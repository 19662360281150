import React from 'react'

import { Row, Charge, Label, Qtd, Spassing, ActionRows, ActionRow } from './style'
import { onlynumber } from '../../Util/Mask'
import upArrow from "../../assets/up_arrow_icon.svg";
import removeIcon from "../../assets/remove_icon.svg";
import editIcon from "../../assets/edit_icon.svg"

const ItemList = ({ charge, item, upItem, removeItem,editItem, position, isEdit = false, onChangeAmmount, onChangeEmbalagem }) => {
    const [value, setValue] = React.useState();
    const [valueEmb, setValueEmb] = React.useState();
    const [editQtd, setEditQtd] = React.useState(false);
    const [editEmb, setEditEmb] = React.useState();

    React.useEffect(() => {
        if ((item.OrdemQtd && item.OrdemQtd !== "0.0") || (item.Mengsum && item.Mengsum !== "0.0")) {
            setValue(item.OrdemQtd || item.Mengsum)
        }
    }, []);

    const onChangeQtd = ({ target: { value } }) => {
        setValue(value ? value.split(".")[0] : "" )
        onChangeAmmount(value || '')
    }

    const onChangeEmb = ({ target: { value } }) => {
        setValueEmb(value.toUpperCase() || "");
        onChangeEmbalagem(value.toUpperCase() || "")
    }

    return (
        <Row>
            <Charge>{charge}</Charge>
            <Label>{item.Vbeln ? item.Vbeln : item.OrdemVenda}</Label>
            <Label>{item.Posnr ? item.Posnr : item.OrdemItem}</Label>
            <Label>{item.Arktx ? item.Arktx : item.OrdemDesc}</Label>
            {
                !editEmb ? (
                    <Label name={'emb'} onClick={() => setEditEmb(!editEmb)}>{item.Vrkme || item.EmbCarga || ''}</Label>

                ) : (
                        <Label> 
                            <input
                                name={'emb'}
                                maxLength={30}
                                type="text"
                                autoFocus={editEmb}
                                value={isEdit ? valueEmb : undefined}
                                onChange={onChangeEmb}
                                onBlur={() => setEditEmb(!editEmb)}
                                maxLength="15" />
                        </Label>
                    )

            }

            {
                !editQtd ? (
                    <Qtd name="qtd" onClick={() => setEditQtd(!editQtd)}> {item.Mengsum || item.OrdemQtd || ''}</Qtd>
                ) : (
                        <Qtd>
                            <input
                                name={'qtd'}
                                maxLength={15}
                                type="number"
                                autoFocus={editQtd}
                                onKeyPress={onlynumber}
                                value={isEdit ? value : undefined}
                                onChange={onChangeQtd}
                                onBlur={() => setEditQtd(false)}
                                maxLength="15"
                                placeholder={"0.0"} /></Qtd>
                    )

            }

            <ActionRows>
                <ActionRow onClick={upItem} position={position} ><div><img src={upArrow} /></div></ActionRow>
                <ActionRow onClick={removeItem}><div> <img src={removeIcon} /></div></ActionRow>
                <ActionRow onClick={editItem}><div> <img src={editIcon} /></div></ActionRow>
            </ActionRows>
        </Row>
    );
}

export default ItemList;