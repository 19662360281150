import React, { useEffect } from 'react';

import { Container, Box, ContentButtons, Button, Body } from './styles';


function Alert({
    show,
    message,
    img,
    positiveButtonText = undefined,
    negativeButtonText = undefined,
    positiveButtonClick = null,
    negativeButtonClick = null
}) {

    const handlerNegativeButton = () => {
        if (negativeButtonClick === null)
            dismiss();
        else
            negativeButtonClick()
    }

    const handlerPositiveClick = () => {
        if (positiveButtonClick === null)
            dismiss();
        else
            positiveButtonClick()

    }



    const dismiss = () => {
        show = false;
    }

    return (
        <Container show={show}>
            <Box image={img}>
                <Body>
                    {
                        message ?
                            <p>
                                {message}
                            </p> : ''
                    }
                    {
                        img ? <img src={img} /> : ''
                    }
                </Body>
                <ContentButtons>
                    {
                        negativeButtonText !== undefined ? (<Button onClick={handlerNegativeButton} type='cancel'> {negativeButtonText} </Button>) : (null)
                    }
                    {
                        positiveButtonText !== undefined ? (<Button onClick={handlerPositiveClick}> {positiveButtonText} </Button>) : (null)
                    }
                </ContentButtons>
            </Box>
        </Container>
    );
}

export default Alert;