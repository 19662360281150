import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: #97B43C;
    height: 44px;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    font-size: 11px;
    width: 100%;

`;

export const H1 = styled.h1`
    font-size: 11px;
    font-weight: 300;
    text-transform: uppercase;
`;
