import React from 'react';

import PropTypes from 'prop-types';

import { Row, Label, LastLabel, FistLabel, ActionRows,ActionRow } from './style';

import { cpfMask, cnpjMask } from '../../Util/Mask'

import editIcon from '../../assets/edit_icon.svg'
import removeIcon from '../../assets/remove_icon.svg'

function ItemListAdminUser(props) {

    let cnpj = cnpjMask(props.cnpj) + (props.cnpjsLegth > 1 ? ' +' : '');

    return (
        <Row>
            <FistLabel>{props.name}</FistLabel>            
            <Label>{props.email}</Label>
            <Label>{props.profiles}</Label>
            <Label>{props.cpf !== ' ' ? cpfMask(props.cpf) : 'Sem CPF'}</Label>
            <LastLabel cnpjsLegth={props.cnpjsLegth}>{cnpj.length > 0 ? cnpj : "Sem CNPJ" }</LastLabel>            
            <ActionRows>
                <ActionRow onClick={props.onEdit}><div><img src={editIcon} /></div></ActionRow>
                <ActionRow onClick={props.onRemove}><div> <img src={removeIcon} /></div></ActionRow >
            </ActionRows>
        </Row>
    )
}

ItemListAdminUser.propTypes = {
    name: PropTypes.string,
    login: PropTypes.string,
    email: PropTypes.string,
    profiles: PropTypes.string,
    cpf: PropTypes.string,
    cnpj: PropTypes.string,
    cnpjsLegth: PropTypes.number
}



export default ItemListAdminUser;