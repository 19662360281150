import { combineReducers } from 'redux';

import ModalItems from './ModalItems';
import ModalItemsError from './ModalItemsError';
import TopStatusBar from './TopStatusBar';
import AlertAddFat from './AlertAddFat';

export default combineReducers({
    ModalItems,
    ModalItemsError,
    TopStatusBar,
    AlertAddFat
})