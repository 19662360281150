import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;  
    width: 100%;
    display:none;
    overflow: hidden;
`;
export const Row = styled.div`
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: space-between;

`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    height:auto;
    width: 100%;
    align-items: flex-end;

`;


export const Image = styled.img`
`;
export const Label = styled.p`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    font-weight: bold;
    font-size: 13px;
`;

export const Regular = styled.div`
    font-weight: 300;
    font-size: 13px;
    margin-left: 5px;
`;


export const Title = styled.h4`
    width: 100%;
    text-align: center;
`;