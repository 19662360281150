import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

import {
    getResponseError,
    getLocalStorage,
    formateDate,
    paginate,
    sleep,
} from "../../Util/Functions";

import Alert from "../../components/Alert";
import ModalLoading from "../../components/ModalLoading";

import {
    Container,
    Content,
    Table,
    RowHeader,
    FisthItem,
    Item,
    LastItem,
    RowBody,
    Row,
} from "./style";
import arrowSetPage from "../../assets/arrow_set_page.svg";

import Api from "../../server";
import { TypeUser } from "../../Util/TypeUser";

const INITIAL_MINUTES_REFRESH_SERVICE = 15;
const INITIAL_SECONDS_REFRESH_SERVICE = 60;

var START_NEXT_PAGE = true;
const INITIAL_SECONDS_NEXT_PAGE = 30;

const AMMOUNT_ITENS = 30;

const DashboardData = ({ history }) => {
    const INITIAL_ALERT_CONFIG = { show: false, message: false };
    const [alertConfig, setAlertConfig] = useState(INITIAL_ALERT_CONFIG);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (goRedirect()) {
            setIconsPages();
            callServiceDashboard();
            updateTimeRefreshService();
            setMinutesRefreshService(`${INITIAL_MINUTES_REFRESH_SERVICE - 1}`);
            startRefreshService();
        }
    }, []);

    useEffect(() => {
        if (
            list.length > 0 &&
            START_NEXT_PAGE &&
            Math.ceil(list.length / AMMOUNT_ITENS) > 1
        ) {
            updateTimeNextPage();
            setMinutesNextPage(0);
            startNextPage(1);
        }
    }, [list]);

    const goRedirect = async () => {
        if ((await getLocalStorage("ACCESS")) !== TypeUser.dashboard) {
            history.goBack();
            return false;
        }
        return true;
    };

    const callServiceDashboard = async () => {
        var today = new Date();
        var day = String(today.getDate()).padStart(2, "0");
        var month = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var year = today.getFullYear();

        let body = {
            date: `${year}-${month}-${day}`,
        };

        // let body = {
        //     date: `${year}-${month}-${day}`
        // }
        setLoading(true);

        try {
            const {
                data: {
                    result: {
                        Agendamentos,
                        Return: { Type, Message },
                    },
                },
            } = await Api.post("users/SchedulingDashboard", body, {
                headers: {
                    Authorization: "Bearer " + (await getLocalStorage("TOKEN")),
                },
            });
            if (Type !== "S") {
                setAlertConfig({
                    show: true,
                    message: Message,
                });
            }
            setList(Agendamentos ? Agendamentos.item : []);
            setPage(1);
        } catch (e) {
            setAlertConfig({
                show: true,
                message: getResponseError(e),
            });
        } finally {
            setLoading(false);
        }
    };

    const getTextStatus = (status) => {
        switch (status) {
            case "10":
                return "Agendado";
            case "20":
                return "Aguardando veículo";
            case "30":
                return "Aguardando carregamento";
            case "40":
                return "Carregamento";
            case "50":
                return "Aguardando NF";
            case "60":
                return "Liberado";
            case "70":
                return "Cancelado";
            case "80":
                return "Aguardando manifesto transportador";
            case "90":
                return "Manifesto emitido";
        }
    };
    const [minutesRefreshService, setMinutesRefreshService] = useState("00");
    const [secondsRefreshService, setSecondsRefreshService] = useState("00");
    const [lastUpdateRefreshService, setLastUpdateRefreshService] =
        useState("00:00");
    const [minutesNextPage, setMinutesNextPage] = useState("00");
    const [secondsNextPage, setSecondsNextPage] = useState("00");
    const [lastUpdateNextPage, setLastUpdateNextPage] = useState("00:00");

    let count_seconds_refresh_service = INITIAL_SECONDS_REFRESH_SERVICE;
    let count_minutes_refresh_service = INITIAL_MINUTES_REFRESH_SERVICE;

    function startRefreshService() {
        if (count_seconds_refresh_service > 0) {
            count_seconds_refresh_service -= 1;
            let count_text = count_seconds_refresh_service;
            if (count_seconds_refresh_service < 10) {
                count_text = "0" + count_seconds_refresh_service;
            }
            setSecondsRefreshService(count_text);
            if (count_seconds_refresh_service === 0) {
                startRefreshService();
            } else {
                setTimeout(startRefreshService, 1000);
            }
        } else {
            count_seconds_refresh_service = INITIAL_SECONDS_REFRESH_SERVICE;
            if (count_minutes_refresh_service > 0) {
                count_minutes_refresh_service -= 1;
                let count_text = count_minutes_refresh_service;
                if (count_minutes_refresh_service < 10) {
                    count_text = "0" + count_minutes_refresh_service;
                }
                setMinutesRefreshService(count_text);
            } else {
                //Chama o metodo de listar novamente
                count_minutes_refresh_service = INITIAL_MINUTES_REFRESH_SERVICE;
                callServiceDashboard();
                setMinutesRefreshService(
                    `${count_minutes_refresh_service - 1}`
                );
                updateTimeRefreshService();
            }
            startRefreshService();
        }
    }

    function updateTimeRefreshService() {
        let date = new Date();
        setLastUpdateRefreshService(
            String(date.getHours()).padStart(2, "0") +
                ":" +
                String(date.getMinutes()).padStart(2, "0")
        );
    }

    let count_seconds_next_page = INITIAL_SECONDS_NEXT_PAGE;

    async function startNextPage(newPage) {
        START_NEXT_PAGE = false;
        if (count_seconds_next_page > 0) {
            count_seconds_next_page -= 1;
            let count_text = count_seconds_next_page;
            if (count_seconds_next_page < 10) {
                count_text = "0" + count_seconds_next_page;
            }
            setSecondsNextPage(count_text);
            setTimeout(() => startNextPage(newPage), 1000);
        } else {
            count_seconds_next_page = INITIAL_SECONDS_NEXT_PAGE;
            updateTimeNextPage();

            if (newPage == Math.ceil(list.length / AMMOUNT_ITENS)) {
                setPage(1);
                startNextPage(1);
            } else {
                setPage(newPage + 1);
                startNextPage(newPage + 1);
            }
        }
    }

    function updateTimeNextPage() {
        let date = new Date();
        setLastUpdateNextPage(
            String(date.getHours()).padStart(2, "0") +
                ":" +
                String(date.getMinutes()).padStart(2, "0")
        );
    }

    const setIconsPages = () => {
        //Adicionando icones de next e previous ao componente ReactPaginate
        let imgPrevious = document.createElement("img");
        imgPrevious.setAttribute("src", arrowSetPage);
        let previous =
            document.getElementsByClassName("previous")[0].firstChild;
        previous.appendChild(imgPrevious);

        let imgNext = document.createElement("img");
        imgNext.setAttribute("src", arrowSetPage);
        imgNext.style.transform = "rotate(180deg)";
        let next = document.getElementsByClassName("next")[0].firstChild;
        next.appendChild(imgNext);
    };
    return (
        <Container>
            <Alert
                show={alertConfig.show}
                message={alertConfig.message}
                negativeButtonClick={() => setAlertConfig(INITIAL_ALERT_CONFIG)}
                negativeButtonText="Fechar"
            />
            <ModalLoading show={loading} />
            <Content>
                <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <p>Última atualização às {lastUpdateRefreshService}</p>
                    <p>
                        Próxima atualização em {minutesRefreshService}:
                        {secondsRefreshService}
                    </p>
                </Row>
                {Math.ceil(list.length / AMMOUNT_ITENS) > 1 ? (
                    <Row
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <p>
                            Última atualização de página às {lastUpdateNextPage}
                        </p>
                        <p>
                            Próxima atualização de página em {minutesNextPage}:
                            {secondsNextPage}
                        </p>
                    </Row>
                ) : null}

                <Table>
                    <thead>
                        <RowHeader>
                            <FisthItem>ID DO AGENDAMENTO</FisthItem>
                            <Item>SEQUÊNCIA DE EMBARQUE</Item>
                            <Item>LOCAL CARREGAMENTO</Item>
                            <Item>PLACA</Item>
                            <Item>MOTORISTA</Item>
                            <Item>SITUAÇÃO DA CARGA</Item>
                            <LastItem>DATA DO AGENDAMENTO</LastItem>
                        </RowHeader>
                    </thead>
                    <tbody>
                        {paginate(list, AMMOUNT_ITENS, page).map(
                            (item, index) => {
                                return (
                                    <RowBody
                                        status={item.SituacaoCarga}
                                        key={
                                            item.IdAgendamento +
                                            item.SequenciaEmbarque
                                        }
                                    >
                                        <FisthItem>
                                            {item.IdAgendamento}
                                        </FisthItem>
                                        <Item>{item.SequenciaEmbarque}</Item>
                                        <Item>{item.LocalCarregamento}</Item>
                                        <Item>{item.Placa.toUpperCase()}</Item>
                                        <Item>{item.Motorista}</Item>
                                        <Item>
                                            {getTextStatus(item.SituacaoCarga)}
                                        </Item>
                                        <LastItem>
                                            {formateDate(item.DataAgendanmento)}
                                        </LastItem>
                                    </RowBody>
                                );
                            }
                        )}
                    </tbody>
                </Table>
                <ReactPaginate
                    previousLabel={""}
                    nextLabel={""}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(list.length / AMMOUNT_ITENS)}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={5}
                    forcePage={page - 1}
                    onPageChange={async ({ selected }) => {
                        setPage(selected + 1);
                    }}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    initialPage={0}
                />
            </Content>
        </Container>
    );
};

export default DashboardData;
