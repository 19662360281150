import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Container,
    Label,
    Field,
    FieldFile,
    Red
} from './style'

//Utils
import { cpfMask, cnpjMask, maskCpfCnpj, phoneMask } from '../../Util/Mask'

var attachmentIsRequired = false;

function Input(props) {
    const [value, setValue] = useState('');
    attachmentIsRequired = props.type === 'file' ? true : false;

    const onChange = (e) => {
        let newValue = checkMask(props.mask, e);
        setValue(newValue);
        props.onChange(e);
    }


    const checkMask = (mask, e) => {
        if (mask) {
            switch (mask) {
                case 'cpf':
                    return cpfMask(e.target.value);
                case 'cnpj':
                    return cnpjMask(e.target.value);
                case 'cpf_cnpj':
                    return maskCpfCnpj(e.target.value);
                case 'phone':
                    return phoneMask(e.target.value);
                default: return e.target.value;
            }
        }
    }

    return (
        <Container>
            {
                props.type !== 'file' ? (
                    <Label id={props.idLabel} radius={props.radius} login={props.login} status={props.status}>
                        {props.label}{props.required && !props.login? (<Red>*</Red>) : null}
                    </Label>
                ) : (null)
            }

            {
                props.type !== 'file' ? (<Field id={props.id} {...props} value={props.value ? props.value : value} onChange={onChange} />)
                    :
                    (
                        <FieldFile htmlFor={props.id} id={props.idLabel} >{ props.label}
                            <Field onChange={props.onChange} value={props.value}  {...props} />
                        </FieldFile>

                    )
            }
        </Container>
    )
}

Input.defaultProps = {
    label: undefined,
    type: 'text',
    attachments: [{}],
    login: false
}

Input.propTypes = {
    label: PropTypes.string,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    numberForLine: PropTypes.number,
    border: PropTypes.string,
    radius: PropTypes.number,
    style: PropTypes.object,
    login: PropTypes.bool,
    onChange: PropTypes.func,
    mask: PropTypes.string,
    status: PropTypes.bool,
    idLabel: PropTypes.string

}

export default Input;
