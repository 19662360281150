import styled from 'styled-components';
import Colors  from '../../Util/Colors'

export const Select = styled.select`
    height: 100%;
    width:100%;
    border-radius: 0px 5px 5px 0px; 
    outline: none;
    padding:0 13px;
    border-color: ${Colors.colorGray};  
    background: white;
`;

export const Label = styled.label`
    display: flex ;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    background-color: ${Colors.primaryColor};
    text-align:center;
    width: 195px;
    border-radius: 5px 0 0 5px; 
    font-size: 11px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    height: 100%;
`;

export const Option = styled.option`
    outline: none;
`;

export const BoxSelect = styled.div`
    margin-top: 5px;
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    width:100%;
    height: 41px;
`;

export const Red = styled.div`
    color:red;
    margin-left: 5px;
`;