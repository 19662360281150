import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

import Colors from '../../Util/Colors';
import Devices from '../../Util/Devices';

const StyledContainer = styled.div`
    display: flex;
    visibility: ${props => props.show ? 'initial' : 'collapse'};
    justify-content:center;
    align-items:center;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.7);
    position: fixed;
    left: 0;
    top: 0;
`;

export const Box = styled.div`
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding:10px;
    min-width: 400px;
    max-width: ${props => props.image ? 'auto' : '400px'};
    min-height: 200px;
    max-height: 900px;
    border-radius: 15px;
    background: #fff;

    img{
        margin-bottom:15px;
        height:600px;
        width:90%;
        
    }
`;

export const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex:4;
    width:100%;
`;

export const ContentButtons = styled.div`
    display: flex;
    flex:1;
    width:100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-bottom: 10px;
`;

const StyledButton = styled.div`
    cursor: pointer;
    color: white;
    border: none;
    height: 10px;
    width: auto;
    border-radius: 22px;
    text-align: center;
    background: ${ props => props.type === 'success' ? Colors.primaryColor : Colors.colorRed} ;
    padding: 15px 26px;
    font-weight: regular;
    font-size: 11px;
    letter-spacing: 0.33px;
    text-transform: uppercase;
    font-weight: 900;

    &:focus{
        border:none;
        outline: none;
    }
    &:active{
        transform: translateY(1.5px);
    }
`;

export const FieldFile = styled.label`
    display: flex;
    flex-direction: column;
    flex:1;
    -ms-flex:1;
    border-radius: 9px;
    border: 1px ${Colors.primaryColor} solid;
    justify-content:center;
    align-items: center;
    text-align:center;
    margin-left: 8.65px;
    color:${Colors.primaryColor};
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    height: 70px;
    
    &:hover{
        cursor: pointer;
    }

    &:hover:disabled{
        cursor: not-allowed
    }
`;

export const Field = styled.input`
    border: 1px solid ${props => props.login ? Colors.primaryColor : '#CCCCCC'};
    border-radius: ${props => props.login ? `${props.radius}px` : `0 ${props.radius}px ${props.radius}px 0`};
    width: 100%;
    padding: ${props => props.login ? 10 : 0}px 13px;
    display: ${props => props.type === 'file' ? 'none' : 'flex'};
    height: ${props => props.login ? 'initial' :'42px'};
    
    &:focus{
        outline: 0;
    }
    
    &:hover:disabled{
        cursor: not-allowed
    }
`;


export const Button = props => <StyledButton {...props} />
export const Container = props => <StyledContainer {...props} />

StyledButton.defaultProps = {
    type: 'success'
}

StyledContainer.defaultProps = {
    show: false
}

Container.propTypes = {
    show: PropTypes.bool,
}

Button.propTypes = {
    type: PropTypes.string
}