import styled from 'styled-components';
import Colors from '../../Util/Colors';

export const Input = styled.input`
  
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 14px;
    height: 14px;
    padding: 1px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid ${Colors.primaryColor};
    background-color: transparent;
    border-radius: 7px;
    &:checked {
        background-color: ${Colors.primaryColor};
    }
    margin-right:15px;
    margin-bottom: 3px;
`;

export const Label = styled.label`
    color: ${Colors.primaryColor};
    font-size: 11px;
    text-transform:uppercase;
    border: 1px solid ${Colors.primaryColor};
    border-radius: 9px;
    padding: 15px;   
    flex: 1;
    margin: 0px ${props => props.position === 1 ? '5px' : '0px'} ;
    display:flex;
    align-items: center;
    justify-content: left;
    font-weight: bold;
    &:hover{
        cursor: pointer;
    }
`;