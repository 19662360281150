import React, { useState } from 'react';
//Components
import Input from '../Input';
import Alert from '../Alert';

//Util
import Colors from '../../Util/Colors'

import { Container, Box, ContentButtons, Button, Body, H1 } from './styles';


function AlertRecoveryPassword({
    show,
    title = "",
    type = 0,
    oldPasswordProps = '',
    positiveButtonText = "Confirmar",
    negativeButtonText = "Cancelar",
    positiveButtonClick = null,
    negativeButtonClick = null
}) {

    const INTIAL_ALERT_CONFIG = { show: false, message: '', alertMessage: true };

    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [oldPassword, setOldPassword] = useState();
    const [alertConfig, setAlertConfig] = useState(INTIAL_ALERT_CONFIG);

    const handlerNegativeButton = (event) => {
        event.preventDefault();
        if (negativeButtonClick === null)
            dismiss();
        else
            negativeButtonClick()
    }

    const handlerPositiveClick = (event) => {
        if (positiveButtonClick === null)
            dismiss();
        else {
            if (type !== 0) {
                if (oldPasswordProps !== oldPassword) {
                    showAlert("A senha antiga está incorreta.");
                    return false;
                }

            }
            if (confirmPassword !== password) {
                showAlert("A senhas digitadas são diferentes");
                return false;
            } else {
                if (!password) {
                    showAlert("Digite uma senha para prosseguir");
                    return false;
                }
                if (password.length < 6) {
                    showAlert("A senha não pode ter menos que seis dígitos.")
                    return false;
                } else {
                    positiveButtonClick(confirmPassword)
                    event.preventDefault();
                }
            }
        }



    }

    const showAlert = (message) => {
        setAlertConfig({
            show: true,
            message: message
        })

    }


    const dismiss = () => {
        show = false;
    }

    return (
        <Container show={show}>
            <Alert
                show={alertConfig.show}
                message={alertConfig.message}
                negativeButtonText="Fechar"
                negativeButtonClick={() => setAlertConfig(INTIAL_ALERT_CONFIG)}
            />
            <Box action="#" onSubmit={e => e.preventDefault()}>
                <H1>{title}</H1>
                <Body>
                    {
                        type === 0 ? (
                            <>
                                <Input onChange={({ target: { value } }) => setPassword(value)} value={password} required border={Colors.primaryColor} placeholder={'Nova senha'} radius={5} type={'password'} login={true} />
                                <Input onChange={({ target: { value } }) => setConfirmPassword(value)} value={confirmPassword} required border={Colors.primaryColor} placeholder={'Confirmar senha'} radius={5} type={'password'} login={true} />
                            </>
                        ) : (
                                <>
                                    <Input onChange={({ target: { value } }) => setOldPassword(value)} value={oldPassword} required border={Colors.primaryColor} placeholder={'Senha Antiga'} radius={5} type={'password'} login={true} />
                                    <Input onChange={({ target: { value } }) => setPassword(value)} value={password} required border={Colors.primaryColor} placeholder={'Nova senha'} radius={5} type={'password'} login={true} />
                                    <Input onChange={({ target: { value } }) => setConfirmPassword(value)} value={confirmPassword} required border={Colors.primaryColor} placeholder={'Confirmar senha'} radius={5} type={'password'} login={true} />
                                </>
                            )
                    }

                </Body>
                <ContentButtons>
                    <Button onClick={handlerNegativeButton} type='cancel'> {negativeButtonText} </Button>
                    <Button onClick={handlerPositiveClick}> {positiveButtonText} </Button>
                </ContentButtons>
            </Box>
        </Container>
    );
}

export default AlertRecoveryPassword;