import React, { useState, useEffect } from 'react';

import { BoxBackground, BoxWhite, Table, HOrder, HLabel, HQtd, TR_Row, Order, Label, Qtd, Header, Close, Row, ButtonSearch, InputSearchOrders, Select } from './style';
import { bindActionCreators } from 'redux';

import { Creators as modalActions } from '../../store/ducks/ModalItems';
import { connect } from 'react-redux';

import img_close from '../../assets/close-green.svg'
import { onlynumber } from '../../Util/Mask';
import { sleep } from '../../Util/Functions';
const ModalItens = ({ clickItem, setVisible, modalVisible, initialList }) => {

    const [list, setList] = useState()
    const [listFilter, setListFilter] = useState();
    const [orderSearch, setOrderSearch] = useState("");
    const [descripitionSearch, setDescripitionSearch] = useState("")
    const [typeFilter, setTypeFilter] = useState(1);


    useEffect(() => {
        let newList = [...initialList];
        setList(newList);
        setListFilter(newList);

    }, [initialList]);

    useEffect(() => {
        if (!modalVisible) {
            setOrderSearch('');
            setDescripitionSearch('');
            setListFilter(initialList);
            setTypeFilter(1);
        }

    }, [modalVisible])


    const searchOrder = async () => {
        setListFilter([])

        let newList = [...list];

        if ((typeFilter == "1" && orderSearch == "") || (typeFilter == "2" && descripitionSearch == "")) {
            setListFilter(newList)
            return;
        }

        let listFilter;

        if (typeFilter == "1") {
            listFilter = newList.filter((item) => {
                return item.Vbeln.indexOf(orderSearch) > -1;
            });
        } else {
            listFilter = newList.filter((item) => {
                return item.Arktx.toUpperCase().indexOf(descripitionSearch.toUpperCase()) > -1;
            });
        }

        await sleep(400);

        setListFilter(listFilter);

    }


    const onOrderSearch = ({ target: { value } }) => {
        setOrderSearch(value)
    }

    const onDescriptionSearch = ({ target: { value } }) => {
        setDescripitionSearch(value);
    }

    const onChangeSelect = (env) => {
        setTypeFilter(env.target.value);
    }

    const checkType = (evt) => {
        if (typeFilter == "1") {
            onlynumber(evt);
        }
    }

    if (modalVisible)
        return (
            <BoxBackground onClick={() => setVisible(false)}>
                <BoxWhite onClick={e => {
                    e.stopPropagation();
                }}>
                    <Header>
                        <Close onClick={() => setVisible(false)}>
                            <img src={img_close} />
                        </Close>
                    </Header>
                    <Row>
                        <Select onChange={onChangeSelect} value={typeFilter}>
                            <option value={"1"} >PEDIDO</option>
                            <option value={"2"} >DESCRIÇÃO</option>
                        </Select>
                        <InputSearchOrders
                            maxLength={typeFilter == "1" ? 10 : 40}
                            placeholder="Filtrar"
                            onChange={typeFilter == "1" ? onOrderSearch : onDescriptionSearch}
                            value={typeFilter == "1" ? orderSearch : descripitionSearch}
                            onKeyPress={checkType} />
                        <ButtonSearch onClick={searchOrder}  >OK</ButtonSearch>
                    </Row>
                    <Table >
                        <thead>
                            <tr>
                                <HOrder>PEDIDO</HOrder>
                                <HLabel>ITEM</HLabel>
                                <HLabel>DESCRIÇÃO</HLabel>
                                <HLabel>UNIDADE DE VENDA</HLabel>
                                <HQtd>QTD.</HQtd>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listFilter.map((item, index) => {
                                    return (<TR_Row key={item.Vbeln + item.Posnr} onClick={async () => {
                                        const newObject = JSON.parse(JSON.stringify(item));
                                        newObject.Mengsum = "0";
                                        newObject.Vrkme = "";
                                        newObject.Mengbas = item.Mengsum;
                                        setOrderSearch('');
                                        clickItem(newObject);
                                        setVisible(false);
                                    }}>
                                        <Order >{item.Vbeln}</Order>
                                        <Label >{item.Posnr}</Label>
                                        <Label >{item.Arktx}</Label>
                                        <Label >{item.Vrkme}</Label>
                                        <Qtd >{item.Mengsum}</Qtd>
                                    </TR_Row>)
                                }
                                )
                            }
                        </tbody>
                    </Table>
                </BoxWhite>
            </BoxBackground>
        );
    else return null;
};

const mapStateToProps = state => ({
    modalVisible: state.ModalItems.visible
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators(modalActions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalItens);