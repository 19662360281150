import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Colors from "../../Util/Colors";
import Devices from "../../Util/Devices";
import arrowSetPage from "../../assets/arrow_set_page.svg";

export const Container = styled.div`
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  align-items: center;
  ul {
    list-style-type: disc;
    margin: auto auto;

    padding: 0px;
  }

  ul li {
    display: inline-block;
  }

  ul li:focus,
  ul li a:focus {
    outline: none;
  }

  ul li:first-child {
    margin-left: 0px;
  }

  ul li {
    margin: 0px 5px;
    font-size: 11px;
    font-weight: 900;
    color: ${Colors.colorGray};
    outline: none;
  }

  ul li a:hover {
    cursor: pointer;
  }

  .disabled,
  .disabled:hover,
  .disabled a:hover,
  .disabled a img:hover {
    cursor: not-allowed;
  }

  ul li:focus {
    outline: none;
  }

  .active {
    color: ${Colors.primaryColor};
  }

  ul li:last-child {
    margin-right: 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;

  @media ${Devices.mobileS} {
    width: 90%;
  }

  @media ${Devices.mobileM} {
    width: 90%;
  }

  @media ${Devices.mobileL} {
    width: 90%;
  }

  @media ${Devices.tablet} {
    width: 53%;
  }

  margin-bottom: 30px;
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 5px;
  width: 100%;
  font-size: 11px;
  

  thead th {
    font-weight: 300;
    border-right: 1px solid #ccc;
    background: ${props => props.isEnabled ? Colors.primaryColor : '#CCC'};
    color: ${props => props.isEnabled ? '' : '#707070'}
  }

  tbody th {
    font-weight: 300;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
  }

  thead th img {
    float: right;
    margin-right: 11px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
`;

export const RowHeader = styled.tr`
  height: 44px;
  color: white;
  font-weight: 300;
`;

export const RowBody = styled.tr`
  background: white;
  height: 44px;
  color: ${(props) => {
    if (props.isHead) return "white";
    else if (props.status === "10" || props.status === "20")
      return Colors.primaryColor;
    else return "#101010";
  }};
`;

export const FisthItem = styled.th`
  border-radius: 9px 0px 0px 9px;
  border-left: 1px solid #ccc;
  font-weight: 300;
`;

export const Item = styled.th`
  border-color: #ccc;
  border-style: solid;
  border-width: 0px 1px 0px 0px;
  font-weight: 300;
`;

export const LastItem = styled.th`
  border-right: 1px solid #ccc;
  border-radius: 0px 9px 9px 0px;
  font-weight: 300;
  &:hover {
    cursor: pointer;
  }
`;

const StyledColumn = styled.div`
  display: flex;
  border-right: ${(props) => {
    if (props.isHead) return "1px solid white";
    else if (!props.isLast) return "1px solid #ccc";
    else return "none";
  }};
  flex-direction: column;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 11px;
  height: 44px;

  @media ${Devices.laptop} {
    height: 30px;
  }
`;

const StyleRow = styled.div`
  margin: 0px;
  width: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  height: 44px;

  ${typeof InstallTrigger !== "undefined"
    ? "position:absolute;margin-left:45px;background:red;"
    : ""}
`;

export const Paragraph = styled.p`
  flex: 1;
  margin: 0;
  padding: 0;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media ${Devices.mobileS} {
    font-size: 8px;
  }

  @media ${Devices.mobileM} {
    font-size: 8px;
  }

  @media ${Devices.laptop} {
    font-size: 11px;
  }
`;

export const Buttons = styled.div`
  div {
    padding: 4px;
    border: 1px solid #aaa;
    border-radius: 4px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 29px;
  }
  div &:hover {
    cursor: pointer;
  }
`;

export const Tooltip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: #ccc;
  color: ${Colors.colorDarkGrey};
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  margin-left: -35px;
  margin-top: -30px;
  ${Buttons}:hover & {
    visibility: visible;
  }
`;

export const Row = (props) => <StyleRow {...props} />;
export const Column = (props) => <StyledColumn {...props} />;

StyleRow.defaultProps = {
  isHead: false,
  status: "Aguardando",
};

Row.propTypes = {
  isHead: PropTypes.bool,
  status: PropTypes.string,
};

StyledColumn.defaultProps = {
  isLast: false,
  isHead: false,
  status: "Aguardando",
};

Column.propTypes = {
  isLast: PropTypes.bool,
  isHead: PropTypes.bool,
  status: PropTypes.element.isRequired,
  status: PropTypes.string,
};

/* Div que armazena o Dropdown de centros */
export const CenterDropdownDiv = styled.div`
  display: grid;
  grid-template-columns: 28% 72%;
  align-items: center;
  margin-bottom: 2rem;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    border-radius: 8px 0 0 8px;
    border: 1px solid ${Colors.primaryColor};
    background: ${Colors.primaryColor};
    height: 44px;
    color: #fff;
    font-size: 12px;
  }
`;
