
import React from 'react';

import { connect } from 'react-redux';
import { Container, Canvas, Content, Logo, Paragraph, CurrentUser } from './style';
import { getLocalStorage } from '../../Util/Functions';

import logo from '../../assets/logo.svg';
import { TypeUser } from '../../Util/TypeUser';


class TopStatusBar extends React.Component {

    state = {
        title: "login",
        data_session: {
            access: '',
        }
    }


    componentWillReceiveProps = (nextProps) => {
        if (nextProps.url !== this.props.url)
            this.draw(nextProps.url);
    }

    componentDidMount() {
        this.draw(window.location.pathname);
    }

    draw = async (pathname) => {
        this.getDatSession();
        const access = await getLocalStorage('ACCESS');
        if (access === TypeUser.operator || access === TypeUser.shippingCompany) {
            const canvas = this.refs.canvas;
            const ctx = canvas.getContext("2d");
            const screenWidth = window.innerWidth * 0.51;

            canvas.width = screenWidth;
            canvas.height = 90;
            let x = screenWidth / 5;
            if (window.innerWidth >= 1600) {
                x = screenWidth / 5;
            } else
                x = screenWidth / 10;
            const y = 35;
            this.setState({
                title: 'barra'
            })




            const pages = [
                {
                    text: 'DISPONIBILIDADE',
                    active: pathname === '/availability',
                    complete: pathname !== '/availability',
                },
                {
                    text: 'AGENDAMENTO DE CARGA',
                    active: pathname === '/book_charge',
                    complete: pathname === '/transporter_data' || pathname === '/mount_charge',
                },
                {
                    text: 'DADOS TRANSPORTADOR',
                    active: pathname === '/transporter_data',
                    complete: pathname === '/mount_charge',
                },
                {
                    text: 'MONTE SUA CARGA',
                    active: pathname === '/mount_charge',
                    complete: false
                },
            ]

            ctx.fillStyle = '#ccc';
            ctx.fillRect(x, y, 591, 1);
            ctx.fillStyle = '#97B43C';
            ctx.font = 'bold 11px Montserrat';


            pages.forEach((item, index) => {
                if ((!item.active && !item.complete) && pages[index - 1].active) {
                    ctx.fill();
                    ctx.beginPath();
                    ctx.fillStyle = '#ccc';
                    ctx.font = '11px Montserrat';
                }
                ctx.arc(x + 7, y, item.active ? 7 : 5, 0, 2 * Math.PI, false);
                ctx.fillText(item.text, item.text.length >= 19 ? x - 65 : x - 40, y + 30);
                x += 193;
            })
            ctx.fill();
        }


        if (pathname === '/user_admin') {
            this.setState({
                title: "Área administrativa"
            })
        }

        if (pathname === '/dashboard') {
            this.setState({
                title: "Dashboard"
            })
        }

        if (pathname === "/") {
            this.setState({
                title: "Login"
            })
        }

        if (pathname === "/download") {
            this.setState({
                title: "download"
            })
        }
    }

    onLogout = () => {
        localStorage.clear();
        window.location.replace('/');
    }

    getDatSession = async () => {
        const access = await getLocalStorage('ACCESS');
        this.setState({
            data_session: { ...this.state.data_session, access }
        })
    }

    render() {
        const { title, data_session } = this.state;
        return (
            <Container>
                <Content direction='flex-end'>
                    <Logo src={logo} onClick={() => {
                        if (this.state.title !== 'Dashboard' && this.state.data_session.access !== '') {                            
                            window.location.replace(
                                data_session.access === TypeUser.operator || 
                                data_session.access === TypeUser.shippingCompany ?
                                '/availability' : '/user_admin');
                        }

                    }} />
                </Content >
                {
                    data_session.access === TypeUser.operator || data_session.access === TypeUser.shippingCompany ? (null) :
                        (<p>{this.state.title}</p>)

                }

                {
                    <center>
                        <Canvas ref="canvas" style={{ flex: 1, display: data_session.access === TypeUser.operator || data_session.access === TypeUser.shippingCompany ? 'flex' : 'none' }} />
                    </center>
                }
                <Content direction='flex-end'>
                    {
                        (localStorage.getItem("ID") || this.state.title === "Dashboard") && this.state.title !== "download" ? (
                            <Paragraph onClick={this.onLogout}>
                                <CurrentUser>
                                    {localStorage.getItem("NAME") + " "}
                                </CurrentUser>
                                / Logout
                            </Paragraph>
                        ) : (null)
                    }

                </Content >
            </Container>
        );
    }


}

const mapStateToProps = state => ({
    url: state.TopStatusBar.url
});

export default connect(mapStateToProps)(TopStatusBar);