import styled from 'styled-components';
import { Container as ContainerInput } from '../../components/Input/style'

export const BoxWhite = styled.div`
    background: white;
    box-shadow: 0px 5px 20px #00000029;
    border-radius: 15px;
    margin: auto;
    width: 21%;
    margin-top: 100px;
    
`;

export const BoxElements = styled.form`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding: 35px;

    ${ContainerInput}{
        margin-top: 0px;
        margin-bottom: 13px;
    }

    p{
        color: #B8B8B8;
        font-size: 10px;
        margin-top: 1px;
        margin-bottom: 18px;
        cursor: pointer;
    }
`;