import styled from 'styled-components';
import Colors from '../../Util/Colors';
export const Container = styled.div`
    width: 100%;
    height: 100%;
    border: 0;
    border: solid ${Colors.colorGray};
    display: flex;
    align-items: center;
    border-width:  ${props => props.position === 'first' ? '1px 1px 1px 1px' : props.position === 'last' ? '1px 1px 1px 0px;' : '1px 1px 1px 0px'};
    border-radius:  ${props => props.position === 'first' ? '9px 0px 0px 9px' : props.position === 'last' ? '0px 9px 9px 0px' : '0'};    
`;

export const Input = styled.input`
    padding: 13px 10px;
    text-align: center;
    font-weight: bold;
    border: 0;
    border-radius: 9px;
    outline: 0;
    background: white;
    max-width: 150px;
`;

export const ClearInput = styled.p`
    padding: 0px 10px;
    color: ${Colors.colorRed};
    font-weight: bold;

    &:hover{
        cursor: pointer;
    }
`;

export const Select = styled.select`
    height: 42px;
    border: 0;
    border-radius: 10px;
    background: white;
    text-align-last: center;
    outline: 0;
    font-weight: bold;
    color: black;
    max-width: 200px;
`;