import styled from "styled-components";
import Colors from "../../Util/Colors";

export const Container = styled.div`
    display: flex;
    align-items: center;

    input{
        border: 0;
        border: 1px solid ${Colors.colorGray};
        border-radius: 10px;
        outline: 0;
        padding: 0px 10px;
        height: 30px;
        width: 50px;
        text-align: center;
        margin-right: 10px;
    }

    p{
        font-size: 12px;
    }
`;