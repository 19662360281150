export const cpfMask = value => {
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

}

export function cnpjMask(text) {
    return text.
        replace(/\D/g, '')
        .replace(/(\d{2})(\d{1,2})/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')

}

export function maskCpfCnpj(value) {
    var newValue = '';
    var newValue = removeMask(value);
    if (newValue.length <= 11) {
        newValue = cpfMask(newValue);
    } else {
        newValue = cnpjMask(newValue);
    }
    return newValue;
}

export function phoneMask(text) {
    if (text.length > 10)
        return text.replace(/\D/g, '')
            .replace(/(\d{2})(\d{1,2})/, '($1) $2')
            .replace(/(\d{5})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')
    else
        return text.replace(/\D/g, '')
            .replace(/(\d{2})(\d{1,2})/, '($1) $2')
            .replace(/(\d{4})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')
}

export function phoneMaskPrint(text) {
    try {
        return text.replace(/\D/g, '')
            .replace(/(\d{2})(\d{1,2})/, '+$1 $2')
            .replace(/(\d{2})(\d{1,2})/, '($1) $2')
            .replace(/(\d{5})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')
    } catch (e) {
        return null
    }
}

export const dateMask = value => {
    return value
        .replace(/\D/g, '') 
        .replace(/(\d{2})(\d)/, '$1/$2')        
        .replace(/(\d{2})(\d)/, '$1/$2')
}

export const removeMask = value => {
    if (value)
        return value.replace(/[^\w\s]/gi, '').replace(/\s/g, '');
    else
        return '';
}

export const onlynumber = (evt) => {                
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode        
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)){
        evt.preventDefault();
        return false;
    }
    return true;
}


export function onlyUTF8(text) {
    if(text){
        return text.replace(/[^a-zA-Z ]/g, "")
    }else{
        return text
    }
}