import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Container, Box, BoxBillingTypes, Header, Close } from './styles';

import Input from '../../components/Input';
import TitleBackgroundGreen from '../../components/TitleBackgroundGreen';
import RadioOption from '../../components/RadioOption';
import Alert from '../../components/Alert';
import Button from '../../components/Button';

import { cnpjMask, onlyUTF8, cpfMask, removeMask, phoneMask, maskCpfCnpj } from '../../Util/Mask';
import { validateCNPJ, validateCpf } from '../../Util/Validate';

import { Creators as modalActions } from '../../store/ducks/ModalItems';
import { Creators as alertAddFat } from '../../store/ducks/AlertAddFat';

import img_close from '../../assets/close-green.svg'

const billing_type = {
    name: 'billing',
    types: [
        {
            id: 'sales_direct',
            text: 'Vendas Direta',
            value: '01',
        },
        {
            id: 'sales_account_arder',
            text: 'Venda Conta e Ordem',
            value: '02'
        },
        {
            id: 'sales_third',
            text: 'Venda Terceiro',
            value: '03'
        }
    ]
}

const INTIAL_ALERT_CONFIG = { show: false, message: '', onClick: undefined };

function AlertAddFat({ lastItem, itemIndex, item, list, saveFat, editFat, visible, ModalActions: { clickItem }, AlertAddFat: { setVisibleAlert } }) {

    const [cpf_cnpj, setCpfCnpj] = useState();
    const [socialReason, setSocialReason] = useState('');
    const [staterRegistration, setStaterRegistration] = useState('')
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [cep, setCep] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [phone, setPhone] = useState('');
    const [number, setNumber] = useState('');
    const [radioSelected, setRadioSelected] = useState(null);
    const [alertConfig, setAlertConfig] = useState(INTIAL_ALERT_CONFIG);
    const [currentItem, setItem] = useState();

    useEffect(() => {
        if (visible)
            checkEdit();
    }, [visible]);

    useEffect(() => {
        if (item) {
            setItem(item)
        }
    }, [item])



    const checkEdit = () => {
        if (itemIndex != null) {
            mountValues(list[itemIndex]);
        } else {
            if (lastItem != null) {
                mountValues(item, lastItem);
            } else {
                clearInputsRadio();
                clearInputs();
            }
        }
    }

    const mountValues = (item, lastItem = {}) => {
        setItem(item);
        let cargaEdit = {};
        cargaEdit.CnpjCpfFat = lastItem.CnpjCpfFat || item.CnpjCpfFat || '';
        cargaEdit.EmailFat = lastItem.EmailFat || item.EmailFat || '';
        cargaEdit.Email = lastItem.Email || item.Email || '';
        cargaEdit.Endereco = lastItem.Endereco || item.Endereco || '';
        cargaEdit.TelFat = lastItem.TelFat || item.TelFat || '';
        cargaEdit.TelefoneFat = lastItem.TelefoneFat || item.TelefoneFat || '';
        cargaEdit.RazaoSocialFat = lastItem.RazaoSocialFat || item.RazaoSocialFat || '';
        cargaEdit.IeFat = lastItem.IeFat || item.IeFat || '';
        cargaEdit.InscrEstadual = lastItem.InscrEstadual || item.InscrEstadual || '';
        cargaEdit.TipoFat = lastItem.TipoFat || item.TipoFat || '';
        cargaEdit.TipoFaturamento = lastItem.TipoFat || item.TipoFat || '';
        cargaEdit.Cep = lastItem.Cep || item.Cep || '';
        cargaEdit.Cidade = lastItem.Cidade || item.Cidade || '';
        cargaEdit.Estado = lastItem.Estado || item.Estado || '';
        cargaEdit.Bairro = lastItem.Bairro || item.Bairro || '';
        cargaEdit.Numero = lastItem.Numero || item.Numero || '';

        var billing_types = document.getElementsByName(billing_type.name);

        billing_types.forEach((type) => {
            if (parseInt(type.value) == parseInt(cargaEdit.TipoFat)) {
                type.checked = true;
            }

        })

        setRadioSelected(cargaEdit.TipoFat)

        setCpfCnpj(maskCpfCnpj(cargaEdit.CnpjCpfFat || ''));
        setEmail(cargaEdit.EmailFat || '');
        setAddress(cargaEdit.Endereco || '');
        try {
            setPhone(phoneMask(cargaEdit.TelFat.substring(2) || ''));
        } catch (e) {
            setPhone('')
        }
        setSocialReason(cargaEdit.RazaoSocialFat || '');
        setStaterRegistration(cargaEdit.IeFat || '');
        setCep(cargaEdit.Cep || '');
        setCity(cargaEdit.Cidade || '');
        setState(cargaEdit.Estado || '');
        setNeighborhood(cargaEdit.Bairro || '');
        setNumber(cargaEdit.Numero || '')
    }

    const onChangeSocialReason = ({ target: { value } }) => {
        setSocialReason(value)
    }

    const showAlert = (message, onClick = null) => {
        setAlertConfig({
            show: true,
            message,
            onClick
        })
    }

    const onChangeCpfCnpj = ({ target: { value } }) => {
        var newValue = removeMask(value);
        if (newValue.length <= 11)
            newValue = cpfMask(newValue);
        else
            newValue = cnpjMask(newValue);

        setCpfCnpj(newValue)
    }

    const onChangeStateRegistration = ({ target: { value } }) => {
        setStaterRegistration(value);
    }

    const onChangeEmail = ({ target: { value } }) => {
        setEmail(value)
    }

    const onChangeAdders = ({ target: { value } }) => {
        setAddress(value)
    }

    const onChangePhone = ({ target: { value } }) => {
        setPhone(phoneMask(removeMask(value)))
    }

    const onChangeState = ({ target: { value } }) => {
        setState(value)
    }

    const onChangeCep = ({ target: { value } }) => {
        setCep(value)
    }

    const onChangeCity = ({ target: { value } }) => {
        setCity(value)
    }

    const onChangeNeighborhood = ({ target: { value } }) => {
        setNeighborhood(value)
    }

    const onChangeNumber = ({ target: { value } }) => {
        setNumber(value)
    }

    const AddFat = async (event) => {
        if (radioSelected != '01') {
            let inputs = document.querySelectorAll('input');
            for (let index = 0; index < inputs.length; index++) {
                const input = inputs[index];
                if ((input.required && input.value === '') || (input.value.length < input.minLength)) {

                    return false;
                }
            }
        }
        const value = removeMask(cpf_cnpj);
        if ((value.length > 11 ? !validateCNPJ(value) : !validateCpf(value)) && radioSelected !== '01') {
            showAlert('CPF ou CNPJ inválido');
            event.preventDefault();
            return false;
        }

        let item = currentItem;

        // var TipoFaturamento = ''

        // var billing_types = document.getElementsByName(billing_type.name);

        // billing_types.forEach(type => {
        //     if (type.checked) {
        //         TipoFaturamento = type.value;
        //     }
        // })

        // if (TipoFaturamento === '') {
        //     showAlert('Selecione um tipo de faturamento');
        //     return false;
        // }


        if (radioSelected != '01') {
            item.TelefoneFat = `55${removeMask(phone).replace(" ", "")}`;
            item.TelFat = item.TelefoneFat;
        } else {
            item.TelefoneFat = '';
            item.TelFat = '';
        }

        item.RazaoSocialFat = onlyUTF8(socialReason);
        item.CnpjCpfFat = removeMask(cpf_cnpj);
        item.InscrEstadual = staterRegistration;
        item.IeFat = staterRegistration;
        item.Email = email;
        item.EmailFat = email;
        item.Endereco = address;
        item.Cep = cep;
        item.Cidade = city;
        item.Estado = state;
        item.Bairro = neighborhood;
        item.Numero = number;
        item.TipoFaturamento = radioSelected;
        item.TipoFat = radioSelected;

        if (itemIndex != null) {
            editFat(item, itemIndex);
        } else {
            saveFat(item);
        }
        clearInputs();
        clearInputsRadio();
        clickItem(undefined);
        setVisibleAlert(false);

    }

    const clearInputsRadio = () => {
        var billing_types = document.getElementsByName(billing_type.name);

        billing_types.forEach(type => {
            type.checked = false;
        })

        billing_types[0].checked = true;
    }

    const clearInputs = () => {
        let inputs = document.querySelectorAll('input');

        for (let index = 0; index < inputs.length; index++) {
            const input = inputs[index];
            input.value = '';
        }
        setCpfCnpj(null);
        setEmail(null);
        setAddress(null);
        setSocialReason(null);
        setStaterRegistration(null);
        setCep(null);
        setCity(null);
        setState(null);
        setNeighborhood(null);
        setNumber(null);
        setPhone(phoneMask(''));
        setRadioSelected('01')
    }

    return (
        <Container show={visible} onClick={() => {
            clearInputs();
            clearInputsRadio();
            setVisibleAlert(false)
        }}>

            <Box radioSelected={radioSelected} onClick={e => {
                e.stopPropagation();
            }}>
                 <Header>
                        <Close onClick={() => setVisibleAlert(false)}>
                            <img src={img_close} />
                        </Close>
                    </Header>
                <div>                   
                    <form onSubmit={e => e.preventDefault()}>
                        <TitleBackgroundGreen text="SELECIONAR TIPO DE FATURAMENTO" />

                        <BoxBillingTypes>
                            <RadioOption
                                id={billing_type.types[0].id}
                                text={billing_type.types[0].text}
                                value={billing_type.types[0].value}
                                name={billing_type.name}
                                position={0}
                                defaultChecked
                                required={true}
                                onClick={({ target: { value } }) => {
                                    if (value === '01') {
                                        clearInputs();
                                    }
                                    setRadioSelected(value)
                                }} />
                            <RadioOption
                                id={billing_type.types[1].id}
                                text={billing_type.types[1].text}
                                value={billing_type.types[1].value}
                                name={billing_type.name}
                                position={1}
                                defaultChecked
                                onClick={({ target: { value } }) => {
                                    setRadioSelected(value)
                                }}
                                required={true} />
                            <RadioOption
                                id={billing_type.types[2].id}
                                text={billing_type.types[2].text}
                                value={billing_type.types[2].value}
                                name={billing_type.name}
                                position={2}
                                defaultChecked
                                onClick={({ target: { value } }) => {
                                    setRadioSelected(value)
                                }}
                                required={true} />
                        </BoxBillingTypes>
                        {
                            radioSelected !== '01' ? (
                                <>
                                    <TitleBackgroundGreen text="Dados Faturamento" />

                                    <Input
                                        label={'Razão social'}
                                        type={'text'}
                                        maxLength={60}
                                        onChange={onChangeSocialReason}
                                        value={socialReason || ''}
                                        required={radioSelected != '01'}
                                    />

                                    <Input
                                        label={'Cpf/CNPJ'}
                                        type={'text'}
                                        onChange={onChangeCpfCnpj}
                                        minLength={14}
                                        mask={'cpf_cnpj'}
                                        value={cpf_cnpj || ''}
                                        required={radioSelected != '01'} />

                                    <Input
                                        label={'Inscrição Estadual'}
                                        type={'text'}
                                        maxLength={18}
                                        onChange={onChangeStateRegistration}
                                        value={staterRegistration || ''} />

                                    <Input
                                        label={'Estado'}
                                        type={'text'}
                                        onChange={onChangeState}
                                        value={state || ''}
                                        maxLength={60}
                                        required={radioSelected != '01'} />

                                    <Input
                                        label={'Cidade'}
                                        type={'text'}
                                        onChange={onChangeCity}
                                        value={city || ''}
                                        maxLength={60}
                                        required={radioSelected != '01'} />

                                    <Input
                                        label={'Bairro'}
                                        type={'text'}
                                        onChange={onChangeNeighborhood}
                                        value={neighborhood || ''}
                                        maxLength={60}
                                        required={radioSelected != '01'} />
                                    <Input
                                        label={'CEP'}
                                        type={'text'}
                                        onChange={onChangeCep}
                                        value={cep || ''}
                                        maxLength={20}
                                        required={radioSelected != '01'} />


                                    <Input
                                        label={'Endereço'}
                                        type={'text'}
                                        onChange={onChangeAdders}
                                        value={address || ''}
                                        maxLength={60}
                                        required={radioSelected != '01'} />

                                    <Input
                                        label={'Número'}
                                        type={'number'}
                                        onChange={onChangeNumber}
                                        value={number || ''}
                                        maxLength={10}
                                        required={radioSelected != '01'} />

                                    <Input
                                        label={'Email'}
                                        type={'email'}
                                        onChange={onChangeEmail}
                                        value={email || ''}
                                        maxLength={241}
                                        required={radioSelected != '01'} />

                                    <Input
                                        label={'Telefone / Whatsapp'}
                                        type={'text'}
                                        onChange={onChangePhone}
                                        minLength={14}
                                        mask={'phone'}
                                        value={phone || ''}
                                        required={radioSelected != '01'} />
                                </>
                            ) : (null)
                        }

                        <Button text={itemIndex != null ? 'EDITAR ITEM DE PEDIDO' : 'ADICIONAR ITEM DE PEDIDO'} onClick={AddFat} shadow={true} />
                    </form>
                </div>
            </Box>

            <Alert
                show={alertConfig.show}
                message={alertConfig.message}
                negativeButtonText="Fechar"
                negativeButtonClick={
                    alertConfig.onClick ? alertConfig.onClick :
                        () => setAlertConfig(INTIAL_ALERT_CONFIG)}
            />
        </Container>)
}

const mapStateToProps = state => ({
    item: state.ModalItems.item,
    visible: state.AlertAddFat.visible
})

const mapDispatchToProps = dispatch => ({
    ModalActions: bindActionCreators(modalActions, dispatch),
    AlertAddFat: bindActionCreators(alertAddFat, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertAddFat);