import React from 'react';
import styled from 'styled-components';

import { Button } from '../../components/Button/style'
import Colors from '../../Util/Colors';

import PropTypes from 'prop-types';

export const Container = styled.div`    
    visibility: ${props => props.show ? 'initial' : 'collapse'};  
    position: fixed;
    background: ${Colors.colorModal};
    width: 100vw;
    height: 100%;
    top:0;
    left:0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;    
`;

export const Box = styled.div`
    background: white;
    width: 60%;
    max-height: 70%; /*ideial 89%*/
    border-radius: 22px;
    display: flex;
    flex-direction: column;    
    position: absolute;
    overflow: hidden; 
    padding: 25px;

    div{      
      overflow: auto;          
    }

    form{
      min-width: 839px;    
      display: flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      ${Button}{
        margin-top: ${props => props.radioSelected != '01' ? '55.5' : '0'}px;
        font-weight: bold;
      }
    }
`;

export const BoxBillingTypes = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top:5px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;

export const Close = styled.button`
  background: none;
  border: none;

  &:hover{
    cursor:pointer;
  }
`;