import React, { useState, useEffect } from 'react';


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Base64 } from 'js-base64';

import { BoxWhite, BoxElements } from './style';

import Colors from '../../Util/Colors';
import Strings, { concat } from '../../Util/Strings';
import { generatePassword, millisToMinutesAndSeconds } from '../../Util/Functions'

import Input from '../../components/Input';
import Alert from '../../components/Alert';
import AlertRecoveryPassword from '../../components/AlertRecoveryPassword';
import Button from '../../components/Button';
import ModalLoading from '../../components/ModalLoading';

import { removeMask } from '../../Util/Mask';
import { getResponseError, checkSessionUser, getLocalStorage, encrypt, decrypt } from '../../Util/Functions'
import { getCurrentTime } from '../../Util/Date'


import { Creators as ActionsStatusBar } from '../../store/ducks/TopStatusBar';

//Server
import Api from '../../server'
import { environment } from '../../Util/environment';
import { TypeUser } from '../../Util/TypeUser';

function Login({ history, TopStatusBarActions: { redirect } }) {
    require('../../components/Redirect')(history, redirect);

    const INTIAL_ALERT_CONFIG = { show: false, message: '', alertMessage: true };
    const INTIAL_SESSION_CONFIG = { _id: '', name: '', access: '', accessToken: '' };
    const [login, setLogin] = useState();
    const [password, setPassword] = useState();
    const [alertConfig, setAlertConfig] = useState(INTIAL_ALERT_CONFIG);
    const [alertPasswordConfig, setAlertPasswordConfig] = useState({ show: false, title: '', type: 0 });
    const [configRecovery, setConfigRecovery] = useState({ email: '', _id: '' });
    const [userSession, setUserSession] = useState(INTIAL_SESSION_CONFIG);
    const [show, setShow] = useState(false);

    useEffect(() => {
        //Verifica se usuário tem uma sessão criada
        if (checkSessionUser()) {
            //Verifica o nível de acesso do usuário para redirecionar para á página
            goRedirect();
        }
    }, []);



    const goRedirect = async () => {
        //Verifica qual o nível de acesso do usuário
        const access = await getLocalStorage('ACCESS')
        if (access === TypeUser.admin) {
            history.replace('/user_admin', {
                login
            });
        } else if (access === TypeUser.operator || access === TypeUser.shippingCompany) {
            history.replace('/availability', { login });
        } else if (access === TypeUser.dashboard) {
            history.replace('/dashboard');
        } else {
            setAlertConfig({
                show: true,
                message: 'Tipo de usuário incorreto',
                alertMessage: false
            });
            history.replace('/');
        }
    }

    const checkIfRecovery = () => {
        //Faz a checagem se existe prefixo
        let prefix = password.split("-");
        return prefix[0] === 'ReQ';
    }

    const callServiceAuth = async () => {
        try {
            //Monsta o corpo da requisição
            let body = {
                login: removeMask(login),
                password
            }

            //Faz a chamada a API
            const { data } = await Api.post('/users/authenticate', body);

            //Monta um retorno de sucesso            
            return {
                error: false,
                data
            }
        } catch (error) {
            //Monta um retorno de erro            
            return {
                error: true,
                data: getResponseError(error)
            }
        }
    }

    const callServiceFindEmail = async () => {
        //trata erro de retorno da api
        try {

            //Monta body de requisição ao serviço
            let body = {
                login: removeMask(login)
            }
            //Faz a consulta a API busncando e-mail pelo login digitado
            const { data } = await Api.post('/users/findEmailByLogin', body);

            //Faz um retorno de sucesso
            return {
                error: false,
                data
            }

        } catch (e) {
            //Faz um retorno de erro
            return {
                error: true,
                data: getResponseError(e)
            }
        }
    }


    const handlerSubmit = async (event) => {
        if (password === undefined || password === '' || login === undefined || login === '') {
            return false;
        }

        try {
            //Guarda search
            let search;
            if (history.location.search)
                search = Base64.decode(history.location.search.split("=")[1]);

            setShow(true);

            //Chama a função que vai fazer a chamada a API 
            const response = await callServiceAuth();

            setShow(false);
            //Checa o erro no response
            if (!response.error) {
                //Caso não tenha erro cria a sessão do usuário
                const {
                    data: {
                        name,
                        _id,
                        profiles,
                        accessToken,
                        status,
                        phone,
                        cpf,
                        cnpj,
                        cnpjs_consult,
                        firstAuthenticate
                    }
                } = response;

                setUserSession({ _id, name, access: profiles[0], accessToken, login: { cpf, cnpj, cnpjs_consult, phone } });
                //Verifica se a url é de troca de senha
                if (search && checkIfRecovery()) {
                    let pass = search.split(":")[1];
                    let time = search.split(":")[0];

                    if (pass === password) {
                        //Pega o horário atual
                        let currentTime = getCurrentTime();
                        //Verifica tempo de expiração
                        let currentTimeMinutes = millisToMinutesAndSeconds(currentTime).minutes;
                        let timeMinutes = millisToMinutesAndSeconds(time).minutes;

                        let isValid = (currentTimeMinutes - timeMinutes) <= 30;
                        if (isValid) {
                            //Mostra alerta de troca de senha
                            setAlertPasswordConfig({ show: true, title: 'Troca Senha', type: 0 });
                        } else {
                            setAlertConfig({
                                show: true,
                                message: Strings.REQUEST_NEW_PASSWORD,
                                alertMessage: false
                            });
                        }
                    } else {
                        setAlertConfig({
                            show: true,
                            message: "Senha de recuperação inválida, por favor tente novamente",
                            alertMessage: false
                        })
                    }
                    return false;
                }

                if (!search) {
                    if (checkIfRecovery()) {
                        setAlertConfig({
                            show: true,
                            message: "Senha de recuperação inválida, por favor tente novamente",
                            alertMessage: false
                        });
                        return false;
                    }
                }
                if (firstAuthenticate) {
                    setAlertPasswordConfig({
                        show: true,
                        title: 'Primeiro Acesso',
                        type: 1
                    })
                } else {
                    //cria sessão de usuário

                    let access = encrypt(profiles[0]);
                    let accessTokenEncrypt = encrypt(accessToken);
                    let login = encrypt(JSON.stringify({ cpf, cnpj, cnpjs_consult, phone }))

                    if (access === '' || accessTokenEncrypt === '' || login === '' || name === '' || _id === '') {
                        setAlertConfig({
                            show: true,
                            message: "Ocorreu um erro ao criar a sessão",
                            alertMessage: false
                        });
                        return false;
                    } else {
                        localStorage.setItem('NAME', name);
                        localStorage.setItem('ID', _id);
                        localStorage.setItem('ACCESS', access);
                        localStorage.setItem('TOKEN', accessTokenEncrypt);
                        localStorage.setItem('LOGIN', login);
                    }

                    //Chama método para redirecionar
                    goRedirect();
                }



                event.preventDefault();

            } else {
                //Caso tenha erro mostra um alerta na tela
                setAlertConfig({
                    show: true,
                    message: response.data,
                    alertMessage: false
                })
                event.preventDefault();

            }
        } catch (e) {
            //Caso ocorra algum problema mostra o erro para o usuário
            setShow(false);

            let response = getResponseError(e);

            setAlertConfig({
                show: true,
                message: response,
                alertMessage: false
            })
            console.error(e);
            event.preventDefault();

        }

    }

    const handlerSendEmailToRecovery = async () => {
        if (!login) {
            setAlertConfig({
                show: true,
                message: "Preencha o campo de login com seu CPF ou CNPJ para que possamos prosseguir com a redefinição de senha",
                alertMessage: false
            })
            return false;
        }

        //Fazendo pesquisa no banco de dados para pegar o e-mail cadastrado
        const response = await callServiceFindEmail();

        //Checa erro no response
        if (!response.error) {

            //caso não tenha erro
            const { data: { email, _id } } = response;



            //Envia o e-mail
            handlerSendEmail(email, _id)

        } else {
            //Caso tenha erro
            setAlertConfig({
                show: true,
                message: response.data,
                alertMessage: false
            })
        }
    }

    const handlerSendEmail = async (email, _id) => {
        try {
            let pass = generatePassword();
            const body = {
                email: encrypt(email, environment.passCriptEmail),
                subject: "[ Boa Safra ] Recuperação de senha",
                password: pass,
                html: concat(Strings.HTML, [environment.domain, Base64.encode(getCurrentTime() + ":" + pass), pass], "CODE")
            }
            setShow(true);
            const response = await Api.post(`/sendEmail/${_id}`, body);
            setShow(false);
            setAlertConfig({ show: true, message: "Verifique sua caixa de mensagem no seu e-mail de cadastro, não esqueça de verificar também a caixa de spam", alertMessage: false })
        } catch (e) {
            setShow(false);
            setAlertConfig({
                show: true,
                message: getResponseError(e),
                alertMessage: false
            })
        }
    }

    const handlerRecoveryPassword = async (password) => {
        //Atualizar a senha
        try {
            let body = {
                password,
                status: true,
                firstAuthenticate: false
            }

            setShow(true);
            await Api.patch(`/users/recovery/${userSession._id}`, body, {
                headers: {
                    Authorization: `Bearer ${userSession.accessToken}`
                }
            });

            setShow(false)
            localStorage.setItem('NAME', userSession.name);
            localStorage.setItem('ID', userSession._id);
            localStorage.setItem('ACCESS', encrypt(userSession.access));
            localStorage.setItem('TOKEN', encrypt(userSession.accessToken));
            localStorage.setItem('LOGIN', encrypt(JSON.stringify(userSession.login)));


            goRedirect()

        } catch (e) {
            setShow(false)
            setAlertConfig({
                show: true,
                message: getResponseError(e),
                alertMessage: false
            })
        }
    }

    return (
        <>
            <BoxWhite>
                <BoxElements onSubmit={e => e.preventDefault()}>
                    <Input onChange={({ target: { value } }) => setLogin(value)} border={Colors.primaryColor} required placeholder={'LOGIN CPF/CNPJ'} radius={5} login={true} mask={'cpf_cnpj'} />
                    <Input onChange={({ target: { value } }) => setPassword(value)} border={Colors.primaryColor} required placeholder={'SENHA'} radius={5} type={'password'} login={true} />
                    <p onClick={handlerSendEmailToRecovery}>Esqueci minha senha</p>
                    <Button onClick={handlerSubmit} enable text={'ACESSAR'} mobileSSize={'106px'} mobileMSize={'10px'} laptopSise={'106px'} radius={'17px'} shadow={false} />
                </BoxElements>
            </BoxWhite>
            <Alert
                show={alertConfig.show}
                message={alertConfig.message}
                positiveButtonClick={handlerSendEmail}
                positiveButtonText={alertConfig.alertMessage ? "Confirmar" : undefined}
                negativeButtonClick={() => { setAlertConfig(INTIAL_ALERT_CONFIG); setConfigRecovery('') }}
                negativeButtonText={alertConfig.alertMessage ? "Cancelar" : "Fechar"}
            />
            <AlertRecoveryPassword
                title={alertPasswordConfig.title}
                show={alertPasswordConfig.show}
                type={alertPasswordConfig.type}
                oldPasswordProps={password}
                positiveButtonClick={handlerRecoveryPassword}
                negativeButtonClick={() => setAlertPasswordConfig(false)} />
            <ModalLoading show={show} />
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    TopStatusBarActions: bindActionCreators(ActionsStatusBar, dispatch)
})

export default connect(null, mapDispatchToProps)(Login);