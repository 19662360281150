import React from 'react';

import { Container, Image, Link } from './style';
import loading from '../../assets/loading.gif';

function LoadingComponent({ show = true, error = true, onClick = () => { } }) {
    return (
        <Container style={{visibility: show ? 'initial' : 'hidden'}}>
            {
                error ? (<Link onClick={onClick}>Tentar novamente</Link>) : (
                    <Image src={loading} />
                )

            }
        </Container>
    );
}

export default LoadingComponent;