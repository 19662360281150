import styled from 'styled-components';

import { ButtonBox as AddItem } from '../../components/AddItem/style';

import { Button } from '../../components/Button/style';

import { Container as TitleBackgroundGreen } from '../../components/TitleBackgroundGreen/styles'

export const Container = styled.form`
    width: 50%;
    margin: auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;

    ${AddItem}{
        margin-top: 6PX;        
        margin-left:auto;
    }

    ${Button}{
        margin-top: 44px;
        margin-bottom:20px;
    }

    ${TitleBackgroundGreen}{
        margin-top: 40px;
    }

    table{
        border-collapse: separate;        
        width: 101%;
        margin-top: -3px;
        
    }


    table tbody tr{
        display:flex;
        justify-content: center;
        align-items:center;
        width: 100%;
    }

    table tbody tr td{        
        width: 100%;        
    }
`;

export const ActionRows = styled.div`
    /* Verifica se está no MS */
    ${typeof InstallTrigger !== 'undefined' ? 'position:absolute;margin-left: 1042px;' : ''} 
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:end;
    height: 44px;
    width:0px;   
`;

export const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
`;

export const ButtonRemove = styled.div`
    margin-top: auto;    
    div {
        padding: 4px;
        border: 1px solid #aaa;
        border-radius: 4px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height:25px;
        width: 29px;
    }
    div &:hover{
        cursor: pointer;
    }
`;