import styled from 'styled-components';

import Devices from '../../Util/Devices';
import Colors from '../../Util/Colors';

import backgroundSelect from '../../assets/background_select.svg'

export const BoxBackground = styled.div`
    position: fixed;
    background: ${Colors.colorModal};
    width: 100vw;
    height: 100%;
    top:0;
    left:0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center; 
    overflow-y: hidden !important;   
`;

export const BoxWhite = styled.div`
    background: white;
    width: 60%;
    max-height: 720px;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    position: absolute;
`;

export const Table = styled.table`
    border-collapse: separate;
    border-spacing: 0 5px;
    flex: 1;
    tbody {
      display: block;
      max-height: 300px;
      overflow-y: scroll;
      margin-right: -10px;      
    }
  

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    thead{
      color:white;
      font-style: Regular;
      font-weight: 300;
    }

    thead tr{
      
      @media ${ Devices.mobileS} {
        font-size: 6px;
        height:24px
      }
      @media ${ Devices.tablet}{
        font-size:8px;
        height: 34px;
      }
      @media ${ Devices.laptop}{
        font-size: 11px;
        height: 44px;
      }
    }
    
    @media ${ Devices.mobileS} {
      font-size: 5px;
    }
    @media ${ Devices.tablet}{
      font-size:8px;
    }
    @media ${ Devices.laptop}{
      font-size: 11px;
    }
`;

export const HLabel = styled.th`  
    background: ${ Colors.primaryColor};  
    border-color: white;
    border-style: solid;
    border-width:  0px 1px 0px 0px;
`;


export const HQtd = styled.th`
    background: ${ Colors.primaryColor};
    border-radius: 0px 9px 9px 0px;
`;

export const HOrder = styled.th`
    background: ${ Colors.primaryColor};
    border-radius: 9px 0px 0px 9px;
    border-color: white;
    border-style: solid;
    border-width:  0px 1px 0px 0px;
`;

export const Order = styled.td`
    background: ${ Colors.primaryColor};
    border: solid ${Colors.colorGray};
    border-width:  1px 1px 1px 1px;
    border-radius: 9px 0px 0px 9px;
    color: #97B43C;
    background: ${props => props.disabled ? '#f2f2f2' : 'white'};
`;

export const Label = styled.td`
    border:solid ${Colors.colorGray};
    border-width:  1px 1px 1px 0px;
    background: ${props => props.disabled ? '#f2f2f2' : 'white'};
`;

export const Qtd = styled.td`
    border: solid ${Colors.colorGray};
    border-radius: 0px 9px 9px 0px;
    border-width: 1px 1px 1px 0px;
    background: ${props => props.disabled ? '#f2f2f2' : 'white'};
`;

export const TR_Row = styled.tr`
    text-align: center;
    letter-spacing: 0.33px;
    &:hover{
      cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    }

    @media ${ Devices.mobileS} {
      font-size: 6px;
      height:20px
    }
    @media ${ Devices.tablet}{
      font-size:8px;
      height: 30px;
    }
    @media ${ Devices.laptop}{
      font-size: 11px;
      height: 44px;
    }
`;


export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  margin: 8px 0px;
`;

export const Select = styled.select`
  background-color: ${Colors.primaryColor};
  color: white;
  outline: none;
  border: none;  
  border-radius: 9px;
  margin-right: 7px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;  
  
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background: url(${backgroundSelect}) 0 0 no-repeat;
  background-repeat: no-repeat;
  background-position-x: 100%;  
  background-position-y: 2px;

  width: 182px;
  height: 40px;
  max-width: 182px;
  text-align: center;
  text-align-last:center;
  background-color: ${Colors.primaryColor};
  
  option{
    padding: 12px 0px !important;
    margin: 0px;
    border: none; 
    outline: none; 
    color: initial;
    background: white;
  }
`;

export const InputSearchOrders = styled.input`
    border: 1px solid ${ Colors.primaryColor};
    border-radius: 9px;
    width: 100%;  
    display: flex;
    height: initial;
    padding: 0px 19px;
    height: 38px;
    &:focus{
        outline: 0;
    }
    
    &:hover:disabled{
        cursor: not-allowed
    }
`;

export const ButtonSearch = styled.button`
  color: white;
  background: ${Colors.primaryColor};
  outline: none;
  border: none;
  border-radius: 9px;
  padding: 12px 13px;
  margin-left: 9px;
  font-size: 11px;
  font-weight: bold;

  &:hover{
    cursor: pointer;
  }
`;

export const Close = styled.button`
  background: none;
  border: none;

  &:hover{
    cursor:pointer;
  }
`;