import styled from 'styled-components';
import Colors from '../../Util/Colors';

export const Charge = styled.td`
    border: solid ${Colors.colorGray};
    border-width:  1px 1px 1px 1px;
    border-radius: 9px 0px 0px 9px;
    color: #97B43C;
`;

export const Label = styled.td`
    border:solid ${Colors.colorGray};
    border-width:  1px 1px 1px 0px;
    input{
        border:none;
        text-align:center;
        width:65px;
        padding: 0px 5px;
        outline:none;
    }
`;

export const Qtd = styled.td`
    border: solid ${Colors.colorGray};
    border-radius: 0px 9px 9px 0px;
    border-width: 1px 1px 1px 0px;
    width: 100px;
    input{
        border:none;
        text-align:center;
        width:90px;
        padding: 0px 5px;
        outline:none;
    }
`;

export const Spassing = styled.td`
    background:white;
    width:5px;
`;

export const UpItem = styled.td`
    border: 1px solid ${Colors.colorGray};
    border-radius: 9px;
    visibility: ${props => {        
        return props.visible === 0 ? 'collapse !important' : 'initial';
    }};
`;

export const Row = styled.tr`
    text-align: center;
    letter-spacing: 0.33px;
    height: 44px;
`;


export const ImgIcon = styled.img`
    width: 15px;
    height: 15px;
`;

export const ActionRows = styled.div`
    ${typeof InstallTrigger !== 'undefined' ? 'position:absolute;margin-left: 0px;' : ''} 
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:start;
    height: 44px;
    width:0px;
`;

export const ActionRow = styled.div`
    width: ${ props => props.position === 0 ? '0px' : 'initial'};
    div {
        padding: 4px;
        border: 1px solid #aaa;
        border-radius: 4px;
        margin-left: 5px;
        visibility: ${ props => props.position === 0 ? 'collapse' : 'initial'};
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${ props => props.position === 0 ? '0' : '25'}px;
        width: ${ props => props.position === 0 ? '0' : '29'}px;
    }
    div &:hover{
        cursor: pointer;
    }
`;