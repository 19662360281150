import styled from 'styled-components';
import Colors from '../../Util/Colors';
import Devices from '../../Util/Devices';

export const Button = styled.button`
    cursor: pointer;
    color: white;
    border: none;
    border-radius: ${props => props.radius ? props.radius : '22px'};
    background: ${props => props.disabled ? Colors.colorGray : props.color || Colors.primaryColor};
    padding: 15px 14px;
    &:focus{
        border:none;
        outline: none;
    }
    &:active{
        transform: translateY(1.5px);
    }
    font-weight: regular;
    font-size: 11px;
    
    height: 44px;
    letter-spacing: 0.33px;
    box-shadow: ${props => props.shadow ? props.disabled ? 'none' : '0px 5px 30px' + (props.color || Colors.primaryColor) : 'none'};
    text-transform: uppercase;

    @media ${Devices.mobileS}{
        font-size: 8px;
        width: ${props => props.mobileSSize ? props.mobileSSise : '200px'};
    }
    @media ${Devices.mobileM}{
        width: ${props => props.mobileMSize ? props.mobileMSise : '230px'};
    }
    @media ${Devices.laptop}{
        font-size:11px;
        width: ${props => props.laptopSise ? props.laptopSise : '301px'};
    }
 
`;