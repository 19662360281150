import styled from 'styled-components';

import Colors from '../../Util/Colors';
import Devices from '../../Util/Devices';

export const Container = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 127px;
    background: white;
    border-bottom: 10px solid ${Colors.primaryColor};
    box-shadow: 0 20px 50px 0 rgba(110,110,110,0.26);

    @media ${Devices.mobileS}{
        height: 20px;
    }

    @media ${Devices.mobileM}{
        height: 80px;
    }

    @media ${Devices.laptop}{
        height: 80px;
    }

    p{
        color: ${Colors.primaryColor};
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        flex: 2;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    flex:1;
    justify-content: ${props => props.direction};
    
`;

export const CurrentUser = styled.b`
    color: ${Colors.primaryColor};
`;
export const Paragraph = styled.p`
    font-size: 12px;
    margin-right: 40px;
    color: ${Colors.colorDarkGrey};

    &:hover{
        cursor: pointer;
    }
`;

export const Logo = styled.img`
  width: 118px;
  height: 53px;

  &:hover{
      cursor: pointer;
  }
`


export const Canvas = styled.canvas`
    width: 100%;

    @media ${Devices.laptopL}{
        width: 80%; 
    }
`;
