import styled from 'styled-components';
import Colors from '../../Util/Colors';

export const FistLabel = styled.td`
    border: solid ${Colors.colorGray};
    border-width:  1px 1px 1px 1px;
    border-radius: 9px 0px 0px 9px;
`;

export const Label = styled.td`
    border:solid ${Colors.colorGray};
    border-width:  1px 1px 1px 0px;
`;

export const LastLabel = styled.td`
    border: solid ${Colors.colorGray};
    border-radius: 0px 9px 9px 0px;
    border-width: 1px 1px 1px 0px;
    color: ${props => props.cnpjsLegth > 1 ? Colors.primaryColor : 'initial'};
`;

export const Spassing = styled.td`
    background:white;
    width:5px;
`;

export const ActionRows = styled.div`
    /* Verifica se está no MS */
    ${typeof InstallTrigger !== 'undefined' ? 'position:absolute;margin-left: 88px;' : ''} 
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:end;
    height: 44px;
    width:0px;
`;

export const ActionRow = styled.div`
    div {
        width: 29px;
        padding: 4px;
        border: 1px solid #aaa;
        border-radius: 4px;
        margin-left: 5px;
    }
    div &:hover{
        cursor: pointer;
    }
`;

export const ImgIcon = styled.img`
    width: 15px;
    height: 15px;
`;

export const Row = styled.tr`
    text-align: center;
    letter-spacing: 0.33px;
    height: 44px;
`;
