import { createActions, createReducer } from 'reduxsauce';

const INITAL_STATE = {
    item: undefined,
    visible: false
}

export const { Types, Creators } = createActions({
    clickItem: ['item'],
    setVisible: ['visible'],
})

const clickItem = (state = INITAL_STATE, action) =>
    ({ ...state, item: action.item });

const setVisible = (state = INITAL_STATE, action) =>
    ({ ...state, visible: action.visible });

export default createReducer(INITAL_STATE, {
    [Types.CLICK_ITEM]: clickItem,
    [Types.SET_VISIBLE]: setVisible,
});
