const base64abc = [
	"A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
	"N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
	"a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
	"n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
	"0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "/"
];

/**
 * @description Converte arquivo para padrão base64
 * @param {arquivo que deseja converter para base64} file 
 * @returns {Retorna a string base64 do arquivo enviado}
 */
export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

/**
 * @description Transforma bytes em base64
 * @param {bytes para converter em base64} bytes 
 * @returns String, Resultado em base64
 */
export function bytesToBase64(bytes) {
	let result = '', i, l = bytes.length;
	for (i = 2; i < l; i += 3) {
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
		result += base64abc[((bytes[i - 1] & 0x0F) << 2) | (bytes[i] >> 6)];
		result += base64abc[bytes[i] & 0x3F];
	}
	if (i === l + 1) { // 1 octet yet to write
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[(bytes[i - 2] & 0x03) << 4];
		result += "==";
	}
	if (i === l) { // 2 octets yet to write
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
		result += base64abc[(bytes[i - 1] & 0x0F) << 2];
		result += "=";
	}
	return result;
}

export function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

export function getContentType(extension) {

	switch(extension){
		case 'doc' : return "application/msword"
		case 'docx' : return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
		case 'pdf' : return "application/pdf"
		case 'ppt' : return "application/vnd.ms-powerpoint"
		case 'pptx' : return "application/vnd.openxmlformats-officedocument.presentationml.presentation"
		case 'xls' : return "application/vnd.ms-excel"
		case 'xlsx' : return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		case 'png' : return "image/png"
		case 'jpeg', 'jpg' : return "image/jpeg"
		case 'gif' : return "image/gif"
	}

}