import { Container, Input, ClearInput, Select } from "./style";
import React, { useState } from "react";
import PropTypes from "prop-types";

function InputFilter(props) {
    const {
        position,
        type,
        placeholder,
        onChange,
        onKeyPress,
        value,
        onClear,
        maxLength,
        options,
    } = props;

    const onChangeInput = ({ target: { value } }) => {
        onChange(value);
    };

    return (
        <Container position={position}>
            {!options ? (
                <>
                    <Input
                        type={type}
                        placeholder={placeholder}
                        disabled={placeholder !== "" ? false : true}
                        onChange={onChangeInput}
                        value={value}
                        maxLength={maxLength}
                        onKeyPress={onKeyPress}
                    />
                    <ClearInput onClick={onClear != null ? onClear : null}>
                        {onClear != null ? "X" : ""}
                    </ClearInput>
                </>
            ) : (
                <Select value={value} onChange={onChangeInput} disabled={onChange != null ? false : true}>
                    {options.map((item) => (
                        <option key={item.name} value={item.value}>
                            {item.name}
                        </option>
                    ))}
                </Select>
            )}
        </Container>
    );
}

InputFilter.defaultProps = {
    type: "text",
    position: "",
    value: "",
};

InputFilter.propTypes = {
    placeholder: PropTypes.string,
    type: PropTypes.string,
    position: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    maxLength: PropTypes.number,
};

export default InputFilter;
