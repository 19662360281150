import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { Creators as ActionsStatusBar } from '../../store/ducks/TopStatusBar'


import { Table, RowHeader, FisthItem, Item, LastItem, Container } from './style'

import ItemListAdminUser from '../../components/ItemListAdminUser';

import InputFilter from '../../components/InputFilter';

import Button from '../../components/Button';
import Alert from '../../components/Alert';
import ModalLoading from '../../components/ModalLoading';
import ReactPaginate from 'react-paginate';

import Api from '../../server/index';

import { getResponseError, checkSessionUser, checkIfAdmin, getLocalStorage } from '../../Util/Functions'
import arrowSetPage from '../../assets/arrow_set_page.svg';
import { cnpjMask, cpfMask, removeMask } from '../../Util/Mask';

export const adminUserPath = '/user_admin';

const AdminUser = (props) => {
    require('../../components/Redirect')(props.history, props.TopStatusBarActions.redirect);
    const STATE_INTIAL_CONFIG_ALERT = {
        show: false,
        message: '',
        removeItemIndex: undefined,
        alertType: 0
    }

    const [list, setList] = useState([]);
    const [alertConfig, setAlertConfig] = useState(STATE_INTIAL_CONFIG_ALERT)
    const [pages, setPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [show, setShow] = useState(false)

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [profile, setProfile] = useState('');
    const [cpf, setCpf] = useState('');
    const [cpfSearch, setCpfSearch] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [cnpjSearch, setCnpjSearch] = useState('');

    const onEdit = (index) => {
        props.history.push('/register_user', { type: 'edit', user: list[index] })
    }

    const showAlert = (message, index = -1, alertType = 0) => {
        setAlertConfig({
            show: true,
            message,
            removeItemIndex: index,
            alertType
        })
    }

    const onRemove = async (index) => {
        try {
            setShow(true);

            const { status } = await Api.delete(`/users/${list[index]._id}`, {
                headers: {
                    Authorization: "Bearer " + await getLocalStorage('TOKEN')
                }
            });

            if (status === 204) {
                let newList = [...list];
                newList.splice(index, 1);
                setList(newList);
            } else {
                showAlert('Ocorreu um erro ao excluir usuário', -1, 1);
            }

            setShow(false);
        } catch (e) {
            setShow(false);
            let response = getResponseError(e);
            showAlert(response, -1, 1);
        }
    }

    useEffect(() => {
        // if (!checkSessionUser() || !checkIfAdmin()) {
        //     //Verifica o nível de acesso do usuário para redirecionar para á página
        //     props.history.replace('/');
        // } else {
        //     getUsers()
        // }
        setIconsPages();
    }, [])

    const setIconsPages = () => {
        //Adicionando icones de next e previous ao componente ReactPaginate
        let imgPrevious = document.createElement("img");
        imgPrevious.setAttribute("src", arrowSetPage);
        let previous = document.getElementsByClassName('previous')[0].firstChild;
        previous.appendChild(imgPrevious);


        let imgNext = document.createElement("img");
        imgNext.setAttribute("src", arrowSetPage);
        imgNext.style.transform = "rotate(180deg)";
        let next = document.getElementsByClassName('next')[0].firstChild;
        next.appendChild(imgNext)

    }


    const getUsers = async (page = 1) => {
        try {
            // setShow(true);

            let cpfApi = cpfSearch;
            if(cpfApi === '000.000.000-00'){
                cpfApi = ' ';
            }
            let cnpjApi = cnpjSearch;
            if(cnpjApi === '00.000.000/0000-00'){
                cnpjApi = ' ';
            }

            const { data: { items, _links: { totalPages } } } = await Api.get(`/users`, {
                headers: {
                    Authorization: "Bearer " + await getLocalStorage('TOKEN')
                },
                params: {
                    _page: page,
                    name,
                    email,
                    profiles: profile,
                    cpf: cpfApi,
                    cnpj: cnpjApi
                }
            });
            setPages(totalPages)

            setList(items)

            // setShow(false);
        } catch (e) {
            // setShow(false);
            let response = getResponseError(e);
            showAlert(response, -1, 1);
        }
    }

    useEffect(() => {
        if (!checkSessionUser() || !checkIfAdmin()) {
            //Verifica o nível de acesso do usuário para redirecionar para á página
            props.history.replace('/');
        } else {
            getUsers(currentPage)
        }
    }, [name, email, profile, cpf, cnpj])

    const handlePageClick = ({ selected }) => {
        const currentPage = selected + 1
        getUsers(currentPage)
        setCurrentPage(currentPage)
    }

    const onChangeName = (value) => {
        setName(value)
    }

    const onChangeEmail = (value) => {
        setEmail(value)
    }

    const onChangeCpf = (value) => {
        const cpf = cpfMask(value)
        const cpfSearch = removeMask(value)
        setCpf(cpf)
        setCpfSearch(cpfSearch)
    }

    const onChangeCnpj = (value) => {
        const cnpj = cnpjMask(value)
        const cnpjSearch = removeMask(value)
        setCnpj(cnpj)
        setCnpjSearch(cnpjSearch)
    }

    const onChangeProfile = (value) => {
        setProfile(value)
    }

    return (
        <>
            <Alert
                show={alertConfig.show}
                message={alertConfig.message}
                positiveButtonText={alertConfig.alertType === 0 ? "Confirmar" : undefined}
                negativeButtonText={alertConfig.alertType === 0 ? "Cancelar" : "fechar"}
                positiveButtonClick={() => { onRemove(alertConfig.removeItemIndex); setAlertConfig(STATE_INTIAL_CONFIG_ALERT) }}
                negativeButtonClick={() => setAlertConfig(STATE_INTIAL_CONFIG_ALERT)}
            />
            <Container>
                <Table>
                    <thead >
                        <RowHeader>
                            <FisthItem>NOME</FisthItem>
                            <Item>E-MAIL</Item>
                            <Item>PRIVILÉGIOS</Item>
                            <Item>CPF</Item>
                            <LastItem>CNPJ</LastItem>
                        </RowHeader>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <InputFilter
                                    placeholder={"Filtro nome"}
                                    position={'first'}
                                    value={name}
                                    onChange={onChangeName}
                                    onClear={() => onChangeName('')} />
                            </td>
                            <td>
                                <InputFilter
                                    placeholder={"Filtro E-mail"}
                                    value={email}
                                    onChange={onChangeEmail}
                                    onClear={() => onChangeEmail('')} />
                            </td>
                            <td>
                                <InputFilter
                                    placeholder={"Filtro"}
                                    value={profile}
                                    onChange={onChangeProfile}
                                    onClear={() => onChangeProfile('')} />
                            </td>
                            <td>
                                <InputFilter
                                    placeholder={"Filtro CPF"}
                                    value={cpf}
                                    onChange={onChangeCpf}
                                    onClear={() => onChangeCpf('')} />
                            </td>
                            <td>
                                <InputFilter
                                    placeholder={"Filtro CNPJ"}
                                    position={'last'}
                                    value={cnpj}
                                    onChange={onChangeCnpj}
                                    onClear={() => onChangeCnpj('')} />
                            </td>
                        </tr>
                        {
                            list.map((item, index) => (
                                <ItemListAdminUser
                                    key={item._id}
                                    name={item.name}
                                    email={item.email}
                                    profiles={item.profiles[0]}
                                    cpf={item.cpf || ''}
                                    cnpj={item.cnpj || ''}
                                    cnpjsLegth={item.cnpjs_consult.length + 1}
                                    onEdit={() => onEdit(index)}
                                    onRemove={() => showAlert(`Tem certeza que deseja remover o usuário ${item.name}?`, index)}
                                />
                            ))
                        }
                    </tbody>
                </Table>


                <ReactPaginate previousLabel={''}
                    nextLabel={''}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages || 1}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    initialPage={0}
                />

                <Button onClick={() => {
                    props.history.push('/register_user', { type: 'cad' })
                }
                } text={'Criar Novo Usuario'} enable={true} shadow={true} />

                <ModalLoading show={show} />
            </Container>
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    TopStatusBarActions: bindActionCreators(ActionsStatusBar, dispatch)
})

export default connect(null, mapDispatchToProps)(AdminUser)