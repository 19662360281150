import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../../components/Button'

//redux
import { Creators as ActionsStatusBar } from '../../store/ducks/TopStatusBar'


//Utils
import { checkSessionUser } from '../../Util/Functions'
import { Container, Row, Column, Content, ButtonCharge, Text } from './styles'

function BookCharge({ TopStatusBarActions: { redirect }, history }) {

    require('../../components/Redirect')(history, redirect);

    useEffect(() => {
        if (!checkSessionUser()) {
            history.replace('/')
        }

    }, [])

    const handlerSubmit = (item) => {
        history.push('/transporter_data?item=' + firstEnabled, {
            dataBook: { idAgenda: -1 }
        })
    }

 
    const checkIfIsNotAllowed = () => {
        let items = history.location.state.item.Posicoes.item;
        if (items.lenght === 0) {
            return true;
        }

        let count_enabled = 0;

        items.forEach(item => {
            if (item.Status !== "02") {
                count_enabled++;
            }
        })
        return count_enabled === 0;
    }



    const checkText = (status, item) => {
        return item.Status === '01' ? 'Disponível' : 'Não Disponível';
    }


    const fields = [];
    let firstEnabled = 0;
    history.location.state.item.Posicoes.item.forEach((item, index) => {
        let status = item.Status === '01';
        if (status && firstEnabled === 0){
            firstEnabled = item.IdAgenda;
        } 
        fields.push(
            <Row key={index + 1 * 80}>
                <ButtonCharge status={status} disabled={!status} >
                    <div>
                        <p>  {
                            index + 1 < 10 ? "0" + (index + 1) : (index + 1)
                        }</p>
                    </div>
                    <Text>
                        {checkText(status, item, index + 1)}
                    </Text>
                </ButtonCharge>
            </Row>
        )
    })

  


    let columns = [];
    let cells = [];

    let division = Math.floor((fields.length % 4) === 0 ? fields.length / 4 : fields.length / 2);
    fields.forEach((item, index) => {
        if (index % division !== 0) {
            cells.push(item);
        } else {
            columns.push(cells);
            cells = [];
            cells.push(item);
        }


        if (index === fields.length - 1) {
            columns.push(cells);
        }
    })

    let trElems = columns.map((item, index) => (
        <Column key={index * 85}>
            {item}
        </Column>
    ));

    return (
        <Container>
            <Content>
                {
                    trElems
                }
            </Content>
            <Button text="INSERIR DADOS DO TRANSPORTADOR" disabled={checkIfIsNotAllowed()} onClick={() => { handlerSubmit(firstEnabled) }} />
            <br />
        </Container>
    )
}

const mapDispatchToProps = dispatch => ({
    TopStatusBarActions: bindActionCreators(ActionsStatusBar, dispatch)
})

export default connect(null, mapDispatchToProps)(BookCharge)