import { getLocalStorage } from "./Functions";

export const TypeUser = {
    admin: 'Admin',
    operator: 'Operador',
    shippingCompany: 'Transportadora',
    dashboard: 'Dashboard'
}

export async function getUserPortal(){
    const { cpf, cnpj } = JSON.parse(await getLocalStorage('LOGIN'));
    let userPortal = cpf || cnpj;
    if(userPortal === ' '){
       userPortal = cnpj; 
    }
    return userPortal;
}

export async function getTypeUserPortal(access){
    switch (access) {
        case TypeUser.admin:
            return '01';
        case TypeUser.operator:
            return '02';
        case TypeUser.shippingCompany:
            return '03';
        case TypeUser.dashboard:
            return '04';
        default:
            return '05';
     }
}