import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:top;
    
`;

export const Row = styled.div`
    margin:0px;
    width: 0px;
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content:end;
    height: 44px;
    ${typeof InstallTrigger !== 'undefined' ? 'position:absolute;margin-left:45px;background:red;' : ''} 
`;

export const RowBox = styled.div`
    display:flex;
    flex-direction: row;
    padding: 3px;
    height: 16px;
    align-items:center;
    z-index: 9999;
    &:hover{
        cursor:pointer;
    }
`;

export const Buttons = styled.div`
    div {
        padding: 4px;
        border: 1px solid #aaa;
        border-radius: 4px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height:25px;
        width: 29px;
        margin-right: -4rem;
    }
    div &:hover{
        cursor: pointer;
    }
`;

export const Box = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    width: 164px;
    min-height: auto;
    background-color: #fff;
    border: 1px solid #aaa;
    margin-left: 5px;
    border-radius:4px ;
    padding: 10px;
    color: #707070;
    box-shadow: #ccc 1px 1px 10px;
    visibility: ${props => !props.open ? `hidden` : `initial`};
    z-index: 1;
    img{
        width: 14px;
        height: 14px;
        margin-right: 10px;
    }
`