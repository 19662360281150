import React, { useEffect, useState } from "react";

//Components
import Input from "../../components/Input";
import TitleBackgroundGreen from "../../components/TitleBackgroundGreen";
import Dropdown from "../../components/Dropdown";
import Alert from "../../components/Alert";
import ModalLoading from "../../components/ModalLoading";

//Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Creators as StatusBarAction } from "../../store/ducks/TopStatusBar";

import { Container, Content, Button, Row, Label, InputFile } from "./styles";

//Utils
import {
    checkSessionUser,
    getResponseError,
    getIdWithZero,
    getLocalStorage,
    sleep,
} from "../../Util/Functions";
import { validateCNPJ, validateCpf } from "../../Util/Validate";
import Colors from "../../Util/Colors";
import { compress } from "../../Util/Compress";
import {
    toBase64,
    bytesToBase64,
    _arrayBufferToBase64,
} from "../../Util/Base64";

import requistos_embarque from "../../assets/requistos_embarque.png";

import {
    removeMask,
    onlyUTF8,
    phoneMask,
    cnpjMask,
    cpfMask,
    onlynumber,
} from "../../Util/Mask";

import Api from "../../server/";
import { Red } from "../../components/Input/style";
import { getUserPortal } from "../../Util/TypeUser";

const jsonp = require("jsonp");

function TransporterData({ history, redirect }) {
    require("../../components/Redirect")(history, redirect);
    const INTIAL_ALERT_CONFIG = {
        show: false,
        message: undefined,
        img: undefined,
        negativeClick: undefined,
    };

    const [alertConfig, setAlertConfig] = useState(INTIAL_ALERT_CONFIG);

    //States fields
    const [cnpj, setCnpj] = useState("");
    const [razaoSocial, setRazaoSocial] = useState("");
    const [telefone, setTelefone] = useState("");
    const [email, setEmail] = useState("");
    const [motorista, setMotorista] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [cpf, setCpf] = useState("");
    const [placaCavalo, setPlacaCavalo] = useState("");
    const [tipoCaminhao, setTipoCaminhao] = useState("");
    const [placaCarretaUm, setPlacaCarretaUm] = useState("");
    const [placaCarretaDois, setPlacaCarretaDois] = useState("");
    const [placaCarretaTreis, setPlacaCarretaTres] = useState("");
    const [renasem, setRenasem] = useState("");
    const [observacao, setObservacao] = useState("");
    // const [crlv, setCRLV] = useState('');
    // const [cnh, setCNH] = useState('');
    const [userCrtAgendamento, setuserCrtAgendamento] = useState("");
    const [dataCrtAgendamento, setDataCrtAgendamento] = useState("");

    const [cnpjIsValid, setCnpjIsValid] = useState(false);
    const [dataBook, setDataBook] = useState({
        idAgenda: "",
        idAgendamento: "",
    });
    const [loading, setLoading] = useState(false);

    const [statusAgendamento, setStatusAgendamento] = useState();

    const [fieldsState, setFieldsState] = useState([
        { field: "CNPJ", value: "" },
        { field: "Razão Social", value: "" },
        { field: "Telefone", value: "" },
        { field: "E-mail", value: "" },
        { field: "Motorista", value: "" },
        { field: "Whatsapp", value: "" },
        { field: "CPF", value: "" },
        { field: "Placa cavalo", value: "" },
        { field: "Tipo caminhão", value: "" },
        { field: "Placa carreta 1", value: "" },
        { field: "Placa carreta 2", value: "" },
        { field: "Placa carreta 3", value: "" },
        { field: "AnCRLV", value: "" },
        { field: "AnCNH", value: "" },
        { field: "Renasem", value: "" },
        { field: "Observação", value: "" },
    ]);

    const [dadosAgendamCarga, setDadosAgendamCarga] = useState({
        list: [],
        fields: {},
    });

    const [extensions, setExtensions] = useState({ crlv: "", cnh: "" });
    useEffect(() => {
        //Verifica sessão do usuário
        if (!checkSessionUser()) {
            history.replace("/");
        }

        let {
            location: { state },
        } = history;

        if (state) setDataBook(state.dataBook);

        if (state && state.type) {
            callService(state.dataBook.idAgendamento);
        } else {
            if (!dataBook) {
                showAlert("Antes de continuar selecione uma carga.", () => {
                    setAlertConfig(INTIAL_ALERT_CONFIG);
                    history.goBack();
                });
            }
        }

        document.getElementById("AnCRLV").style.borderColor = Colors.colorRed;
        document.getElementById("AnCNH").style.borderColor = Colors.colorRed;
        //Verifica se ja existe dados salvos
        if (localStorage.getItem("DATA_TRANSPORTER") && !state.type) {
            //Modifica o valor do array com os itens salvos
            const fields = JSON.parse(localStorage.getItem("DATA_TRANSPORTER"));
            const ext = JSON.parse(localStorage.getItem("EXTENSIONS"));
            fields[12].value = "";
            fields[13].value = "";

            setExtensions({
                crlv: ext.crlv,
                cnh: ext.cnh,
            });
            fillFileds(fields);
        }
    }, []);

    const fillFileds = (fields) => {
        //Mostra os valores nos campos
        setCnpjIsValid(true);
        setCnpj(cnpjMask(fields[0].value));
        setRazaoSocial(fields[1].value);
        setTelefone(phoneMask(fields[2].value));
        setEmail(fields[3].value);
        setMotorista(fields[4].value);
        setWhatsapp(phoneMask(fields[5].value));
        setCpf(cpfMask(fields[6].value));
        setPlacaCavalo(fields[7].value);
        setTipoCaminhao(fields[8].value);
        setPlacaCarretaUm(fields[9].value);
        setPlacaCarretaDois(fields[10].value);
        setPlacaCarretaTres(fields[11].value);
        setRenasem(fields[12].value);
        setObservacao(fields[13].value);
        // setCRLV(`${fields[12].value}`);
        // setCNH(`${fields[13].value}`);
        changeColorValid(
            "AnCNH",
            history.location.state.type,
            !history.location.state.type
        );
        changeColorValid(
            "AnCRLV",
            history.location.state.type,
            !history.location.state.type
        );
        setFieldsState(fields);
    };

    const callService = async (idAgendamento) => {
        setLoading(true);
        try {
            const response = await Api.post(
                "users/GetSchedulingById",
                { idAgendamento: getIdWithZero(idAgendamento) },
                {
                    headers: {
                        Authorization:
                            "Bearer " + (await getLocalStorage("TOKEN")),
                    },
                }
            );

            const {
                data: {
                    result: {
                        Return: { Type, Message },
                        DadosAgendamTransp,
                        DadosAgendamAnexo,
                        DadosAgendamCarga,
                        IdAgenda,
                        StatusAgendamento,
                        DataCrtAgendamento,
                        UserCrtAgendamento,
                        Centro
                    },
                },
            } = response;

            localStorage.setItem("CENTER", Centro);

            setStatusAgendamento(StatusAgendamento);
            setDataBook({ idAgendamento, idAgenda: IdAgenda });
            setuserCrtAgendamento(UserCrtAgendamento);

            DadosAgendamCarga.item.forEach((carga) => {
                carga.InscrEstadual = carga.IeFat;
                carga.Email = carga.EmailFat;
                carga.TelefoneFat = carga.TelFat;
                carga.TipoFaturamento = carga.TipoFat;
                carga.Arktx = carga.OrdemDesc;
            });

            setDataCrtAgendamento(DataCrtAgendamento);
            if (DadosAgendamAnexo)
                setExtensions({
                    crlv: DadosAgendamAnexo.item[0].AttachExtension,
                    cnh: DadosAgendamAnexo.item[1].AttachExtension,
                });
            if (DadosAgendamCarga)
                setDadosAgendamCarga({
                    list: DadosAgendamCarga.item,
                    fields: DadosAgendamTransp.item[0],
                });

            if (StatusAgendamento !== "10" && StatusAgendamento !== "20") {
                setLoading(false);
                showAlert(
                    "O status do agendamento não é permitido modificações.",
                    () => {
                        setAlertConfig(INTIAL_ALERT_CONFIG);
                        history.goBack();
                    }
                );
                return false;
            }

            if (Type === "S") {
                const {
                    item: [
                        {
                            RazaoSocial,
                            TranspCnpj,
                            TranspTel,
                            TranspEmail,
                            MotNome,
                            MotCpf,
                            MotTel,
                            PlacaCavalo,
                            TpCaminhao,
                            PlacaCarreta1,
                            PlacaCarreta2,
                            PlacaCarreta3,
                            Renasem,
                            Observacao,
                        },
                    ],
                } = DadosAgendamTransp;
                let fields = [
                    { field: "CNPJ", value: TranspCnpj },
                    { field: "Razão Social", value: RazaoSocial },
                    { field: "Telefone", value: TranspTel.substring(2) },
                    { field: "E-mail", value: TranspEmail },
                    { field: "Motorista", value: MotNome },
                    { field: "Whatsapp", value: MotTel.substring(2) },
                    { field: "CPF", value: MotCpf },
                    { field: "Placa cavalo", value: PlacaCavalo },
                    { field: "Tipo caminhão", value: TpCaminhao },
                    { field: "Placa carreta 1", value: PlacaCarreta1 },
                    { field: "Placa carreta 2", value: PlacaCarreta2 },
                    { field: "Placa carreta 3", value: PlacaCarreta3 },
                    { field: "Renasem", value: Renasem },
                    { field: "Observação", value: Observacao },
                    {
                        field: "AnCRLV",
                        value: DadosAgendamAnexo
                            ? DadosAgendamAnexo.item[0].AttachContent
                            : "@MOCK",
                    },
                    {
                        field: "AnCNH",
                        value: DadosAgendamAnexo
                            ? DadosAgendamAnexo.item[1].AttachContent
                            : "@MOCK",
                    },
                ];

                setLoading(false);
                fillFileds(fields);
            } else {
                setLoading(false);
                showAlert(Message, () => {
                    setAlertConfig(INTIAL_ALERT_CONFIG);
                    history.goBack();
                });
            }
        } catch (e) {
            setLoading(false);
            showAlert(getResponseError(e));
        }
    };

    const showAlert = (
        message,
        negativeClick = null,
        negativeText = "Fechar"
    ) => {
        setAlertConfig({
            show: true,
            message,
            negativeText,
            negativeClick,
        });
    };

    const showAlertRequisitos = (
        positiveClick = null,
        negativeClick = null
    ) => {
        setAlertConfig({
            show: true,
            img: requistos_embarque,
            // negativeText: 'Fechar',
            // negativeClick,
            positiveText: "Aceitar e continuar",
            positiveClick,
        });
    };

    const updateTransporterData = async (fields) => {
        setLoading(true);
        const userPortal = await getUserPortal();
        const { phone } = JSON.parse(await getLocalStorage("LOGIN"));

        const center = localStorage.getItem("CENTER")

        const body = {
            Centro: center,
            CnpjTransp: removeMask(fields[0].value),
            RazaoSocial: onlyUTF8(fields[1].value),
            TelefoneTransp: `55${removeMask(fields[2].value).replace(" ", "")}`,
            EmailTransp: fields[3].value,
            Motorista: fields[4].value,
            TelefoneMot: `55${removeMask(fields[5].value).replace(" ", "")}`,
            CpfMot: removeMask(fields[6].value),
            PlacaCavalo: fields[7].value,
            TipoCaminhao: fields[8].value,
            PlacaCarreta_1: fields[9].value,
            PlacaCarreta_2: fields[10].value,
            PlacaCarreta_3: fields[11].value,
            Renasem: fields[12].value,
            Observacao: fields[13].value,
            Crlv: fields[14].value === "@MOCK" ? "" : fields[12].value,
            Cnh: fields[15].value === "@MOCK" ? "" : fields[13].value,
            idAgenda: dataBook.idAgenda,
            idAgendamento: dataBook.idAgendamento,
            userPortal: userPortal,
            //TipoFaturamento: dadosAgendamCarga.fields.TipoFat || '',
            DadosCarga: dadosAgendamCarga.list,
            userTel: `55${removeMask(phone).replace(" ", "")}`,
            type: 1,
            ExtencaoArq: extensions.crlv + ";" + extensions.cnh,
            DataAgendamento: dataCrtAgendamento,
            Renasem: renasem,
            Observacao: observacao,
        };

        try {
            const response = await Api.post(
                "/users/SetRegistrationSchedule",
                body,
                {
                    headers: {
                        Authorization: `Bearer ${await getLocalStorage(
                            "TOKEN"
                        )}`,
                    },
                }
            );

            const {
                data: {
                    result: {
                        Return: {
                            item: [{ Type, Message }],
                        },
                    },
                },
            } = response;

            if (Type === "S") {
                setLoading(false);
                showAlert(Message, () => {
                    history.push("/availability");
                });
            } else {
                setLoading(false);
                showAlert(Message);
                return false;
            }
        } catch (e) {
            setLoading(false);
            showAlert(getResponseError(e));
        }
    };

    const handlerSubmit = async (updateOnlyTransporterData = false, e) => {
        let fields = [
            /*0*/ { field: "CNPJ", value: cnpj },
            /*1*/ { field: "Razão Social", value: razaoSocial },
            /*2*/ { field: "Telefone", value: telefone },
            /*3*/ { field: "E-mail", value: email },
            /*4*/ { field: "Motorista", value: motorista },
            /*5*/ { field: "Whatsapp", value: whatsapp },
            /*6*/ { field: "CPF", value: cpf },
            /*7*/ { field: "Placa cavalo", value: placaCavalo },
            /*8*/ { field: "Tipo caminhão", value: tipoCaminhao },
            /*9*/ { field: "Placa carreta 1", value: placaCarretaUm },
            /*10*/ { field: "Placa carreta 2", value: placaCarretaDois },
            /*11*/ { field: "Placa carreta 3", value: placaCarretaTreis },
            /*12*/ { field: "Renasem", value: renasem },
            /*13*/ { field: "Observação", value: observacao },
            /*14*/ { field: "AnCRLV", value: fieldsState[12].value },
            /*15*/ { field: "AnCNH", value: fieldsState[13].value },
        ];

        const pl1 = document.getElementById("Placa carreta 1");
        const pl2 = document.getElementById("Placa carreta 2");
        const pl3 = document.getElementById("Placa carreta 3");
        if (!pl1) {
            setPlacaCarretaUm("");
            fields[9].value = "";
        }
        if (!pl2) {
            setPlacaCarretaDois("");
            fields[10].value = "";
        }
        if (!pl3) {
            setPlacaCarretaTres("");
            fields[11].value = "";
        }
        if (!renasem) {
            setRenasem("");
            fields[12].value = "";
        }
        if (!observacao) {
            setObservacao("");
            fields[13].value = "";
        }
        var empty = [];
        var index = 0;

        fields.forEach((item) => {
            let insert = true;
            //Verifica se os valores do array estão nulos
            if (item.value === "" || (item.value == null && index !== 8)) {
                if (index === 9 && !pl1) {
                    insert = false;
                    setPlacaCarretaUm("");
                }
                if (index === 10 && !pl2) {
                    insert = false;
                    setPlacaCarretaDois("");
                }
                if (index === 11 && !pl3) {
                    insert = false;
                    setPlacaCarretaTres("");
                }
                if (index === 12 && !renasem) {
                    insert = false;
                    setRenasem("");
                }
                if (index === 13 && !observacao) {
                    insert = false;
                    setObservacao("");
                }

                if (insert) empty.push(item);
            }
            index++;
        });

        const fieldEmail = document.querySelector("input#E-mail");
        const listener = () => {
            empty.push(fields[3]);
        };
        fieldEmail.addEventListener("invalid", listener, false);
        //Espera 10 mill para adicionar o o evendo no campo do e-mail
        await sleep(10);

        //Se não existir nenhum campo vazio, salva no local storage e redireciona para a página seguinte
        if (empty.length < 1) {
            if (!validateCNPJ(removeMask(fields[0].value))) {
                showAlert("CNPJ Inválido");
                // } else if (!cnpjIsValid) {
                //     showAlert("O CNPJ digitado não consta na receita federal, verifique o CNPJ e tente novamente.");
            } else if (!validateCpf(removeMask(fields[6].value))) {
                showAlert("CPF Inválido");
            } else if (fieldsState[13].value && fieldsState[13].value == "") {
                showAlert("CNH está vazio");
            } else if (fieldsState[12].value && fieldsState[12].value == "") {
                showAlert("CRLV está vazio");
            } else {
                //Ajustando edição de agendamento
                if (
                    (history.location.state != undefined &&
                        history.location.state.status &&
                        history.location.state.status !== "10" &&
                        history.location.state.status !== "20") ||
                    updateOnlyTransporterData
                ) {
                    updateTransporterData(fields);
                    fieldEmail.removeEventListener("invalid", listener, false);
                } else {
                    fieldEmail.removeEventListener("invalid", listener, false);
                    showAlertRequisitos(() => {
                        setAlertConfig({ show: false });
                        goMountCharge(fields);
                    });
                }
            }
        } else {
            fieldEmail.removeEventListener("invalid", listener, false);
            empty.forEach((item) => {
                const element = document.getElementById(item.field);
                if (element) element.style.borderColor = Colors.colorRed;
            });
        }
    };

    const goMountCharge = async (fields) => {
        try {
            localStorage.removeItem("DATA_TRANSPORTER");
            localStorage.removeItem("EXTENSIONS");
            localStorage.setItem("EXTENSIONS", JSON.stringify(extensions));
            const crlv = await fields[14].value;
            const cnh = await fields[15].value;
            localStorage.setItem("DATA_TRANSPORTER", JSON.stringify(fields));

            history.push("/mount_charge", {
                idAgenda: dataBook.idAgenda,
                idAgendamento: dataBook.idAgendamento,
                dadosAgendamCarga,
                extensions:
                    crlv === "@MOCK" || cnh === "@MOCK"
                        ? ""
                        : `${extensions.crlv};${extensions.cnh}`,
                crlv,
                cnh,
            });
        } catch (e) {
            showAlert(
                "O arquivo anexado ultrapassa os limites de tamanho do navegador"
            );
        }
    };

    const onChangeCnpj = ({ target: { value } }) => {
        setCnpj(cnpjMask(value));
        if (removeMask(value).length >= 14) {
            callReceitaFederalService(removeMask(value));
        } else {
            // document.getElementById('Razão Social').disabled = false;
            setCnpjIsValid(false);
            setRazaoSocial("");
        }
    };

    const onChangeCpf = ({ target: { value } }) => {
        setCpf(cpfMask(value));
    };

    const onChangePhone = ({ target: { value } }) => {
        setTelefone(phoneMask(value));
    };

    const onChangeWhatsapp = ({ target: { value } }) => {
        setWhatsapp(phoneMask(value));
    };

    const callReceitaFederalService = async (cnpj) => {
        //Utilizando um lib diferente do Axios pois o serviço não retorna CORS corretamente
        try {
            jsonp(
                `https://www.receitaws.com.br/v1/cnpj/${cnpj}`,
                null,
                (err, data) => {
                    if (err) {
                        console.error(err.message);
                        // setRazaoSocial('');
                        setCnpjIsValid(false);
                        // document.getElementById('Razão Social').disabled = false;
                    } else {
                        const { status } = data;
                        if (status === "OK") {
                            const { nome } = data;
                            setRazaoSocial(nome.substring(0, 60));
                            // document.getElementById('Razão Social').disabled = true;
                            setCnpjIsValid(true);
                            changeColorValid("Razão Social");
                        } else {
                            const { message } = data;
                            // setRazaoSocial('');
                            // document.getElementById('Razão Social').disabled = false;
                            console.error(message);
                            setCnpjIsValid(false);
                        }
                    }
                }
            );
        } catch (e) {
            console.err("ERRO AO CONSULTAR O CNPJ -> ", e);
            setRazaoSocial("");
            setCnpjIsValid(false);
            // document.getElementById('Razão Social').disabled = false;
        }
    };

    const changeColorValid = (id, isGreen = false, isRed = false) => {
        const element = document.getElementById(id);
        element.style.borderColor = isGreen ? Colors.primaryColor : "#ccc";
        if (isRed) {
            element.style.borderColor = Colors.colorRed;
        }
    };

    const trucks = [
        { value: "01", option: "Caminhão Truck" },
        { value: "02", option: "Caminhão Trucado" },
        { value: "03", option: "Caminhão Simples" },
        { value: "04", option: "Caminhão Duplo Direcional Trucado" },
        { value: "05", option: "Caminhão + Reboque" },
        { value: "06", option: "Caminhão Trucado + Reboque" },
        { value: "07", option: "Romeu e Julieta" },
        { value: "08", option: "Caminhão Trator + Semi-reboque" },
        { value: "09", option: "Caminhão Trator + Semi-reboque (LS)" },
        { value: "10", option: "Caminhão Trator Trucado + Semi-reboque" },
        {
            value: "11",
            option: "Caminhão Trator Trucado + Semi-reboque (LS Trucado)",
        },
        { value: "12", option: "Treminhão" },
        { value: "13", option: "Bitrem com comprimento entre 17,50m a 19,80m" },
        { value: "14", option: "Bitrem com comprimento entre 19,80m a 30,0m" },
        { value: "15", option: "Rodotrem com comprimento entre 19,8m a 25,0m" },
        { value: "16", option: "Rodotrem com comprimento entre 25,0m a 30,0m" },
        { value: "17", option: "Tritrem" },
        { value: "18", option: "Bitrem de 8 Eixos" },
        { value: "19", option: "Bitrem de 9 Eixos" },
    ];
    /**
     * @description Pega o valor do arquivo anexado e guarda no estado do componente
     * @param { onChange event } e
     * @param {field attachment information} options
     * @returns void
     */
    async function onChangeFile({ target: { value, files } }, options) {
        try {
            const file = files[0];
            const file64 = await toBase64(file);
            let newList = [...fieldsState];
            newList[options.position].value = file64.split(",")[1];
            setFieldsState(newList);
        } catch (e) {
            showAlert(
                `Não foi possível converter o arquivo ${options.name} para Base64.`
            );
            return false;
        }

        changeColorValid(options.id, true);
        const ext = value.split(".")[value.split(".").length - 1];
        if (options.id === "AnCRLV")
            setExtensions({ ...extensions, crlv: ext });
        else setExtensions({ ...extensions, cnh: ext });
    }

    return (
        <Container>
            <Alert
                show={alertConfig.show}
                message={alertConfig.message}
                img={alertConfig.img}
                negativeButtonText={alertConfig.negativeText}
                negativeButtonClick={
                    alertConfig.negativeClick
                        ? alertConfig.negativeClick
                        : () => setAlertConfig(INTIAL_ALERT_CONFIG)
                }
                positiveButtonText={alertConfig.positiveText}
                positiveButtonClick={alertConfig.positiveClick}
            />
            <ModalLoading show={loading} />
            <Content onSubmit={(e) => e.preventDefault()}>
                <TitleBackgroundGreen text="Dados do transportador" />

                <Input
                    id="CNPJ"
                    label={"CNPJ"}
                    required
                    minLength={18}
                    type="text"
                    value={cnpj}
                    onChange={(e) => {
                        onChangeCnpj(e);
                        changeColorValid("CNPJ");
                    }}
                />
                <Input
                    id="Razão Social"
                    label="Razão Social"
                    maxLength={60}
                    required
                    type="text"
                    value={razaoSocial}
                    onChange={({ target: { value } }) => {
                        setRazaoSocial(value);
                        changeColorValid("Razão Social");
                    }}
                />
                <Input
                    id="Telefone"
                    label="Telefone"
                    required
                    minLength={14}
                    value={telefone}
                    type="text"
                    onChange={(e) => {
                        onChangePhone(e);
                        changeColorValid("Telefone");
                    }}
                    onKeyPress={onlynumber}
                />
                <Input
                    id="E-mail"
                    label="E-mail"
                    required
                    maxLength={241}
                    type="email"
                    value={email}
                    onChange={({ target: { value } }) => {
                        setEmail(value);
                        changeColorValid("E-mail");
                    }}
                />
                <Input
                    id="Motorista"
                    label="Motorista"
                    maxLength={60}
                    required
                    type="text"
                    value={motorista}
                    onChange={({ target: { value } }) => {
                        setMotorista(value);
                        changeColorValid("Motorista");
                    }}
                />
                <Input
                    id="Whatsapp"
                    label="Whatsapp"
                    required
                    minLength={14}
                    value={whatsapp}
                    type="text"
                    onChange={(e) => {
                        onChangeWhatsapp(e);
                        changeColorValid("Whatsapp");
                    }}
                    onKeyPress={onlynumber}
                />
                <Input
                    id="CPF"
                    label="CPF"
                    required
                    minLength={14}
                    type="text"
                    value={cpf}
                    onChange={(e) => {
                        onChangeCpf(e);
                        changeColorValid("CPF");
                    }}
                />
                <Input
                    id="Placa cavalo"
                    label="Placa cavalo"
                    maxLength="8"
                    required
                    type="text"
                    value={placaCavalo}
                    onChange={({ target: { value } }) => {
                        setPlacaCavalo(value);
                        changeColorValid("Placa cavalo");
                    }}
                />
                <Dropdown
                    name="Tipo Caminhão"
                    useName
                    required
                    labels={trucks}
                    value={tipoCaminhao}
                    onSelectChange={(value) => {
                        setTipoCaminhao(value);
                    }}
                />
                {tipoCaminhao >= 5 ? (
                    <Input
                        id="Placa carreta 1"
                        required
                        label="Placa carreta 1"
                        minLength="8"
                        maxLength="8"
                        type="text"
                        value={placaCarretaUm}
                        onChange={({ target: { value } }) => {
                            setPlacaCarretaUm(value);
                        }}
                    />
                ) : null}
                {tipoCaminhao >= 12 ? (
                    <Input
                        id="Placa carreta 2"
                        required
                        label="Placa carreta 2"
                        minLength="8"
                        maxLength="8"
                        type="text"
                        value={placaCarretaDois}
                        onChange={({ target: { value } }) => {
                            setPlacaCarretaDois(value);
                        }}
                    />
                ) : null}
                {tipoCaminhao == 12 || tipoCaminhao == 17 ? (
                    <Input
                        id="Placa carreta 3"
                        required
                        label="Placa carreta 3"
                        minLength="8"
                        maxLength="8"
                        type="text"
                        value={placaCarretaTreis}
                        onChange={({ target: { value } }) => {
                            setPlacaCarretaTres(value);
                        }}
                    />
                ) : null}
                <Input
                    id="Renasem"
                    label="Renasem"
                    maxLength="13"
                    type="text"
                    value={renasem}
                    onChange={({ target: { value } }) => {
                        setRenasem(value);
                    }}
                />
                <Input
                    id="Observação"
                    label="Observação"
                    maxLength="60"
                    type="text"
                    value={observacao}
                    onChange={({ target: { value } }) => {
                        setObservacao(value);
                    }}
                />
                <Row>
                    <Label>
                        Anexos <Red>*</Red>
                    </Label>
                    <Row>
                        <InputFile
                            id="CRLV"
                            idLabel="AnCRLV"
                            label="Anexar CRLV"
                            required
                            type="file"
                            accept="application/pdf"
                            onChange={(e) =>
                                onChangeFile(e, {
                                    name: "CRLV",
                                    id: "AnCRLV",
                                    position: 12,
                                })
                            }
                        />
                        <InputFile
                            id="CNH"
                            idLabel="AnCNH"
                            label="Anexar CNH"
                            required
                            type="file"
                            accept="application/pdf"
                            onChange={(e) =>
                                onChangeFile(e, {
                                    name: "CNH",
                                    id: "AnCNH",
                                    position: 13,
                                })
                            }
                        />
                    </Row>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    {
                        <Button
                            shadow
                            text={
                                statusAgendamento
                                    ? statusAgendamento === "10" ||
                                      statusAgendamento === "20"
                                        ? "ALTERAR MATERIAL A SER CARREGADO"
                                        : "Salvar dados"
                                    : "Escolher Material a ser carregado"
                            }
                            onClick={(e) => handlerSubmit(false, e)}
                            enable
                        />
                    }
                    {statusAgendamento === "10" ||
                    statusAgendamento === "20" ? (
                        <Button
                            shadow
                            style={{
                                width: "auto",
                                marginLeft: 20,
                            }}
                            text="Salvar dados"
                            enable
                            onClick={(e) => handlerSubmit(true, e)}
                        />
                    ) : null}
                </Row>
            </Content>
        </Container>
    );
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(StatusBarAction, dispatch);

export default connect(null, mapDispatchToProps)(TransporterData);
