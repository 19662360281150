import React from 'react';

import { Container, H1 } from './styles';

function TitleBackgroundGreen({ text }) {
    return (
        <Container>
            <H1>
                { text }
            </H1>
        </Container>
    );
}


export default TitleBackgroundGreen;