export function getTypeTruck(type) {
    switch (type) {
        case "01":
            return "Caminhão Truck";
        case "02":
            return "Caminhão Trucado";
        case "03":
            return "Caminhão Simples";
        case "04":
            return "Caminhão Duplo Direcional Trucado";
        case "05":
            return "Caminhão + Reboque";
        case "06":
            return "Caminhão Trucado + Reboque";
        case "07":
            return "Romeu e Julieta"
        case "08":
            return "Caminhão Trator + Semi-reboque";
        case "09":
            return "Caminhão Trator + Semi-reboque (LS)";
        case "10":
            return "Caminhão Trator Trucado + Semi-reboque";
        case "11":
            return "Caminhão Trator Trucado + Semi-reboque (LS Trucado)";
        case "12":
            return "Treminhão";
        case "13":
            return "Bitrem com comprimento entre 17,50m a 19,80m";
        case "14":
            return "Bitrem com comprimento entre 19,80m a 30,0m";
        case "15":
            return "Rodotrem com comprimento entre 19,8m a 25,0m";
        case "16":
            return "Rodotrem com comprimento entre 25,0m a 30,0m"
        case "17":
            return "Tritrem";
        case "18":
            return "Bitrem de 8 Eixos";
        case "19":
            return "Bitrem de 9 Eixos";
        default:
            return null;
    }
}

export function getTypeRevenues(type) {
    switch (type) {
        case "01":
            return "Vendas Direta";
        case "02":
            return "Venda Conta e Ordem";
        case "03":
            return "Venda Terceiro";
        default:
            return null;
    }
}