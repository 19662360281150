import styled from 'styled-components';
import btn from '../../components/Button';

import Input from '../../components/Input'
import Colors from '../../Util/Colors'
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: center;
    height: 100vh;
`;

export const Content = styled.form`
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    width: 51.5%;
    justify-content:center;
    align-items:center;    
     margin-top: 60px;

`;


export const Row = styled.div`
  display: flex; 
  width: 100%;
  flex-direction: row; 

`

export const Button = styled(btn)`
    margin-top: 40px;
`

export const Label = styled.label`
    display:   flex ;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    background-color: ${Colors.primaryColor} ;
    text-align:center;
    width:  195px;
    border-radius: 5px 0px 0px 5px; 
    font-size: 11px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    height: 44px;

    margin-top: 5px;
`

export const Red = styled.label`
    margin-left: 5px;
    color:red;
`;

export const InputFile = styled(Input)`
    margin:0;
`