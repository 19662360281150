import styled from 'styled-components';

import Colors from '../../Util/Colors';

export const Background = styled.div`
    display: flex;
    visibility: ${props => props.show ? 'initial' : 'collapse'};
    justify-content:center;
    align-items:center;
    width: 100%;
    height: 100vh;
    background: ${Colors.colorModal};
    position: fixed;
    left: 0;
    top: 0;
    overflow-y:hidden;
`;

export const BoxWhite = styled.div`
    background: white;
    padding:15px;
    border-radius:9px;

    img{
        width: 30px;
        height: 30px;
    }
`;