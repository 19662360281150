import React from 'react';

import { ButtonBox, IconAdd } from './style'
import plus from '../../assets/plus.svg'

const AddItem = ({
    text,
    onClick,
    font = 14,
    disabled
}) => {

    return (
        <ButtonBox disabled={disabled} onClick={onClick}><IconAdd src={plus}/>{text}</ButtonBox>
    )
};

export default AddItem;