import React, { useEffect, useRef } from 'react';

import { Container, Box, ContentButtons, Button, Body, FieldFile,Field } from './styles';

import Colors from '../../Util/Colors';
import { toBase64 } from '../../Util/Base64'


function AlertAttachment({
    show,
    type,
    IdAgendamento,
    onError,
    onSend,
    onClose
}) {

    const inputRef = useRef()

    useEffect(()=>{
        if(show){
           const input = document.getElementById('input')
           input.value = ''
        }
    },[show])

    const changeColorValid = (isGreen = false, isRed = false) => {
        const element = document.getElementById('label');
        element.style.borderColor = isGreen ? Colors.primaryColor : '#ccc';
        if (isRed) {
            element.style.borderColor = Colors.colorRed;
        }
    }

    async function onChangeFile({ target: { value, files } }) {
        try {
            const file = files[0]
            const file64 = await toBase64(file);
            const ext = value.split('.')[value.split('.').length - 1]
            changeColorValid(true)
            onSend(file64,ext,type,IdAgendamento)
            onClose()
        } catch (e) {
            onClose()
            onError(`Não foi possível converter o arquivo para Base64.`)
            return false;
        }  
    }

    return (
        <Container show={show}>
            <Box>
                <Body>
                    <FieldFile id={'label'} htmlFor={'input'}>
                        Anexo Documento
                        <Field ref={inputRef} id={'input'} type="file" required accept="application/pdf" onChange={onChangeFile} />
                    </FieldFile>
                </Body>
                <ContentButtons>
                    <Button onClick={onClose} type='cancel'> Fechar </Button>
                </ContentButtons>
            </Box>
        </Container>
    );
}

export default AlertAttachment;