import styled from 'styled-components';

import Color from '../../Util/Colors'

export const Container = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    width:100%;
    height: 400px;
    justify-content:center;
    align-items:center;
`;


export const Image = styled.img`
    width: 50px;
    height: 50px;
    margin: 0 auto;

`

export const Link = styled.p`
    font-size: 14px;
    cursor: pointer;
    color: ${Color.primaryColor};
    font-weight: bold;
`;