import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

import Colors from '../../Util/Colors'
import Devices from '../../Util/Devices'

export const Container = styled.div`
    display: flex;
    flex:1;
    width:100%;
    flex-direction: column;
`;

export const Row = styled.div`
    display:flex;
    flex-direction: row;
    flex:1;
    justify-content:center;
    align-items:center;
    text-align:center;
    border-radius: 5px;
    margin-top:5px;
    background: ${props => (props.isTitle && !props.isEnabled) ? '#CCC' : (props.isTitle && props.isEnabled) ? Colors.primaryColor : 'white'};
    border: 1px solid #CCCCCC;
`;

const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
    height: 100%;
    width: 100%;
    justify-content:center;
    align-items:center;
    text-align:center;
    border-right: ${props => props.isLast ? 'none' : props.isTitle ? '1px solid white' : '1px solid #ccc'};
    padding: 15px 0;
`;

export const DaysOfWeek = styled(Row)`
    margin: 0;
    border: ${ props => props.isLast ? 'none' : 'white'};
    background: ${Colors.colorGray};
    text-transform: uppercase;
    font-size: 11px;
`;

export const DaysOfMonth = styled(StyledColumn)`
    padding: 20px;
    height: 77px;
    font-size:11px;

    &:hover{
        cursor:pointer;
    }


    @media ${Devices.mobileL}{

    }

    @media ${Devices.mobileS}{
        padding: 5px;
        height: 30px;
    }
    @media ${Devices.laptop}{
        padding: 10px;
        height: 40px;
    }

   
`;

export const Title = styled.div`
    flex: 1;    
    padding: 15px 0;
    flex: 1;
    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    font-size: 11px;    
`;


export const Nav = styled.div`
    display:flex;
    flex:0;
    justify-content: ${props => props.direction === 'LEFT' ? 'flex-start' : 'flex-end'};
    padding-left: ${props => props.direction === 'LEFT' ? '15px' : 'none'};
    padding-right: ${props => props.direction === 'RIGHT' ? '15px' : 'none'};
    align-items:center;

    &:hover{
        cursor:pointer;
    }
`;

export const NavIcon = styled.img`
  
`;

export const Circle = styled.div`
    border-radius: 100%;
    width: 15px;
    height: 15px;
    margin-top: 9px;
    background: ${props => !props.isEnabled ? '#E6E6E6' : props.status ? Colors.primaryColor : Colors.colorRed};

    @media ${Devices.mobileS}{
        width: 10px;
        height: 10px;
    }

`;

export const Column = props => <StyledColumn {...props} />


StyledColumn.defaultProps = {
    isLast: false,
    isTitle: false
}

Column.propTypes = {
    isLast: PropTypes.bool,
    isTitle: PropTypes.bool
}