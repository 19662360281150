import styled from 'styled-components'
import Colors from '../../Util/Colors';
import Devices from '../../Util/Devices';
import { UpItem } from '../../components/ItemListMoutCharge/style'
import { Button } from '../../components/Button/style'

export const Table = styled.table`
    border-collapse: separate;
    border-spacing: 0 5px;
    width:100%;
    margin-top:40px;
    font-size: 11px;
    
    thead th{
      font-weight: 300;
    }
    tbody:first-child:hover ${UpItem}{
      display: hidden !important;
    }
  `;

export const RowHeader = styled.tr`  
  height: 44px;
  color:white;
  font-style: Regular;
`;

export const FisthItem = styled.th`
    background: ${ Colors.primaryColor};
    border-color: white;
    border-style: solid;
    border-width:  0px 1px 0px 0px;
    border-radius: 9px 0px 0px 9px;
`;

export const Item = styled.th`
background: ${ Colors.primaryColor};
  border-color: white;
  border-style: solid;
  border-width:  0px 1px 0px 0px;
`;

export const LastItem = styled.th`
    background: ${ Colors.primaryColor};
    border-radius: 0px 9px 9px 0px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin:auto auto;
    @media ${ Devices.mobileS} {
        width: 80%;
        font-size:8px;
    }
    @media ${Devices.tablet}{
        width: 50%;
        font-size:10px;
        max-width:1000px;
    }

    ${Button}{
      margin-top: 40px;
      margin-bottom: 20px;
    }
`;

export const BoxAddItem = styled.div`
  margin-left: auto;
  margin-bottom:31px;
`;