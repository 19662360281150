import React from 'react';

import { Input, Label } from './style'

const RadioOption = ({ id, text, name, style, position, required, value, onChange,onClick }) => {

    return (
        <Label position={position} style={style} onClick={onClick} >
            <Input type='radio' id={id} value={value} name={name} required={required} onChange={onChange} />
            {text}
        </Label>
    )
};

export default RadioOption;