import React from 'react';

import moment from 'moment'
import 'moment/locale/pt'

import PropTypes from 'prop-types';

import { Container, Row, Column, Title, DaysOfWeek, DaysOfMonth, Nav, NavIcon, Circle } from './styles'
import uuid from 'react-uuid';


import arrow_left from '../../assets/arrow_left.svg';
import arrow_right from '../../assets/arrow_right.svg';

import Alert from '../Alert';
import { generatePassword } from '../../Util/Functions';

const MONTH =
    ["Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ]
class Calendar extends React.Component {
    state = {
        dateContext: moment().locale('pt'),
        today: moment(),
        alertConfig: {
            show: false,
            message: ''
        },
        currentMonth: MONTH[new Date().getMonth()]
    }


    weekDays = moment.weekdays();
    weekDaysShort = moment.weekdaysShort();
    months = moment.months();

    year = () => this.state.dateContext.format("Y")

    month = () => this.state.dateContext.format("MMMM")

    daysInMonth = () => this.state.dateContext.daysInMonth();

    currentDate = () => this.state.dateContext.get('date');


    componentDidMount() {
        // this.props.onChangeCalendar({ numberMonth: this.state.dateContext._locale._months.indexOf(this.month()) + 1, month: this.month(), year: this.year() });
    }

    firstDayOfMonth = () => {
        let dateContext = this.state.dateContext;
        let firstDay = moment(dateContext).startOf('month').format('d');
        return firstDay;
    }

    setMonth = (month) => {
        let monthNo = this.months.indexOf(month);
        let dateContext = this.state.dateContext;
        dateContext = moment(dateContext).set("month", monthNo)
        this.setState({
            dateContext
        })
    }

    onSelectChange = (e, data) => {
        this.setMonth(data);
        this.props.onMonthChange && this.props.onMonthChange();
    }

    SelectList = (props) => {
        let popup = props.data.map((data) => {
            return (
                <div key={data}>
                    {data}
                </div>
            );
        });

        return (
            <div className="month-popup">
                {popup}
            </div>
        );
    }

    onChangeMonth = (e, month) => {
        this.setState({
            showMonthPopup: !this.state.showMonthPopup
        })
    }

    MonthNav = () => {
        return (
            <span>
                {this.month()}
                {this.state.showMonthPopup &&
                    <this.SelectList data={this.months} />
                }
            </span>
        );
    }

    showYearEditor = () => {
        this.setState({
            showYearNav: true
        })
    }

    setYear = year => {
        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).set("year", year);
        this.setState({
            dateContext
        })
    }

    onYearChange = e => {
        this.setYear(e.target.value);
        this.props.onYearChange && this.props.onYearChange(e, e.target.value);


    }

    YearNav = () => (
        <span>
            {this.year()}
        </span>
    )

    onKeyUpYear = e => {
        if (e.which === 13 || e.which === 27) {
            this.setYear(e.target.value);
            this.setState({
                showYearNav: false
            })
        }
    }

    onDayClick = (e, day) => {
        this.setState({
            selectDay: day
        });


        // this.props.books.forEach(item => {

        //     const dayItem = parseInt(removeMask(item.Data));            
        //     let yearToday = this.state.today.getFullYear();
        //     let monthToday = this.addZero(this.state.today.getMonth() + 1)
        //     let dayToday = this.addZero(this.state.today.getDate());
        //     const today = `${yearToday}${monthToday}${dayToday}`
        //     const allowed = ((date + 3 >= today) && (date >= (parseInt(today) + 3)));
        //     if ( == dayItem && allowed) {
        //         status = item.Status == "01";
        //         return item.Status == "01";
        //     }
        // })

        this.props.onClickDay && this.props.onClickDay(e, day);
    }

    onNext = () => {
        let current = "";
        if (this.state.showYearNav === true) {
            current = "year";
        } else {
            current = "month"
        }

        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = this.state.dateContext.add(1, current);

        this.setState({
            dateContext,
            currentMonth: MONTH[dateContext.format("M") - 1]
        })

        this.props.onChangeCalendar({ numberMonth: this.state.dateContext._locale._months.indexOf(this.month()) + 1, month: this.month(), year: this.year() });

    }

    onPrev = () => {
        let current = "";
        if (this.state.showYearNav === true) {
            current = "year";
        } else {
            current = "month"
        }

        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = this.state.dateContext.subtract(1, current);

        this.setState({
            dateContext,
            currentMonth: MONTH[dateContext.format("M") - 1]
        })

        this.props.onChangeCalendar({ numberMonth: this.state.dateContext._locale._months.indexOf(this.month()) + 1, month: this.month(), year: this.year() });

    }

    checkStatus = (date) => {
        //Status pra saber se é bloqueado ou não, incialmente a data é bloqueada
        let status = false;

        //ForEach no array de agendamentos deisponíveis
        this.props.books.forEach(item => {
            //pega a data de acordo com a quem vem do serviço
            const dataItem = new Date(item.Data);
            //data disponível para agendamento
            const allowedDate = new Date();
            //Data que vem do calendário
            const dateCalendar = new Date(date);
            //data atual
            const today = new Date();


            //Data que vem do serviço concatenda em long no padrão YYYYMMDD
            const dataItemFormat = parseInt(`${dataItem.getFullYear()}${this.addZero(dataItem.getMonth() + 1)}${this.addZero(dataItem.getDate())}`);
            //Data atual concatenada em long no padrão YYYYMMDD
            const dataTodayFormat = parseInt(`${today.getFullYear()}${this.addZero(today.getMonth() + 1)}${this.addZero(today.getDate())}`);
            const dataTodayFormatMoreOne = parseInt(`${today.getFullYear()}${this.addZero(today.getMonth() + 1)}${this.addZero(today.getDate() + 1)}`);
            //Data do calendário concatenada em long no padrão YYYYMMDD
            const dataCalendarFormat = parseInt(`${dateCalendar.getFullYear()}${this.addZero(dateCalendar.getMonth() + 1)}${this.addZero(dateCalendar.getDate())}`);
            //Data do allowed concatenada em long no padrão YYYYMMDD
            const dataAllowedFormat = parseInt(`${allowedDate.getFullYear()}${this.addZero(allowedDate.getMonth() + 1)}${this.addZero(allowedDate.getDate())}`);

            //Setando a data disponível para agendamento
            allowedDate.setDate(allowedDate.getDate() + 2)

            //Guarda o resultado da comparação das datas
            /**
             * @description
             * se a data do calendário for maior ou igual a data disponível retorna true
             * caso contrãrio retorna false
             */

            const exist = this.props.books.find(item => item.Data === date);
            //Condição que verifica se o dia vai estár dispnível para agendamento ou não
            if (item.Status === '01' && exist) {
                if (dataCalendarFormat != dataTodayFormat && dataCalendarFormat != dataTodayFormatMoreOne) {
                    if (dataCalendarFormat >= dataAllowedFormat) {
                        if (dataItemFormat >= dataAllowedFormat) {
                            status = item.Status === '01';
                            return status;
                        }
                    }
                }

            }
        })

        return status;
    }

    addZero = (number) => {
        return number <= 9 ? "0" + number : number;
    }

    showAlert() {
        this.setState({
            alertConfig: {
                show: true,
                message: 'Data indisponível para agendamento'
            }
        })
    }

    render() {
        let weekDays = this.weekDaysShort.map((day, index) => {

            return (
                <Column key={day + this.daysInMonth()} isTitle isLast={index === 6}>
                    {day}
                </Column>);
        }
        );

        let blanks = [];
        let CellBlank = pos => <DaysOfMonth key={pos * 80} > <span>{""}</span> </DaysOfMonth>
        for (let i = 0; i < this.firstDayOfMonth(); i++) {
            blanks.push(<CellBlank pos={i} key={uuid()} />)
        }

        let daysInMonth = [];
        let currentMonth = this.state.dateContext.month() + 1
        let currentYear = this.state.dateContext.year()
        for (let d = 1; d <= this.daysInMonth(); d++) {
            let status = this.checkStatus(`${currentYear}-${this.addZero(currentMonth)}-${this.addZero(d)}`);
            this.props.books.map(item => {
                if (this.addZero(d) == item.Data.substring(item.Data.length - 2)) {
                    if (item.Status === '02') {
                        status = false;
                    };
                }
            });

            daysInMonth.push(
                <DaysOfMonth key={uuid()} onClick={e => !this.props.isEnabled ? null : status ? this.onDayClick(e, d) : this.showAlert()}>
                    <span >{d}</span>
                    <Circle isEnabled={this.props.isEnabled} status={status} />
                </DaysOfMonth>
            )
        }

        var totalSlots = [...blanks, ...daysInMonth];
        let rows = [];
        let cells = [];


        totalSlots.forEach((row, index) => {
            if (index % 7 !== 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }

            if (index === totalSlots.length - 1) {
                rows.push(cells);
            }
        });
        rows.splice(0, 1)

        let loop = 7 - rows[rows.length - 1].length;

        if (loop > 0) {
            for (let i = 1; i <= loop; i++) {
                rows[rows.length - 1].push(<CellBlank key={uuid()} pos={i} />);
            }
        }


        let trElems = rows.map((d, i) => (
            <Row key={uuid()}>
                {d}
            </Row>
        ));

        return (
            <Container style={{ display: this.props.visible ? 'block' : 'none' }}>
                <Alert
                    show={this.state.alertConfig.show}
                    message={this.state.alertConfig.message}
                    negativeButtonText="Fechar"
                    negativeButtonClick={() => { this.setState({ alertConfig: { show: false, message: '' } }) }}
                />
                <Row isTitle isEnabled={this.props.isEnabled}>
                    <Nav onClick={this.props.isEnabled ? this.onPrev : null} direction="LEFT">
                        <NavIcon src={arrow_left} />
                    </Nav>
                    <Title>
                        {this.state.currentMonth}
                        {" "}
                        <this.YearNav />
                    </Title>
                    <Nav onClick={this.props.isEnabled ? this.onNext : null} direction="RIGHT">
                        <NavIcon src={arrow_right} />
                    </Nav>
                </Row>

                <Row>
                    <DaysOfWeek isEnabled={this.props.isEnabled}>
                        {weekDays}
                    </DaysOfWeek>
                </Row>
                {trElems}
            </Container>)
    }
}

Calendar.propTypes = {
    onClickDay: PropTypes.func,
    onChangeCalendar: PropTypes.func,
    status: PropTypes.string,
}

export default Calendar;