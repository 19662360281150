import { createActions, createReducer } from 'reduxsauce';

const INITAL_STATE = {
    visibleError: false
}

export const { Types, Creators } = createActions({
    setVisibleError: ['visibleError'],
})

const setVisibleError = (state = INITAL_STATE, action) =>
    ({ ...state, visibleError: action.visibleError });

export default createReducer(INITAL_STATE, {
    [Types.SET_VISIBLE_ERROR]: setVisibleError,
});
