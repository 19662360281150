import React from 'react';

import PropTypes from 'prop-types';

import { Button as CustomButtom } from './style';


const Button = (props) => (
    <CustomButtom {...props} disabled={props.disabled}>
        {props.text}
    </CustomButtom>
);

Button.defaultProps = {
    disabled: false
}

Button.propTypes = {
    disabled: PropTypes.bool,
    shadow: PropTypes.bool,
    text: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

export default Button;