import React, { useEffect,memo } from 'react'
import { pure } from 'recompose';

import { Select, Label, BoxSelect, Option, Red } from './style'

const Dropdown = ({ labels, required = false, name, useName = false, onSelectChange, id, value = '' }) => {
    useEffect(() => {
        onSelectChange(value !== '' ? value : labels[0].value)
    }, [])
    const handleChange = (event) => {
        let selectedValue = event.target.value;
        onSelectChange(selectedValue);
    }

    return (<BoxSelect>
        <Label htmlFor={name}>{useName ? name : 'Opções'}{required ? (<Red>*</Red>) : null}</Label>
        <Select id={id} name={name} onChange={handleChange} options={labels} value={value} >
            {
                labels.map(item => (
                    <Option key={item.value} value={item.value}>{item.option}</Option>
                ))
            }
        </Select>
    </BoxSelect>
    )
}

export default pure(Dropdown);