const Strings = {
    CONFIRM_EMAIL: "Foi enviado uma E-mail de recuperação de senha para o seu e-mail de cadastro",
    REQUEST_NEW_PASSWORD: "A sua senha temporária solicitada expirou, por favor, solicite uma nova senha.",
    HTML: `
    <center>
    <div style="justify-content: center; align-items: center ;color:#97B43C; width: 100%;font-family: arial; background-color: white;border-bottom: 10px solid #97B43C;height: 60px; text-align: center;">
        <center> Recuperação de senha </center>
    </div>
    <br>
    <div style="flex-direction: column;font-family: arial;justify-content: center; align-items: center ;">
    <p style="text-align: center;">
        Olá, segue abaixo uma senha temporária para fazer login e efetuar a troca de senha. <br>
        Fique atento pois a senha é temporária e tem a duração máxima de 30 minutos, valendo <br> a partir da sua solicitação no portal.
    </p>
    <br>
    <a href="CODE/?t=CODE" target="_blank" style="color: white; text-decoration:none">
    <div  style="justify-content: center; align-items: center ;background: #97B43C; border: none;font-family: arial; border-radius: 5px;padding:10px; width: 150px;color:white;">
       CODE
    </div>
    </a>
    <p>Obs: Clique no código acima para seguir com a redefinição de senha</p>
    <br>
    <br>
    <!-- <img  style="width: 100px; height: 100px;" src="cid:bs_logo" alt=""> -->
    </div>
    </center>
    `
}

export function concat(string, texts = [], separator = "#") {
    let arr = string.split(separator);
    let value = "";
    texts.forEach((txt, index) => {
        value += arr[index] + txt;
        if (index + 1 === arr.length - 1) {
            value += arr[index + 1];
        }
    })

    return value;
}
export default Strings;