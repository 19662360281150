import { TypeUser } from './TypeUser'
import { environment } from './environment'
export function getResponseError(e) {
    if (e.message === 'Network Error') {
        return 'Erro de conexão, Tente Novamente'
    }
    if (e.response) {
        return e.response.data.message
    } else {
        return e.message
    }
}

export function getResponseErrorObjectResponse(e) {
    if (e.message === 'Network Error') {
        return 'Erro de conexão, Tente Novamente'
    }
    if (e.response) {
        return { message: e.response.data.message, error: e.response.data.error }
    } else {
        return e.message
    }
}


export function checkSessionUser() {
    let id = localStorage.getItem('ID');
    if (id) {
        return true;
    } else {
        return false;
    }
}

export async function checkIfAdmin() {
    const access = await getLocalStorage('ACCESS')
    return access === TypeUser.admin;
}


export function generatePassword() {
    let prefix = 'ReQ-';
    const first = Math.floor(Math.random() * 10);
    const second = Math.floor(Math.random() * 10);
    const third = Math.floor(Math.random() * 10);
    const fourth = Math.floor(Math.random() * 10);
    return `${prefix}${first}${second}${third}${fourth}`;
}
export function sleep(time) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, time);
    })
}

export function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return {
        minutes,
        seconds
    }
}

export function getIdWithZero(id) {
    return "0000000000".substring(id.length) + id;
}

export const encrypt = (security, pass = environment.passCript) => {
    const crypto = require("crypto-js");
    return crypto.AES.encrypt(security, pass).toString();
}

export const decrypt = (pass, security) => {
    try {
        const crypto = require("crypto-js");
        const bytes = crypto.AES.decrypt(security, pass);
        return bytes.toString(crypto.enc.Utf8);
    } catch (e) {
        console.error("Erro ao decriptografar dados");
        return ''
    }

}

export async function getLocalStorage(key) {
    const result = localStorage.getItem(key);
    const strdecrypt = decrypt(environment.passCript, result);
    return strdecrypt;
}


export function formateDate(format) {
    try {
        let arrayDate = format.split('-');
        let formatDate = `${addZero(arrayDate[2])}/${addZero(arrayDate[1])}/${arrayDate[0]}`
        return formatDate;
    } catch (e) {
        return '00/00/0000'
    }

}

export function addZero(number) {
    return parseInt(number) <= 9 ? `0${parseInt(number)}` : number;
}

export function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    let pagination = array.slice((page_number - 1) * page_size, page_number * page_size);
    return pagination;
}
