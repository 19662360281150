import React from 'react';

import { Container, Row, Image, Label, Column, Title, Regular } from './style'

import { getTypeTruck, getTypeRevenues } from '../../Util/Valeus';
import { cpfMask, cnpjMask, maskCpfCnpj, phoneMaskPrint } from '../../Util/Mask';

import logo from '../../assets/logo.svg'

const EMPTY = 'Não informado';



function PDFView({ item: { DadosAgendamAnexo, DadosAgendamCarga, DadosAgendamTransp, IdAgendamento, DataAgendamento, DataCrtAgendamento, UserCrtAgendamento } }) {

    return DadosAgendamCarga ? (
        <Container id="pdf">
            <Row>
                <Image src={logo} />
                <Column orientation="rigth">
                    <Label style={{ marginRight: 10 }}>ID Agendamento:{" "}<Regular>{IdAgendamento}</Regular></Label>
                    <Label style={{ marginRight: 10 }}>Data Agendamento:{" "} <Regular>{DataAgendamento}</Regular></Label>
                </Column>
            </Row>
            <Title>TRANSPORTADORA / MOTORISTA</Title>
            <Row>
                <Label>TRANSPORTADORA: <Regular>{DadosAgendamTransp.item[0].RazaoSocial}</Regular></Label>
                <Label style={{ marginRight: 10 }}> <Regular>CNPJ: {cnpjMask(DadosAgendamTransp.item[0].TranspCnpj) || EMPTY}</Regular></Label>
            </Row>
            <Row>
                <Label style={{ flex: 1 }}><Regular>FONE:  {phoneMaskPrint(DadosAgendamTransp.item[0].TranspTel) || EMPTY}</Regular></Label>
                <Label style={{ flex: 1 }}><Regular>EMAIL: {DadosAgendamTransp.item[0].TranspEmail || EMPTY}</Regular></Label>
            </Row>
            <Row>
                <Label>MOTORISTA: <Regular>{DadosAgendamTransp.item[0].MotNome || EMPTY}</Regular></Label>
                <Label style={{ marginRight: 10 }}> <Regular>CPF: {cpfMask(DadosAgendamTransp.item[0].MotCpf) || EMPTY}</Regular></Label>
            </Row>
            <Row>
                <Label style={{ flex: 1 }}><Regular>PLACA/UF:  {DadosAgendamTransp.item[0].PlacaCavalo || EMPTY}</Regular></Label>
                <Label style={{ flex: 1 }}> <Regular>TIPO CAMINHÃO: {getTypeTruck(DadosAgendamTransp.item[0].TpCaminhao) || EMPTY}</Regular></Label>
            </Row>
            <Row>
                <Label style={{ flex: 1 }}><Regular>WHATSAPP: {phoneMaskPrint(DadosAgendamTransp.item[0].MotTel) || EMPTY}</Regular></Label>
            </Row>
            <Row>
                <Label><Regular>PLACA CARRETA 1:  {DadosAgendamTransp.item[0].PlacaCarreta1 || EMPTY}</Regular></Label>
                <Label><Regular>PLACA CARRETA 2: {DadosAgendamTransp.item[0].PlacaCarreta2 || EMPTY}</Regular></Label>
                <Label style={{ marginRight: 10 }}><Regular>PLACA CARRETA 3: {DadosAgendamTransp.item[0].PlacaCarreta3 || EMPTY}</Regular></Label>
            </Row>

            <Title style={{ fontSize: 12 }}>USUÁRIO E DATA DE CRIAÇÃO DA CARGA</Title>
            <Title style={{ fontSize: 10, marginTop: -10 }}>{UserCrtAgendamento} - {DataCrtAgendamento}</Title>
            <div class="html2pdf__page-break"></div>
            {
                DadosAgendamCarga.map((carga, index) => {                    
                    return (
                        <>
                            <Title>PRODUTO / VOLUMES</Title>
                            <Row>
                                <Label style={{ flex: 1 }}><Regular>SEQ. CARGA:  {carga.SeqCarga}</Regular></Label>
                                <Label style={{ flex: 1 }}> <Regular>PEDIDO: {carga.OrdemVenda}</Regular></Label>
                                <Label style={{ flex: 1 }}> <Regular>ITEM: {carga.OrdemItem}</Regular></Label>
                                <Label style={{ flex: 1 }}> <Regular>EMB: {carga.EmbCarga}</Regular></Label>
                                <Label style={{ flex: 1 }}> <Regular>QUANT: {carga.OrdemQtd}</Regular></Label>
                            </Row>
                            <Label style={{ flex: 1 }}> <Regular>DESCRIÇÃO: {carga.OrdemDesc}</Regular></Label>

                            <Title>DADOS PARA FATURAMENTO</Title>
                            <Label style={{ flex: 1 }}><Regular>TIPO PEDIDO:  {getTypeRevenues(carga.TipoFat) || EMPTY}</Regular></Label>
                            <Row>
                                <Label style={{ flex: 2 }}><Regular>RAZÃO SOCIAL:  {carga.RazaoSocialFat || EMPTY}</Regular></Label>
                                <Label style={{ flex: 1 }}> <Regular>CPF/CNPJ: {maskCpfCnpj(carga.CnpjCpfFat) || EMPTY}</Regular></Label>
                                <Label style={{ flex: 1 }}> <Regular>I.E: {carga.IeFat || EMPTY}</Regular></Label>

                            </Row>
                            <Row>
                                <Label style={{ flex: 1 }}><Regular>ESTADO:  {carga.Estado || EMPTY}</Regular></Label>
                                <Label style={{ flex: 1 }}> <Regular>CIDADE: {carga.Cidade || EMPTY}</Regular></Label>
                                <Label style={{ flex: 2 }}> <Regular>BAIRRO: {carga.Bairro || EMPTY}</Regular></Label>
                                <Label style={{ flex: 1 }}> <Regular>CEP: {carga.Cep || EMPTY}</Regular></Label>

                            </Row>
                            <Row>
                                <Label style={{ flex: 2 }}><Regular>END: {carga.Endereco || EMPTY}</Regular></Label>
                                <Label style={{ flex: 1 }}> <Regular>Nº: {carga.Numero || EMPTY}</Regular></Label>

                            </Row>
                            <Row>
                                <Label style={{ flex: 2 }}><Regular>EMAIL: {carga.EmailFat || EMPTY}</Regular></Label>
                                <Label style={{ flex: 1 }}> <Regular>WHATSAPP: {phoneMaskPrint(carga.TelFat) || EMPTY}</Regular></Label>
                            </Row>
                            {                               
                                    (index + 1) % 2 === 0 &&
                                    index !== 0 ?
                                    <div class="html2pdf__page-break"></div>
                                    : ''
                            }
                        </>
                    )
                })
            }
        </Container>
    ) : (null);
}

export default PDFView;