import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
    url: '/'
}

export const { Types, Creators } = createActions({
    redirect: ['url']
})

const redirect = (state = INITIAL_STATE, action) =>
    ({ url: action.url });
    
export default createReducer(INITIAL_STATE, {
    [Types.REDIRECT]: redirect
})

