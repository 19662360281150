import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

import Colors from '../../Util/Colors'
import Devices from '../../Util/Devices'


export const Container = styled.div`
    display:flex;
    flex:1;
    width:100%;
    flex-direction: row;
    margin-top: 5px;
    height: 41px;
      
    @media ${Devices.mobileS}{
        height: 40px;
        width:100%;
    }

    @media ${Devices.mobileM}{
        height: 50px;
        width:100%;
    }

    @media ${Devices.laptop}{
        height: 41px;
    }

   
`;

const StyledLabel = styled.label`
    display:   flex ;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    background-color: ${props => props.status ? Colors.primaryColor : Colors.colorRed};
    text-align:center;
    width: ${props => props.login ? '0px' : props.radius === 22 ? 60 : 201.1}px;
    border-radius: ${props => `${props.radius}px 0 0 ${props.radius}px`}; 
    font-size: 11px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    height: 44px;

`;


export const ContentFile = styled.div`
  display: flex;  
  width: 52%;
  height: 100%;
`




const StyledField = styled.input`
    border: 1px solid ${props => props.login ? Colors.primaryColor : '#CCCCCC'};
    border-radius: ${props => props.login ? `${props.radius}px` : `0 ${props.radius}px ${props.radius}px 0`};
    width: 100%;
    padding: ${props => props.login ? 10 : 0}px 13px;
    display: ${props => props.type === 'file' ? 'none' : 'flex'};
    height: ${props => props.login ? 'initial' :'42px'};
    
    &:focus{
        outline: 0;
    }
    
    &:hover:disabled{
        cursor: not-allowed
    }
`;


export const FieldFile = styled.label`
    display: flex;
    flex-direction: column;
    flex:1;
    -ms-flex:1;
    border-radius: 9px;
    border: 1px ${Colors.primaryColor} solid;
    justify-content:center;
    align-items: center;
    text-align:center;
    margin-left: 8.65px;
    color:${Colors.primaryColor};
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    height: 100%;
    
    &:hover{
        cursor: pointer;
    }

    &:hover:disabled{
        cursor: not-allowed
    }
`;


export const Red = styled.div`
    color:red;
    margin-left: 5px;
`;
export const Field = props => <StyledField {...props} />
export const Label = props => <StyledLabel {...props} />

StyledField.defaultProps = {
    radius: 5
}

StyledLabel.defaultProps = {
    radius: 5,
    status: true
}

Field.propTypes = {
    radius: PropTypes.number
}

Label.propTypes = {
    radius: PropTypes.number
}

Label.propTypes = {
    status: PropTypes.bool
}   