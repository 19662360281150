import React from 'react';

import { Container, ButtonRemove, ActionRows, ActionButtons } from './styles';

import TitleBackgroundGreen from '../../components/TitleBackgroundGreen';
import Input from '../../components/Input';
import AddItem from '../../components/AddItem';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import ModalLoading from '../../components/ModalLoading';
import Alert from '../../components/Alert';

import Api from '../../server/index';

import { cpfMask, cnpjMask, removeMask, phoneMask } from '../../Util/Mask';
import Colors from '../../Util/Colors';


import { getResponseError, getResponseErrorObjectResponse, checkSessionUser, checkIfAdmin, getLocalStorage } from '../../Util/Functions'
import { validateCpf, validateCNPJ } from '../../Util/Validate';
import { sleep } from '../../Util/Functions';
import removeIcon from "../../assets/remove_icon.svg"
import { TypeUser } from '../../Util/TypeUser';
export const registerUserPath = '/register_user';


class RegisterUser extends React.Component {

    state = {
        cnpjs_consult: [],
        selectedValue: TypeUser.operator,
        show: false,
        name: '',
        password: '',
        email: '',
        phone: '',
        cpf: '',
        cnpj: '',
        alertConfig: {
            show: false,
            message: ''
        },
        type_user: '',
        cnpj_field_disable: false,
    };

    optionsUser = [
        { option: 'Administrador', value: TypeUser.admin },
        { option: 'Operador', value: TypeUser.operator },
        { option: 'Dashboard', value: TypeUser.dashboard },
        { option: 'Transportadora',value: TypeUser.shippingCompany}
    ];

    onChangeName = ({ target: { value } }) => {
        this.setState({ name: value })
    }


    onChangePass = ({ target: { value } }) => {
        this.setState({ password: value })
    }

    onChangeEmail = ({ target: { value } }) => {
        this.setState({ email: value })
    }

    onChangePhone = ({ target: { value } }) => {
        this.setState({ phone: phoneMask(removeMask(value)) })
    }

    onChangeCpf = ({ target: { value } }) => {
        this.setState({ cpf: cpfMask(value) })
    }

    onChangeCnpj = ({ target: { value } }) => {
        this.setState({ cnpj: cnpjMask(value) })
    }

    onChangeCnpjs = ({ target: { value } }, index) => {
        let newCNPJs = this.state.cnpjs_consult;

        newCNPJs[index].value = cnpjMask(value);

        this.setState({ cnpjs_consult: newCNPJs })
    }

    handlerSubmit = async (event) => {
        if ((!this.state.cpf && !this.state.cnpj)) {
            this.showAlert(`É obrigatório o preenchimento do CPF ou CNPJ`)
            event.preventDefault();

            return false
        }
        let cnpjs_consult = [];
        event.preventDefault();

        const cpf = removeMask(this.state.cpf);
        if (this.state.selectedValue != TypeUser.dashboard && cpf.length > 0 && !validateCpf(cpf)) {
            this.showAlert('CPF inválido');
            event.preventDefault();
            return false;
        }


        this.state.cnpjs_consult.map((item, index) => {
            let cnpj = removeMask(item.value)
            if (!validateCNPJ(cnpj)) {
                this.showAlert(`CNPJ Consulta - ${index + 1} inválido`);
                event.preventDefault();
                return false;
            }
            cnpjs_consult.push(cnpj);
        });


        let body = {
            name: this.state.name,
            email: this.state.email,
            phone: removeMask(this.state.phone),
            profiles: [
                this.state.selectedValue
            ],
            cnpjs_consult
        }

        let cnpj = removeMask(this.state.cnpj);

        if (this.state.selectedValue != TypeUser.dashboard && cnpj.length > 0 && !validateCNPJ(cnpj)) {
            this.showAlert(`CNPJ inválido`);
            event.preventDefault();
            return false;
        }
        if (cnpj.length > 0) {
            body = { ...body, cnpj }
        }

        if (cpf.length > 0) {
            body = { ...body, cpf }
        }

        if (this.state.password !== '') {
            body = { ...body, firstAuthenticate: true, password: this.state.password }
        }
        try {
            let statusCode = 200;
            this.setState({
                show: true
            })
            if (this.props.location.state.type === 'cad') {
                const { status } = await Api.post('/users', body, {
                    headers: {
                        Authorization: "Bearer " + await getLocalStorage("TOKEN")
                    }
                });
                statusCode = status;
            } else {
                const { status } = await Api.patch(`users/${this.props.location.state.user._id}`, body, {
                    headers: {
                        Authorization: "Bearer " + await getLocalStorage("TOKEN")
                    }
                });
                statusCode = status;
            }

            if (statusCode === 200) {
                this.props.history.goBack();
            } else {
                event.preventDefault();
                return false;
            }
            this.setState({
                show: false
            })
        } catch (e) {
            this.setState({
                show: false
            })
            let response = getResponseErrorObjectResponse(e);
            if (response) {
                try {
                    this.showAlert(response.message);
                } catch (e) {
                    this.showAlert(`Erro ao salvar usuário, para mais detalhes técnicos: ${e.message}`);
                }
            }
            else
                this.showAlert('Ocorreu um erro tente novamente')
            event.preventDefault();
            return false;
        }

    }

    addCnpj = (event) => {
        var newCpnjs = [...this.state.cnpjs_consult];
        var newCNPJ = { label: `CPNJ consulta - ${this.state.cnpjs_consult.length + 1}`, key: `CPNJ${this.state.cnpjs_consult.length - 1}`, value: '', typer: 'number', mask: 'cnpj' };
        newCpnjs.push(newCNPJ);
        this.setState({ cnpjs_consult: newCpnjs });
        event.preventDefault();
    }

    handleSelectChange = (selectedValue) => {
        this.setState({
            selectedValue
        });
    }

    componentWillMount() {
        const type_user = this.props.location.state.type;

        window.onkeypress = e => {
            if (e.key === 'Enter') {
                return false;
            }
        }

        if (type_user)
            this.setState({ type_user });
        if (!checkSessionUser() || !checkIfAdmin()) {
            //Verifica o nível de acesso do usuário para redirecionar para á página
            this.props.history.replace('/');
            return false;
        }
    }

    componentDidMount() {
        const { user, type } = this.props.location.state;
        if (user) {

            let currentCnpjs = [];

            user.cnpjs_consult.map((item, index) => {
                currentCnpjs.push({ label: `CNPJ Consulta - ${index + 1}`, key: `CPNJ${index}`, value: cnpjMask(item), mask: 'cnpj' })
            });

            this.setState({
                cnpjs_consult: currentCnpjs,
                name: user.name,
                email: user.email,
                phone: phoneMask(user.phone),
                cpf: cpfMask(user.cpf || ''),
                cnpj: cnpjMask(user.cnpj || '')
            })

            this.cnpjDisable(user.cnpj, type);

            const slp = async () => {
                await sleep(200);
                document.getElementById('optionsUser').value = user.profiles[0];
                this.state.cnpjs_consult.map(item => {
                    document.getElementById(item.key).value = item.value;

                })
                this.setState({
                    selectedValue: user.profiles[0]
                })
            }
            slp();
        }
    }

    showAlert = (message) => {
        this.setState({
            alertConfig: {
                show: true,
                message
            }
        })
    }

    cnpjDisable = (cnpj, type) => {
        if (type !== 'edit') {
            this.setState({ cnpj_field_disable: false });
            return false;
        }

        this.setState({ cnpj_field_disable: cnpj && cnpj.length === 14 });
    }

    render() {
        return (
            <>
                <Container onSubmit={this.handlerSubmit}>
                    <TitleBackgroundGreen text={this.state.type_user === 'cad' ? 'Cadastrar usuário' : 'Editar Usuário'} />
                    <Input
                        label={'Nome'}
                        type={'text'}
                        onChange={this.onChangeName}
                        minLength={4}
                        maxLength={80}
                        value={this.state.name}
                        required />

                    <Input
                        label={this.state.type_user === 'edit' ? 'Nova senha' : 'senha'}
                        type={'password'}
                        onChange={this.onChangePass}
                        minLength={6}
                        maxLength={80}
                        value={this.state.password}
                        required={this.state.type_user === 'edit' ? false : true} />

                    <Input
                        label={'Email'}
                        type={'email'}
                        onChange={this.onChangeEmail}
                        maxLength={80}
                        value={this.state.email}
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
                    <Input
                        label={'Celular'}
                        type={'text'}
                        onChange={this.onChangePhone}
                        minLength={15}
                        mask={'phone'}
                        value={this.state.phone}
                        required />

                    <Input
                        label={'Cpf'}
                        type={'text'}
                        onChange={this.onChangeCpf}
                        minLength={14}
                        disabled={this.state.type_user === 'edit' ? true : false}
                        mask={'cpf'}
                        value={this.state.cpf} />

                    <Input
                        label={'Cnpj'}
                        type={'text'}
                        onChange={this.onChangeCnpj}
                        minLength={18}
                        disabled={this.state.cnpj_field_disable}
                        mask={'cnpj'}
                        value={this.state.cnpj} />

                    <Dropdown labels={this.optionsUser} name={'optionsUser'} id={'optionsUser'} onSelectChange={this.handleSelectChange} value={this.state.selectedValue} />
                    <table>
                        <tbody>
                            {
                                this.state.cnpjs_consult.map((item, index) => (
                                    <tr>
                                        <td>
                                            <Input
                                                key={item.key}
                                                label={item.label}
                                                type={item.type ? item.type : 'text'}
                                                attachments={item.attachments}
                                                onChange={(e) => this.onChangeCnpjs(e, index)}
                                                value={item.value}
                                                mask={item.mask}
                                                required
                                                id={'CPNJ' + index}
                                            />
                                        </td>
                                        <ActionRows>
                                            <ButtonRemove onClick={(e) => {
                                                e.preventDefault()
                                                const arr = [...this.state.cnpjs_consult]
                                                arr.splice(arr.indexOf(item), 1)
                                                this.setState({
                                                    cnpjs_consult: arr
                                                })
                                            }}>
                                                <div>
                                                    <img src={removeIcon} />
                                                </div>
                                            </ButtonRemove>
                                        </ActionRows>
                                    </tr>
                                )
                                )
                            }
                        </tbody>
                    </table>

                    <AddItem
                        text={'ADICIONAR CNPJ PARA CONSULTA'}
                        disabled={!(removeMask(this.state.cnpj).length >= 14)}
                        onClick={this.addCnpj} />
                    <ActionButtons>
                        <Button text={'Cancelar'}
                            enable={true}
                            shadow={true}
                            color={Colors.colorRed}
                            onClick={(e) => {
                                e.preventDefault()
                                this.props.history.goBack();
                            }} />
                        <Button text={'Salvar dados'}
                            type='submit'
                            enable={true}
                            shadow={true}
                        />
                    </ActionButtons>
                </Container>
                <ModalLoading show={this.state.show} />
                <Alert
                    show={this.state.alertConfig.show}
                    message={this.state.alertConfig.message}
                    negativeButtonClick={() => { this.setState({ alertConfig: { show: false, message: '' } }) }}
                    negativeButtonText={"Fechar"}
                />
            </>
        )
    };
}

RegisterUser.defaultProps = {
    title: 'Cadastrar usuário',
    btnSubmit: 'Salvar dados'
}

export default RegisterUser;