import React from 'react'

import { Container } from './style'

import { onlynumber } from '../../Util/Mask'

function InputFilterYear({value,onChange}){
    return (
        <Container>
            <input value={value} onChange={({ target: { value } }) => onChange(value)}  maxLength={4} onKeyPress={onlynumber} />
            <p>Filtrar por ano</p>
        </Container>
    )
}

export default InputFilterYear;