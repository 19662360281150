import styled from 'styled-components';

import Colors from '../../Util/Colors';
import Devices from '../../Util/Devices';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
    justify-content: center;
    align-items: center;
    width: 100%;   
    margin-top: 60px;

    

`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    
    ul{        
        list-style-type: disc;
        margin: auto auto;
        margin-bottom: 48px;
    }

    ul li{
        display: inline-block;
    }

    ul li:focus,ul li a:focus {
        outline: none;
    }

    ul li:first-child {
        margin-left:0px;
    }   

    ul li {
        margin: 0px 5px;
        font-size: 11px;
        font-weight: 900;
        color: ${Colors.colorGray};
        outline: none;
    }

    ul li a:hover{
        cursor: pointer;
    }

    .disabled,
    .disabled:hover,
    .disabled a:hover,
    .disabled a img:hover
    {
        cursor: not-allowed;
    }

    ul li:focus {
        outline: none;
    }

    .active{
        color: ${Colors.primaryColor};
    }

    ul li:last-child{
        margin-right:0px;
    }
    
`;

export const Table = styled.table`
border-collapse: separate;
    border-spacing: 0 5px;
    width:100%;    
    font-size: 11px;
    
    thead th{
      font-weight: 300;
      border-right: 1px solid #ccc;
      background: ${Colors.primaryColor};
    }

    tbody th{
      font-weight: 300;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
    }

    thead th img {    
        float: right;
        margin-right: 11px;         
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; 
    }
  `;

export const RowHeader = styled.tr`
    height: 44px;
    color:white;
    font-weight: 300;
`;

export const FisthItem = styled.th`
   border-radius: 9px 0px 0px 9px;
    border-left: 1px solid #ccc;
    font-weight: 300; 
`;

export const Item = styled.th`
   border-color:#ccc;
  border-style: solid;
  border-width:  0px 1px 0px 0px;
  font-weight: 300;
`;

export const LastItem = styled.th`
    border-radius: 0px 9px 9px 0px;
`;

export const RowBody = styled.tr`
    background: white;
    height: 44px;
    color: ${props => {
        if (props.isHead) return 'white';
        else if (props.status === '10' || props.status === '20') return Colors.primaryColor
        else return '#101010';
    }};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;