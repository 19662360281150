import { createActions, createReducer } from 'reduxsauce';

const INITAL_STATE = {
    visible: false
}

export const { Types, Creators } = createActions({
    setVisibleAlert: ['visible'],
})


const setVisibleAlert = (state = INITAL_STATE, action) =>
    ({ ...state, visible: action.visible });

export default createReducer(INITAL_STATE, {
    [Types.SET_VISIBLE_ALERT]: setVisibleAlert,
});
