import styled from 'styled-components';

import Devices from '../../Util/Devices';
import Colors from '../../Util/Colors';

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    flex:1;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    flex:1;
    width: 70%;    
    margin-top: 60px;
    margin-bottom: 30px;

    @media ${Devices.mobileS}{
        flex-direction:column;
        width: 80%;

    }

    @media ${Devices.mobileM}{
        flex-direction: column;
        width: 90%;
        margin-top: 20px;
    }

    @media ${Devices.laptop}{
        flex-direction:row;
        justify-content: center;
    }

`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    height: 44px;
    margin-bottom: 3px;
`;
export const Column = styled.div`
    display: flex;
    flex-direction: column;
    /* padding-left: 23.5px; */
    /* padding-right: 23.5px; */
`;

export const ButtonCharge = styled.div`
    display: flex;
    flex:1;
    flex-direction:row;
    background: ${props => props.disabled ? '#f2f2f2' : 'white'};
    width: 303px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #ccc;
    border-left: 0px;
    margin-right: 15px;
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40px;
        background: ${props => props.status ? Colors.primaryColor : Colors.colorRed};
        border-radius: 20px 0 0 20px;
        text-align: center;
        color: #fff;
    }

  
  
`;

export const Text = styled.p`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
    height: 100%;
    padding-left: 10px;
`;