import React, { useState, useEffect } from 'react';

import { BoxBackground, BoxWhite, Table, HOrder, HLabel, HQtd, TR_Row, Order, Label, Qtd, Header, Close, Row } from './style';
import { bindActionCreators } from 'redux';

import { Creators as modalActionsError } from '../../store/ducks/ModalItemsError';
import { connect } from 'react-redux';

import img_close from '../../assets/close-green.svg'
import { Container } from '../Alert/styles';
const ModalItensError = ({ setVisibleError, modalVisibleError, initialList }) => {

    const [list, setList] = useState()

    useEffect(() => {
        let newList = [...initialList];

        setList(newList);

    }, [modalVisibleError]);

    const close = () => {
        initialList = [];
        setVisibleError(false);
    }

    if (modalVisibleError)
        return (
            <BoxBackground onClick={() => close()}>
                <BoxWhite onClick={e => {
                    e.stopPropagation();
                }}>
                    <Header>
                        <Close onClick={() => close() }>
                            <img src={img_close} />
                        </Close>
                    </Header>
                    
                    <Row> 
                        <p style={{ color: 'black', fontSize: 15 , fontWeight: 'bold'}}>
                            A quantidade dos itens a seguir são maiores do que o permitido:
                        </p>
                    </Row>
                    <Table >
                        <thead>
                            <tr>
                                <HOrder>PEDIDO</HOrder>
                                <HLabel>ITEM</HLabel>
                                <HLabel>DESCRIÇÃO</HLabel>
                                <HLabel>UNIDADE DE VENDA</HLabel>
                                <HQtd>QTD.</HQtd>
                            </tr>
                        </thead>
                        <tbody>
                            {   
                                list.map((item, index) => {
                                    return (
                                        <TR_Row>
                                            <Order>{item.OrdemVenda || item.Vbeln}</Order>
                                            <Label>{item.OrdemItem || item.Posnr}</Label>
                                            <Label>{item.OrdemDesc || item.Arktx}</Label>
                                            <Label>{item.EmbCarga || item.Vrkme}</Label>
                                            <Qtd>{item.Mengsum}</Qtd>
                                        </TR_Row>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </Table>
                </BoxWhite>
            </BoxBackground>
        );
    else return null;
};

const mapStateToProps = state => ({
    modalVisibleError: state.ModalItemsError.visibleError
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators(modalActionsError, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalItensError);