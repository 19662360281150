import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Buttons, Row, Box, RowBox, Container } from './style';

import hamburger from '../../assets/hamburger.svg'
import manifest_attach_icon from '../../assets/manifest_attach_icon.svg'
import { getLocalStorage } from '../../Util/Functions';
import { TypeUser } from '../../Util/TypeUser';

const DEFAULT_OPTIONS = [{
    name: '',
    icon: '',
    condition: true
}]

const MenuHamburger = ({ options = DEFAULT_OPTIONS, action = function (position) { }, onClickManifest}) => {

    const [openOptions, setOpenOptions] = useState(false)
    const [access, setAccess] = useState('')

    useState(() => {
        getAccess()
    }, [])

    async function getAccess() {
        const access = await getLocalStorage('ACCESS')
        setAccess(access)
    }

    window.onmousedown = (e) => {
        const block = e.target.id.toLowerCase().indexOf("item-box") != -1 || false;
        if (openOptions && !block) setOpenOptions(false);
    }

    const onClick = (position, item) => {
        action(position, item)
        setOpenOptions(false)
    }

    return (

        <Container>
            <Row >
                <Buttons onClick={() => setOpenOptions(!openOptions)} id="menu">
                    <div>
                        <img src={hamburger} />
                    </div>
                </Buttons>

            </Row>
            <Box open={openOptions}>
                {
                    options[0].condition ? (
                        <RowBox onClick={() => onClick(0, options[0])}>
                            <img src={options[0].icon} id={`item-box-01`} />
                            <p id={`item-box-01`}>{options[0].name}</p>
                        </RowBox>
                    ) : (null)
                }
                {

                    options[1].condition ? (

                        <RowBox onClick={() => onClick(1, options[1])}>

                            <img src={options[1].icon} id={`item-box-02`} />

                            <p id={`item-box-02`}>{options[1].name}</p>

                        </RowBox>

                    ) : (null)

                }

                {

                    options[2].condition ? (

                        <RowBox onClick={() => onClick(2, options[2])}>

                            <img src={options[2].icon} id={`item-box-03`} />

                            <p id={`item-box-03`}>{options[2].name}</p>

                        </RowBox>

                    ) : (null)

                }

                {

                    options[3].condition ? (

                        <RowBox onClick={() => onClick(3, options[3])}>

                            <img src={options[3].icon} id={`item-box-04`} />

                            <p id={`item-box-04`}>{options[3].name}</p>

                        </RowBox>

                    ) : (null)

                }

                {

                    options[4].condition ? (

                        <RowBox onClick={() => onClick(4, options[4])}>

                            <img src={options[4].icon} id={`item-box-05`} />

                            <p id={`item-box-05`}>{options[4].name}</p>

                        </RowBox>

                    ) : (null)

                }

                {

                    options[5].condition ? (

                        <RowBox onClick={() => onClick(5, options[5])}>

                            <img src={options[5].icon} id={`item-box-06`} />

                            <p id={`item-box-06`}>{options[5].name}</p>

                        </RowBox>

                    ) : (null)

                }

                {

                    options[6].condition ? (

                        <RowBox onClick={() => onClick(6, options[6])}>

                            <img src={options[6].icon} id={`item-box-08`} />

                            <p id={`item-box-08`}>{options[6].name}</p>

                        </RowBox>

                    ) : (null)

                }

                {

                    options[7].condition ? (

                        <RowBox onClick={() => onClick(7, options[7])}>

                            <img src={options[7].icon} id={`item-box-09`} />

                            <p id={`item-box-09`}>{options[7].name}</p>

                        </RowBox>

                    ) : (null)

                }

                {
                    access === TypeUser.shippingCompany ? (

                        <RowBox onClick={()=>{
                            setOpenOptions(false)
                            onClickManifest()
                        }}>

                            <img src={manifest_attach_icon} id={`item-box-07`} />

                            <p id={`item-box-06`}>Anexar manifesto</p>

                        </RowBox>

                    ) : (null)
                }
            </Box>
        </Container>
    )
}

// MenuHamburger.defaultProps = {
// }


// MenuHamburger.propTypes = {
//     onClickManifest: PropTypes.func,
// }


export default MenuHamburger;
