import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Components
import Calendar from "../../components/Calendar";
import Alert from "../../components/Alert";
import LoadingComponent from "../../components/LoadingComponent";
import InputFilter from "../../components/InputFilter";
import InputFilterYear from "../../components/InputFilterYear";

//Redux
import { Creators as ActionsStatusBar } from "../../store/ducks/TopStatusBar";

import {
    Container,
    Content,
    RowBody,
    Table,
    FisthItem,
    LastItem,
    Item,
    RowHeader,
    CenterDropdownDiv,
} from "./styles";

import ModalLoading from "../../components/ModalLoading";
import MenuHamburger from "../../components/MenuHamburger";
import ReactPaginate from "react-paginate";

import editIcon from "../../assets/edit_icon.svg";
import nfIcon from "../../assets/document_icon.svg";
import qualityIcon from "../../assets/copy_icon.svg";
import car from "../../assets/car.svg";
import book from "../../assets/book.svg";
import print from "../../assets/print.svg";

import arrowFilter from "../../assets/arrow_filter.svg";
import arrowSetPage from "../../assets/arrow_set_page.svg";
import arrowSetNextPage from "../../assets/arrow_set_next_page.svg";

//Utils
import {
    checkSessionUser,
    getResponseError,
    getLocalStorage,
    formateDate,
    getIdWithZero,
} from "../../Util/Functions";
import { onlynumber } from "../../Util/Mask";
import { getContentType } from "../../Util/Base64";

//Service
import Api from "../../server/";
import PDFView from "../../components/PDFView";
import {
    Title,
    Row,
    DaysOfWeek,
    Column,
} from "../../components/Calendar/styles";
import AlertAttachment from "../../components/AlertAttachment";
import { dateMask } from "../../Util/Mask";

import {
    TypeUser,
    getUserPortal,
    getTypeUserPortal,
} from "../../Util/TypeUser";
import { CenterDropdown } from "./components/CenterDropdown";

const INTIAL_ALERT_CONFIG = { show: false, message: "" };
const INTIAL_ALERT_ATTACHMENT_CONFIG = { show: false, type: "" };
const INITIAL_STATE_SATUS = { Agendamentos: { item: [] }, Paginacao: {} };
const CONCAT_BASE64 = "data:application/pdf;base64,";

class Availability extends React.Component {
    /* TODO: Criar uma variável do tipo array que armazenará os centros recuperados do serviço */

    state = {
        calendarConfig: {},
        alertConfig: INTIAL_ALERT_CONFIG,
        alertAttachment: INTIAL_ALERT_ATTACHMENT_CONFIG,
        books: { item: [] },
        status: INITIAL_STATE_SATUS,
        show: false,
        isAscending: true,
        dataPdf: {},
        loading: false,
        loadingStatusGrid: true,
        loadingStatusGridError: false,
        loadingCalendarGrid: true,
        loadingCalendarGridError: false,
        IdAgendamento: "",
        Placa: "",
        DataAgendamento: "",
        statusAgendamento: "00",
        Ano: "",
        access: "",

        // Variáveis referentes ao filtro por centro
        selectedValue: "",
        centers: { item: [] },
        loadingCenter: true,
        loadingCenterError: false,

        // Filtro de centro
        center: "",

        // Variáveis que guardam ano e mês que o calendário está no momento
        currentCalendarYear: `${new Date().getFullYear()}`,
        currentCalendarMonth: new Date().getMonth() + 1,
    };

    componentDidMount() {
        if (!checkSessionUser()) {
            this.props.history.replace("/");
        }
        this.setIconsPages();
        const today = new Date();
        this.setState({ Ano: today.getFullYear() });
        this.checkUser();
    }

    // Método responsável por checar usuário e chamar o serviço de centros
    async checkUser(today) {
        const access = await getLocalStorage("ACCESS");
        this.setState({ access, loadingCalendarGrid: false });

        const center = localStorage.getItem("CENTER");
        if (center != null && center.length > 0) {
            localStorage.setItem("CENTER", "");
        }
        this.callServiceCenters();
    }

    showAlert = (message) => {
        this.setState({ alertConfig: { show: true, message } });
    };

    checkBookSelected = (day) => {
        let index = 0;
        this.state.books.item.forEach((item, i) => {
            if (day == item.Data.substring(item.Data.length - 2)) {
                index = i;
                return true;
            }
        });
        return index;
    };

    handlerSelectDay = (e, day) => {
        try {
            let item = this.state.books.item[this.checkBookSelected(day)]
                ? this.state.books.item[this.checkBookSelected(day)]
                : { Posicoes: { item: [] } };
            localStorage.removeItem("DATE_SELECTED");
            localStorage.setItem("DATE_SELECTED", item.Data);

            this.props.history.push(`/book_charge?day=${day}`, {
                item,
            });
        } catch (e) {
            this.showAlert(
                "Ocorreu um erro desconhecido, para mais detalhes técnicos: " +
                    e.message
            );
        }
    };

    callServiceGrid = async (exercicio, mes) => {
        //Consumindo serviço de consulta ao SAP
        try {
            this.setState({ loadingCalendarGrid: true });
            this.setState({ loadingCalendarGridError: false });
            const userPortal = await getUserPortal();

            const center = localStorage.getItem("CENTER");

            const {
                data: {
                    result: { Agendamentos },
                },
            } = await Api.post(
                "users/GetScheduleGrid",
                { exercicio, mes, userPortal, centro: center },
                {
                    headers: {
                        Authorization:
                            "Bearer " + (await getLocalStorage("TOKEN")),
                    },
                }
            );

            this.setState({
                books: Agendamentos ? Agendamentos : { item: [] },
            });
            this.setState({ loadingCalendarGrid: false });
        } catch (e) {
            this.showAlert(getResponseError(e));
            this.setState({ loadingCalendarGridError: true });
        }
    };

    callServiceStatus = async (page = 1, needLoading = false) => {
        try {
            if (needLoading) {
                this.setState({ loading: true });
            }
            this.setState({ loadingStatusGridError: false });

            const userPortal = await getUserPortal();
            const typeUserPortal = await getTypeUserPortal(this.state.access);
            const { cnpjs_consult } = JSON.parse(
                await getLocalStorage("LOGIN")
            );

            const {
                center,
                IdAgendamento,
                Placa,
                DataAgendamento,
                statusAgendamento,
                Ano,
            } = this.state;

            let dateFomatted = DataAgendamento;

            if (dateFomatted.length >= 4) {
                console.log(dateFomatted.length);
                if (dateFomatted.length < 6) {
                    dateFomatted += `/${Ano}`;
                }
                let splitDate = dateFomatted.split("/");
                dateFomatted = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
            }

            const {
                data: {
                    result: { Status },
                },
            } = await Api.post(
                "users/GetScheduleStatus",
                {
                    page,
                    userPortal: userPortal,
                    typeUserPortal: typeUserPortal,
                    othersCnpj: cnpjs_consult,
                    IdAgendamento,
                    Placa,
                    DataAgendamento: dateFomatted,
                    Status: statusAgendamento,
                    Ano,
                    Centro: center,
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + (await getLocalStorage("TOKEN")),
                    },
                }
            );

            let status = {
                Paginacao: Status.Paginacao,
                Agendamentos: {
                    item: await this.organizeGrid(
                        Status.Agendamentos ? Status.Agendamentos.item : []
                    ),
                },
            };

            this.setState({ status });
            this.setState({ loadingStatusGrid: false });
            this.setState({ loading: false });
        } catch (e) {
            this.setState({ loadingStatusGridError: true });
            this.showAlert(getResponseError(e));
            this.setState({ show: false });
        }
    };

    callServiceAttachment = async (AttachType, IdAgendamento) => {
        let body = {
            AttachType,
            IdAgendamento,
        };

        try {
            this.setState({
                show: true,
            });
            const {
                data: {
                    result: { Attachments },
                },
            } = await Api.post("/users/GetSchedulingAttachment", body, {
                headers: {
                    Authorization: "Bearer " + (await getLocalStorage("TOKEN")),
                },
            });

            this.setState({ show: false });
            return {
                error: false,
                attachments: Attachments,
            };
        } catch (e) {
            this.setState({ show: false });
            this.showAlert(getResponseError(e));
            return {
                error: true,
                data: getResponseError(e),
            };
        }
    };

    getTextStatus = (status) => {
        switch (status) {
            case "10":
                return "Agendado";
            case "20":
                return "Aguardando veículo";
            case "30":
                return "Aguardando carregamento";
            case "40":
                return "Carregamento";
            case "50":
                return "Aguardando NF";
            case "60":
                return "Liberado";
            case "70":
                return "Cancelado";
            case "80":
                return "Aguardando manifesto transportador";
            case "90":
                return "Manifesto emitido";
        }
    };

    rotateArrowFilterStatus = (first = false) => {
        var arrow = document.getElementById("arrowFilter");
        let deg = this.state.isAscending ? 0 : -180;
        arrow.style.webkitTransform = "rotate(" + deg + "deg)";
        arrow.style.mozTransform = "rotate(" + deg + "deg)";
        arrow.style.msTransform = "rotate(" + deg + "deg)";
        arrow.style.oTransform = "rotate(" + deg + "deg)";
        arrow.style.transform = "rotate(" + deg + "deg)";
    };

    handlePageClick = (data) => {
        let selected = data.selected;
        this.callServiceStatus(selected + 1);
    };

    setIconsPages = () => {
        //Adicionando icones de next e previous ao componente ReactPaginate
        let imgPrevious = document.createElement("img");
        imgPrevious.setAttribute("src", arrowSetPage);
        let previous =
            document.getElementsByClassName("previous")[0].firstChild;
        previous.appendChild(imgPrevious);

        let imgNext = document.createElement("img");
        imgNext.setAttribute("src", arrowSetNextPage);
        let next = document.getElementsByClassName("next")[0].firstChild;
        next.appendChild(imgNext);
    };

    organizeGrid = async (item) => {
        return this.state.isAscending
            ? await item.sort((a, b) => a.Status - b.Status)
            : await item.sort((a, b) => b.Status - a.Status);
    };

    handlerFilter = async () => {
        await this.setState(
            { isAscending: !this.state.isAscending },
            async () => {
                this.rotateArrowFilterStatus(false);

                const {
                    Agendamentos: { item },
                    Paginacao,
                } = this.state.status;
                const currentItem = item;

                this.setState({
                    status: {
                        Paginacao,
                        Agendamentos: {
                            item: await this.organizeGrid(currentItem),
                        },
                    },
                });
            }
        );
    };

    goEditBook = (book) => {
        try {
            this.props.history.push(
                `/transporter_data?edit=${book.DataAgendamento}`,
                {
                    dataBook: { idAgendamento: book.IdAgendamento },
                    status: book.Status,
                    type: "Edit",
                }
            );
        } catch (e) {
            this.showAlert(
                "Ocorreu um erro desconhecido, para mais detalhes técnicos: " +
                    e.message
            );
        }
    };

    downloadFile = async (type, idAgendamento, name) => {
        const attachFile = await this.callServiceAttachment(
            type,
            idAgendamento
        );

        let contentType = getContentType(attachFile.extension);

        if (!window.ActiveXObject) {
            var link = document.createElement("a");
            link.target = "_blank";
            link.href = `data:${contentType};base64,${attachFile.data}`;
            link.download = `${name}(${idAgendamento})`;

            if (
                navigator.userAgent
                    .toLowerCase()
                    .match(/(ipad|iphone|safari)/) &&
                navigator.userAgent.search("Chrome") < 0
            ) {
                document.location = link.href;
            } else {
                var evt = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                });
                link.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(link.href);
            }
        } else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(
                `data:${contentType};base64,${attachFile.data}`,
                "_blank"
            );
            _window.document.close();
            _window.document.execCommand(
                "SaveAs",
                true,
                `data:${contentType};base64,${attachFile.data}`
            );
            _window.close();
        }
    };

    renderPdf = async (type, idAgendamento, name) => {
        try {
            const response = await this.callServiceAttachment(
                type,
                idAgendamento
            );

            let attachments = response.attachments.item;

            for (let d = 0; d < attachments.length; d++) {
                let attachment = attachments[d];

                let contentType = getContentType(attachment.AttachExtension);

                const linkSource = `data:${contentType};base64,${attachment.AttachContent}`;
                const download = document.createElement("a");
                const fileName = `${name} ${d} (${idAgendamento})`;

                download.href = linkSource;
                download.download = fileName;
                document.body.appendChild(download);
                download.click();
                document.body.removeChild(download);

                //                if(attachment.AttachType === 'pdf'){
                //
                //                    let objbuilder = '';
                //                    objbuilder += (`<object width="100%" height="100%" data="data:${contentType};base64,`);
                //                    objbuilder += (`${attachment.AttachContent} "`);
                //                    objbuilder += (`type="${contentType}" class="internal">`);
                //                    objbuilder += (`<embed src="data:${contentType};base64,`);
                //                    objbuilder += (`${attachment.AttachContent} "`);
                //                    objbuilder += (`type="${contentType}" />`);
                //                    objbuilder += ('</object>');

                //                    let win = window.open("", "_blank", "titlebar=yes", "fullscreen=no");
                //                   win.document.write('<html><body>');
                //                    win.document.write(objbuilder);
                //                    win.document.write('</body></html>');
                //
                //                }
            }
        } catch (e) {
            console.error(e);
            this.showAlert(
                "Ocorreu um erro não esperado, para mais detalhes técnicos: " +
                    e.message
            );
        }
    };

    getOptionsMenu = (item) => {
        let options = [
            {
                name: "Editar",
                icon: editIcon,
                condition:
                    (item.Status === "20" || item.Status === "10") &&
                    this.state.access !== TypeUser.shippingCompany,
                type: 0,
            },
            {
                name: "Anexo Nota Fiscal",
                icon: nfIcon,
                condition: item.AnexoNf,
                type: 3,
            },
            {
                name: "Anexo Qualidade",
                icon: qualityIcon,
                condition: item.AnexoQualidade,
                type: 4,
            },
            {
                name: "Anexo Mapa de Carga",
                icon: car,
                condition: item.AnexoMapaCarga,
                type: 5,
            },
            {
                name: "Anexo Guia de Fase",
                icon: book,
                condition: item.AnexoGuiaFase,
                type: 6,
            },
            {
                name: "Anexo Fotos",
                icon: book,
                condition: item.AnexoFotos,
                type: 8,
            },
            {
                name: "Outros Anexos",
                icon: book,
                condition: item.AnexoOutros,
                type: 99,
            },
            {
                name: "Imprimir Agendamento",
                icon: print,
                condition: true,
                type: 0,
            },
        ];
        return options;
    };

    print = async ({ IdAgendamento, DataAgendamento }) => {
        this.setState({ show: true });
        try {
            const response = await Api.post(
                "users/GetSchedulingById",
                { idAgendamento: getIdWithZero(IdAgendamento) },
                {
                    headers: {
                        Authorization:
                            "Bearer " + (await getLocalStorage("TOKEN")),
                    },
                }
            );

            let {
                data: { result },
            } = response;

            const responseNameCreateCharge = await Api.post(
                "users/getNameCreateCharge",
                { cpf_cnpj: result.UserCrtAgendamento },
                {
                    headers: {
                        Authorization:
                            "Bearer " + (await getLocalStorage("TOKEN")),
                    },
                }
            );

            result.UserCrtAgendamento = responseNameCreateCharge.data.name;
            result.DataCrtAgendamento = formateDate(result.DataCrtAgendamento);
            result.DadosAgendamCarga = result.DadosAgendamCarga.item;

            result = {
                ...result,
                IdAgendamento,
                DataAgendamento: formateDate(DataAgendamento),
            };
            this.setState({ dataPdf: result }, async () => {
                const element = document.getElementById("pdf");
                const html2pdf = require("html2pdf.js");
                var opt = {
                    margin: 0.8,
                    filename: IdAgendamento,
                    html2canvas: { scale: 1 },
                    jsPDF: {
                        unit: "in",
                        format: "letter",
                        orientation: "portrait",
                    },
                };
                // New Promise-based usage:
                element.style.display = "block";
                await html2pdf()
                    .from(element)
                    .set(opt)
                    .toPdf()
                    .get("pdf")
                    .then(function (pdf) {
                        window.open(
                            pdf.output("bloburl"),
                            "_blank",
                            "titlebar=yes",
                            "fullscreen=no"
                        );
                        element.style.display = "none";
                    });
                this.setState({ show: false });
            });
        } catch (e) {
            this.showAlert(getResponseError(e));
            this.setState({ show: false });
        }
    };

    callServiceSetAttachment = async (file, ext, type, IdAgendamento) => {
        let body = {
            AttachContent: file,
            AttachExtension: ext,
            AttachType: type,
            IdAgendamento,
        };

        try {
            this.setState({
                show: true,
            });
            const {
                data: {
                    result: {
                        Return: { Message, Type },
                    },
                },
            } = await Api.post("/users/SetSchedulingAttachment", body, {
                headers: {
                    Authorization: "Bearer " + (await getLocalStorage("TOKEN")),
                },
            });

            this.setState({ show: false });
            this.showAlert(Message);

            this.callServiceStatus();
        } catch (e) {
            this.setState({ show: false });
            this.showAlert(getResponseError(e));
        }
    };

    onChangeIdAgendamento = (value) => {
        this.setState({ IdAgendamento: value }, () => {
            this.callServiceStatus();
        });
    };

    onChangePlaca = (value) => {
        this.setState({ Placa: value }, () => {
            this.callServiceStatus();
        });
    };

    onChangeData = (value) => {
        const date = dateMask(value);
        this.setState({ DataAgendamento: date }, () => {
            if (
                this.state.DataAgendamento.length > 4 ||
                this.state.DataAgendamento.length == 0
            )
                this.callServiceStatus();
        });
    };

    onChangeAno = (value) => {
        this.setState({ Ano: value }, () => {
            if (this.state.Ano.length == 4) this.callServiceStatus();
        });
    };

    onChangeStatusAgendamento = (value) => {
        this.setState({ statusAgendamento: value }, () => {
            this.callServiceStatus();
        });
    };

    // Método resopnsável por gerenciar estado do filtro de centro
    onChangeCenter = (value) => {
        this.setState({ center: value.toUpperCase() }, () => {
            this.callServiceStatus();
        });
    };

    // método responsável por chamar o serviço de consultar centros e preencher uma lista de opções com os resultados
    callServiceCenters = async () => {
        this.setState({ loadingCenter: true });
        this.setState({ loadingCenterError: false });

        const userPortal = await getUserPortal();

        let cpf = "";
        let cnpj = "";
        const { cnpjs_consult } = JSON.parse(await getLocalStorage("LOGIN"));

        if (userPortal.length > 11) {
            cnpj = userPortal;
        } else {
            cpf = userPortal;
        }

        try {
            const {
                data: {
                    result: { BoardingLocations },
                },
            } = await Api.post(
                "users/GetBoardingLocations",
                {
                    cnpj,
                    cpf,
                    othersCnpj: cnpjs_consult,
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + (await getLocalStorage("TOKEN")),
                    },
                }
            );

            this.setState({
                centers: BoardingLocations
                    ? BoardingLocations
                    : {
                          item: [
                              {
                                  Codigo: "",
                                  Descricao:
                                      "Você não possui saldo em ordens de vendas para agendamentos",
                              },
                          ],
                      },
            });

            this.setState({ loadingCenter: false });
        } catch (e) {
            console.log("Erro: " + e);
            this.showAlert(getResponseError(e));
            this.setState({ loadingCalendarGridError: true });
        }
    };

    // Método responsável por lidar com a escolha do centro no dropdown e chamar o serviço a partir do centro selecionado
    handleSelectCenter = (value) => {
        this.setState({ selectedValue: value }, async () => {
            localStorage.setItem("CENTER", this.state.selectedValue.Codigo);

            if (this.state.selectedValue.Codigo !== "") {
                if (
                    localStorage.getItem("CENTER") != null &&
                    (localStorage.getItem("CENTER").length < 4 ||
                        localStorage.getItem("CENTER") === "null" ||
                        localStorage.getItem("CENTER") === null)
                ) {
                    this.showAlert(
                        "Houve um erro na seleção do centro, por favor, tente selecionar novamente"
                    );
                } else {
                    const today = new Date();

                    const access = await getLocalStorage("ACCESS");

                    if (this.state.selectedValue.Codigo != null) {
                        this.callServiceStatus(1, true);

                        if (access !== TypeUser.shippingCompany) {
                            this.callServiceGrid(
                                this.state.currentCalendarYear,
                                this.state.currentCalendarMonth
                            );
                        }
                    } else {
                        this.setState({ books: { item: [] } });
                    }
                }
            } else {
                return;
            }
        });
    };

    // Filtra os agendamentos existes pelo centro

    render() {
        const MONTH = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        return (
            <Container>
                <Alert
                    show={this.state.alertConfig.show}
                    message={this.state.alertConfig.message}
                    negativeButtonText="Fechar"
                    negativeButtonClick={() =>
                        this.setState({ alertConfig: INTIAL_ALERT_CONFIG })
                    }
                />

                <AlertAttachment
                    show={this.state.alertAttachment.show}
                    type={this.state.alertAttachment.type}
                    IdAgendamento={this.state.alertAttachment.idAgendamento}
                    onSend={this.callServiceSetAttachment}
                    onError={(message) => {
                        this.setState({ alertConfig: { show: true, message } });
                    }}
                    onClose={() => {
                        this.setState({
                            alertAttachment: INTIAL_ALERT_ATTACHMENT_CONFIG,
                        });
                    }}
                />

                <Content>
                    {this.state.access !== TypeUser.shippingCompany ? (
                        this.state.loadingCenter ? (
                            <LoadingComponent
                                error={this.state.loadingCenterError}
                                onClick={() => this.callServiceCenters()}
                            />
                        ) : (
                            <CenterDropdownDiv>
                                <span>CENTRO</span>
                                <CenterDropdown
                                    placeholder="SELECIONE O CENTRO"
                                    options={this.state.centers}
                                    selectedValue={this.state.selectedValue}
                                    handleSelectCenter={this.handleSelectCenter}
                                />
                            </CenterDropdownDiv>
                        )
                    ) : null}

                    {this.state.loadingStatusGrid ? (
                        <>
                            <Table isEnabled={true}>
                                <thead>
                                    <RowHeader>
                                        <FisthItem>Centro</FisthItem>
                                        <Item>ID do Agendamento </Item>
                                        <Item>Placa</Item>
                                        <Item>Data de Agendamento</Item>
                                        <LastItem onClick={this.handlerFilter}>
                                            Status{" "}
                                            <img
                                                id="arrowFilter"
                                                src={arrowFilter}
                                            />
                                        </LastItem>
                                    </RowHeader>
                                </thead>
                            </Table>
                            <br />
                            <LoadingComponent
                                error={this.state.loadingStatusGridError}
                                onClick={() => this.callServiceStatus(1)}
                            />
                        </>
                    ) : (
                        <>
                            <Table isEnabled={true}>
                                <thead>
                                    <RowHeader>
                                        <FisthItem>Centro</FisthItem>
                                        <Item>ID do Agendamento </Item>
                                        <Item>Placa</Item>
                                        <Item>Data de Agendamento</Item>
                                        <LastItem onClick={this.handlerFilter}>
                                            Status{" "}
                                            <img
                                                id="arrowFilter"
                                                src={arrowFilter}
                                            />
                                        </LastItem>
                                    </RowHeader>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <InputFilter
                                                placeholder={"Filtro Centro"}
                                                maxLength={4}
                                                position={"first"}
                                                value={this.state.center}
                                                onChange={this.onChangeCenter}
                                                onClear={() =>
                                                    this.onChangeCenter("")
                                                }
                                            />
                                        </td>
                                        <td>
                                            <InputFilter
                                                placeholder={
                                                    "Filtro Id de Agendamento"
                                                }
                                                maxLength={10}
                                                value={this.state.IdAgendamento}
                                                onChange={
                                                    this.onChangeIdAgendamento
                                                }
                                                onClear={() =>
                                                    this.onChangeIdAgendamento(
                                                        ""
                                                    )
                                                }
                                                onKeyPress={onlynumber}
                                            />
                                        </td>
                                        <td>
                                            <InputFilter
                                                placeholder={"Filtro Placa"}
                                                maxLength={8}
                                                value={this.state.Placa}
                                                onChange={this.onChangePlaca}
                                                onClear={() =>
                                                    this.onChangePlaca("")
                                                }
                                            />
                                        </td>
                                        <td>
                                            <InputFilter
                                                placeholder={
                                                    "Filtro data de agendamento"
                                                }
                                                maxLength={10}
                                                value={
                                                    this.state.DataAgendamento
                                                }
                                                onChange={this.onChangeData}
                                                onClear={() =>
                                                    this.onChangeData("")
                                                }
                                            />
                                        </td>
                                        <td>
                                            <InputFilter
                                                placeholder={"Filtro Status"}
                                                position={"last"}
                                                maxLength={23}
                                                options={[
                                                    {
                                                        value: "00",
                                                        name: "Todos",
                                                    },
                                                    {
                                                        value: "10",
                                                        name: "Agendado",
                                                    },
                                                    {
                                                        value: "20",
                                                        name: "Aguardando veículo",
                                                    },
                                                    {
                                                        value: "30",
                                                        name: "Aguardando carregamento",
                                                    },
                                                    {
                                                        value: "40",
                                                        name: "Carregamento",
                                                    },
                                                    {
                                                        value: "50",
                                                        name: "Aguardando NF",
                                                    },
                                                    {
                                                        value: "60",
                                                        name: "Liberado",
                                                    },
                                                    {
                                                        value: "70",
                                                        name: "Cancelado",
                                                    },
                                                    {
                                                        value: "80",
                                                        name: "Aguardando manifesto transportador",
                                                    },
                                                    {
                                                        value: "90", 
                                                        name: "Manifesto emitido"
                                                    }
                                                ]}
                                                value={
                                                    this.state.statusAgendamento
                                                }
                                                onChange={
                                                    this
                                                        .onChangeStatusAgendamento
                                                }
                                            />
                                        </td>
                                    </tr>

                                    {this.state.status.Agendamentos.item.map(
                                        (item, index) => (
                                            <RowBody
                                                status={item.Status}
                                                key={item.IdAgendamento}
                                            >
                                                <FisthItem>
                                                    {item.Centro || "Definir"}
                                                </FisthItem>
                                                <Item>
                                                    {item.IdAgendamento}
                                                </Item>
                                                <Item>{item.Placa}</Item>
                                                <Item>
                                                    {formateDate(
                                                        item.DataAgendamento
                                                    )}
                                                </Item>
                                                <LastItem>
                                                    {this.getTextStatus(
                                                        item.Status
                                                    )}
                                                </LastItem>
                                                <MenuHamburger
                                                    options={this.getOptionsMenu(
                                                        item
                                                    )}
                                                    onClickManifest={() => {
                                                        this.setState({
                                                            alertAttachment: {
                                                                show: true,
                                                                type: "07",
                                                                idAgendamento:
                                                                    item.IdAgendamento,
                                                            },
                                                        });
                                                    }}
                                                    action={(position, i) => {
                                                        switch (position) {
                                                            case 0:
                                                                this.goEditBook(
                                                                    item
                                                                );
                                                                break;
                                                            case 1:
                                                            case 2:
                                                            case 3:
                                                            case 4:
                                                            case 5:
                                                            case 6:
                                                                this.renderPdf(
                                                                    i.type,
                                                                    item.IdAgendamento,
                                                                    i.name
                                                                );
                                                                //this.downloadFile(i.type, item.IdAgendamento, i.name);
                                                                break;
                                                            case 7:
                                                                this.print(
                                                                    item
                                                                );
                                                        }
                                                    }}
                                                />
                                            </RowBody>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </>
                    )}

                    <ReactPaginate
                        previousLabel={""}
                        nextLabel={""}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={
                            this.state.status.Paginacao.TotalPaginas || ""
                        }
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        initialPage={0}
                    />

                    <InputFilterYear
                        value={this.state.Ano}
                        onChange={this.onChangeAno}
                    />

                    {this.state.selectedValue.Codigo != null
                        ? this.state.loadingCalendarGrid && (
                              <>
                                  <Row isTitle isEnabled={true}>
                                      <Title>
                                          {this.state.calendarConfig.month ||
                                              MONTH[new Date().getMonth()]}{" "}
                                          {this.state.calendarConfig.year ||
                                              new Date().getFullYear()}
                                      </Title>
                                  </Row>
                                  <Row>
                                      <DaysOfWeek>
                                          <Column isTitle>DOM</Column>
                                          <Column isTitle>SEG</Column>
                                          <Column isTitle>TER</Column>
                                          <Column isTitle>QUA</Column>
                                          <Column isTitle>QUI</Column>
                                          <Column isTitle>SEX</Column>
                                          <Column isTitle isLast>
                                              SAB
                                          </Column>
                                      </DaysOfWeek>
                                  </Row>
                                  <br />
                                  <br />
                                  <br />
                                  <LoadingComponent
                                      show={this.state.loadingCalendarGrid}
                                      error={
                                          this.state.loadingCalendarGridError
                                      }
                                      onClick={() => {
                                          const today = new Date();
                                          this.callServiceGrid(
                                              today.getFullYear(),
                                              today.getMonth() + 1
                                          );
                                      }}
                                  />
                              </>
                          )
                        : null}

                    {this.state.access !== TypeUser.shippingCompany && (
                        <Calendar
                            visible={!this.state.loadingCalendarGrid}
                            onClickDay={this.handlerSelectDay}
                            isEnabled={
                                localStorage.getItem("CENTER") != null &&
                                localStorage.getItem("CENTER").length === 4 &&
                                localStorage.getItem("CENTER") !== "null" &&
                                localStorage.getItem("CENTER") !== null
                                    ? true
                                    : false
                            }
                            books={this.state.books.item}
                            onChangeCalendar={({
                                numberMonth,
                                month,
                                year,
                            }) => {
                                this.setState({
                                    currentCalendarMonth: numberMonth,
                                });
                                this.setState({ currentCalendarYear: year });

                                this.callServiceGrid(year, numberMonth);

                                this.setState({
                                    calendarConfig: {
                                        numberMonth,
                                        month,
                                        year,
                                    },
                                });
                            }}
                        />
                    )}
                </Content>

                <ModalLoading show={this.state.show} />
                <br />

                <PDFView item={this.state.dataPdf} />
            </Container>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    TopStatusBarActions: bindActionCreators(ActionsStatusBar, dispatch),
});

export default connect(null, mapDispatchToProps)(Availability);
