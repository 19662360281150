import React from 'react';

import { Background, BoxWhite } from './style';

import loading from '../../assets/loading.gif';

const ModalLoading = ({ show }) => (
    <Background show={show}>
        <BoxWhite>
            <img src={loading} />
        </BoxWhite>
    </Background>
);


export default ModalLoading;