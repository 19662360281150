import React from 'react';

import Router from '../../router';
import { Provider } from 'react-redux';
import { store } from '../../store'

import TopStatusBar from '../../components/TopStatusBar';

export default function Main() {
    return (
        <Provider store={store}>
            <TopStatusBar />
            <Router />
        </Provider>
    )
}