import styled from 'styled-components'
import Colors from '../../Util/Colors';
import Devices from '../../Util/Devices';
import { UpItem } from '../../components/ItemListMoutCharge/style';

import { Button } from '../../components/Button/style'

export const Table = styled.table`
    border-collapse: separate;
    border-spacing: 0 5px;
    width: 100%;
    margin-top: 40px;
    max-width: 968px;
    tbody td{
      font-size:11px;
    }

    thead th{
      background: ${Colors.primaryColor};
    }
    
  `;

export const RowHeader = styled.tr`  
  height: 44px;
  color:white;
  font-style: Regular;
`;

export const FisthItem = styled.th`
  border-radius: 9px 0px 0px 9px;
  border-color: white;
  border-style: solid;
  border-width:  0px 1px 0px 0px;
`;

export const Item = styled.th`
  border-color: white;
  border-style: solid;
  border-width:  0px 1px 0px 0px;
`;

export const LastItem = styled.th`
    border-radius: 0px 9px 9px 0px;
`;

export const Container = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  margin:auto auto;
  font-size:10px;
  width: 90%;
  max-width:968px;

  ${Button}{
    margin-bottom: 20px;
    margin-top: 40px;
  }

  ul{        
        list-style-type: disc;
        margin: auto auto;
        margin-bottom: 48px;
    }

    ul li{
        display: inline-block;
    }

    ul li:focus,ul li a:focus {
        outline: none;
    }

    ul li:first-child {
        margin-left:0px;
    }   

    ul li {
        margin: 0px 5px;
        font-size: 11px;
        font-weight: 900;
        color: ${Colors.colorGray};
        outline: none;
    }

    ul li a:hover{
        cursor: pointer;
    }

    .disabled,
    .disabled:hover,
    .disabled a:hover,
    .disabled a img:hover
    {
        cursor: not-allowed;
    }

    ul li:focus {
        outline: none;
    }

    .active{
        color: ${Colors.primaryColor};
    }

    ul li:last-child{
        margin-right:0px;
    }
`;