import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Login from './pages/Login';
import RegisterUser from './pages/RegisterUser';
import AdminUser from './pages/AdminUser';
import Availability from './pages/Availability';
import MountCharge from './pages/MountCharge';
import TransporterData from './pages/TransporterData';
import BookCharge from './pages/BookCharge';
import DashboardData from './pages/DashboardData';


const Router = () => (
    <BrowserRouter >
        <Switch>
            <Route path={"/availability"} component={Availability} />
            <Route path={"/book_charge"} component={BookCharge} />
            <Route path={"/mount_charge"} component={MountCharge} />
            <Route path={"/transporter_data"} component={TransporterData} />
            <Route path={"/register_user"} component={RegisterUser} />
            <Route path={"/user_admin"} component={AdminUser} />
            <Route exact path={"/dashboard"} component={DashboardData} />            
            <Route exact path={"/"} component={Login} />
        </Switch>
    </BrowserRouter>
);

export default Router;