import styled from 'styled-components';
import Colors from '../../Util/Colors';
import Devices from '../../Util/Devices';

export const ButtonBox = styled.button`
    color: white;
    border: none;
    border-radius: 9px;
    background:  ${props=> props.disabled ? Colors.colorGray : Colors.primaryColor };
    padding: 15px 14px;
    &:focus{
        border:none;
        outline: none;
    }
    &:active{
        transform: translateY(1.5px);
    }
    @media ${Devices.mobileS}{
        font-size: 8px;
    }
    @media ${Devices.tablet}{
        font-size: 11px;
    }
    display:flex;
    align-items: center;
    justify-content: center;
    &:hover{
        cursor: pointer;
    }

    &:disabled{
        cursor: not-allowed;
    }
`;

export const IconAdd = styled.img`
    padding-right: 10px;
    @media ${Devices.mobileS}{
        
    }

    @media ${Devices.laptop}{
        
    } 
`; 
