import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as modalActions } from '../../store/ducks/ModalItems';
import { Creators as modalActionsError } from '../../store/ducks/ModalItemsError';
import { Creators as TopStatusbarActions } from '../../store/ducks/TopStatusBar';
import { Creators as alertAddFat } from '../../store/ducks/AlertAddFat';

import AddItem from '../../components/AddItem';
import Button from '../../components/Button';
import ItemList from '../../components/ItemListMoutCharge';
import ModalItens from '../../components/ModalItens';
import ModalItensError from '../../components/ModalItensError';
import Alert from '../../components/Alert';
import AlertAddFat from '../../components/AlertAddFat';
import ModalLoading from '../../components/ModalLoading';

import { removeMask, onlyUTF8 } from '../../Util/Mask';

//Utils
import { checkSessionUser, getLocalStorage } from '../../Util/Functions';

import { Table, FisthItem, LastItem, Item, RowHeader, Container, BoxButtonSave, BoxAddItem } from './style'
import Api from '../../server';
import { getResponseError } from '../../Util/Functions';
import { getUserPortal } from '../../Util/TypeUser';

function MountCharge({ history, item, modalVisible, modalVisibleError, alertAddFatVisible, ModalActions, ModalActionsError, StatusbarActions: { redirect }, AlertAddFatActions }) {
    require('../../components/Redirect')(history, redirect);
    const INTIAL_ALERT_CONFIG = { show: false, message: '', onClick: undefined };

    const [list, setlist] = useState([]);
    const [listErrors, setlistErrors] = useState([]);
    const [itens, setItens] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alertConfig, setAlertConfig] = useState(INTIAL_ALERT_CONFIG);
    const [itemIndex, setItemIndex] = useState();
    const [lastItem, setLastItem] = useState();

    useEffect(() => {
        if (!checkSessionUser()) {
            history.replace('/');
        } else {
            callService();
        }
        checkIfPermited();
        checkEdit();
    }, []);

    useEffect(() => {
        if (item) {
            AlertAddFatActions.setVisibleAlert(true);
        }
    }, [item])

    const checkEdit = () => {
        const { state } = history.location;

        if (state && state.dadosAgendamCarga.list.length > 0) {
            const {
                dadosAgendamCarga: {
                    list
                },
            } = state;

            let newList = [...list];

            newList.forEach(carga => {
                carga.TipoFaturamento = carga.TipoFat;
                carga.Email = carga.EmailFat;
                carga.InscrEstadual = carga.IeFat;
                carga.TelefoneFat = carga.TelFat;
                carga.Mengbas = carga.OrdemQtd;
            });

            setlist(newList)

        }
    }

    const callService = async () => {
        setLoading(true);

        const { cpf, cnpj, cnpjs_consult } = JSON.parse(await getLocalStorage("LOGIN"));

        // Campo de centro
        const center = localStorage.getItem('CENTER');

        try {
            const response = await Api.post('/users/GetSalesOrder', {
                cpf,
                cnpj,
                outrosCnpj: cnpjs_consult,
                centro: center,
            }, {
                headers: {
                    Authorization: "Bearer " + await getLocalStorage('TOKEN')
                }
            });

            const {
                data: {
                    result: {
                        Return: { Type, Message },
                        ListaOrdemVenda: { item },
                    }
                }
            } = response;

            if (Type === 'S') {

                item.map( it => {
                    it.Mengbas = it.Mengsum;
                });

                setItens(item);
                
            } else {
                showAlert(Message)
            }
        } catch (e) {
            showAlert(getResponseError(e));
        } finally {
            setLoading(false);
        }
    }

    const checkIfPermited = async () => {
        const transporterData = localStorage.getItem('DATA_TRANSPORTER');
        if (!transporterData) {
            showAlert("Antes de continuar, preencha o formulário de dados do transportador", () => {
                setAlertConfig(INTIAL_ALERT_CONFIG);
                history.goBack();
            })
        }
    }

    const showAlert = (message, onClick = null) => {
        setAlertConfig({
            show: true,
            message,
            onClick
        })
    }

    const upItem = (index, item) => {
        var newList = [...list];
        newList.splice(index, 1);
        newList.splice(index - 1, 0, item);

        setlist(newList);
    }

    const remove = (index) => {
        var newList = [...list];
        newList.splice(index, 1);
        setlist(newList);
    }

    const onSend = async (event) => {
        let error;

        const embs = document.getElementsByName('emb');
        for (let i = 0; i < embs.length; i++) {
            if (embs[i].innerText.trim() === '') {
                showAlert("Preencha todos os campos de embalagem antes de continuar.")
                return false;
            }
        }

        const qtds = document.getElementsByName('qtd');
        for (let i = 0; i < qtds.length; i++) {
            if (qtds[i].innerText === '0') {
                showAlert("Preencha todos os campos de quantidades antes de continuar.")
                return false;               
            }
        }

        let DadosCarga = JSON.parse(JSON.stringify(list));

        setlistErrors([]);
        let newListErrors = [];

        list.forEach((carga, index) => {

            DadosCarga[index].Mengsum = qtds[index].innerText;

            let item = itens.filter(it => filterProduct(it, DadosCarga[index]));

            if( item.length != 0 ){

                let qtdInput = parseInt(DadosCarga[index].Mengsum);
                let qtdBase = parseInt(item[0].Mengsum.split(".")[0]);

                if( qtdInput > qtdBase){
                    newListErrors.push(DadosCarga[index]);
                    return;
                }
    
            }else{

                let qtdInput = parseInt(DadosCarga[index].Mengsum);
                let qtdBase = parseInt(DadosCarga[index].Mengbas.split(".")[0]);

                if( qtdInput > qtdBase){
                    newListErrors.push(DadosCarga[index]);
                }
        
            }

            DadosCarga[index].SeqCarga = index + 1
            
        });

        if(newListErrors.length > 0){

            event.preventDefault();
            ModalActionsError.setVisibleError(true);
            setlistErrors([]);
            setlistErrors(newListErrors);
            return;
            
        }

        const dataTransporter = JSON.parse(localStorage.getItem('DATA_TRANSPORTER'));
        const { crlv, cnh } = history.location.state;
        const userPortal = await getUserPortal();
        const phoneUser = JSON.parse(await getLocalStorage('LOGIN')).phone;

        const center = localStorage.getItem('CENTER');

        let body = {
            Centro: center,
            Cnh: cnh === "@MOCK" ? "" : cnh,
            Crlv: crlv === "@MOCK" ? "" : crlv,
            CnpjTransp: removeMask(dataTransporter[0].value),
            CpfMot: removeMask(dataTransporter[6].value),
            DadosCarga,
            EmailTransp: dataTransporter[3].value,
            ExtencaoArq: history.location.state.extensions,
            Motorista: dataTransporter[4].value,
            PlacaCarreta_1: dataTransporter[9].value,
            PlacaCarreta_2: dataTransporter[10].value,
            PlacaCarreta_3: dataTransporter[11].value,
            Renasem: dataTransporter[12].value,
            Observacao: dataTransporter[13].value,
            PlacaCavalo: dataTransporter[7].value,
            RazaoSocial: onlyUTF8(dataTransporter[1].value),
            TelefoneMot: `55${removeMask(dataTransporter[5].value).replace(" ", "")}`,
            TelefoneTransp: `55${removeMask(dataTransporter[2].value).replace(" ", "")}`,
            TipoCaminhao: dataTransporter[8].value,
            idAgenda: history.location.state.idAgenda == -1 ? '' : history.location.state.idAgenda,
            userTel: `55${removeMask(phoneUser).replace(" ", "")}`,
            userPortal: userPortal,
            DataAgendamento: localStorage.getItem("DATE_SELECTED")
        }


        if (history.location.state.idAgendamento) {
            body = { ...body, idAgendamento: history.location.state.idAgendamento }
        }
        
        setLoading(true);

        try {
            const response = await Api.post('/users/SetRegistrationSchedule', body, {
                headers: {
                    Authorization: "Bearer " + await getLocalStorage('TOKEN')
                }
            })
            const {
                data: {
                    result: {
                        Return: {
                            item: [{
                                Type,
                                Message
                            }]
                        }
                    }

                }
            } = response;

            if (Type === 'S') {
                setLoading(false);
                showAlert(Message);
                localStorage.removeItem('DATA_TRANSPORTER');
                localStorage.removeItem("DATE_SELECTED")
                setlist([]);
                ModalActions.clickItem(undefined);
                showAlert(Message, () => {
                    history.push('/availability');
                });
            } else {
                setLoading(false);

                showAlert(Message);
                event.preventDefault();
                return false;
            }
        } catch (e) {
            setLoading(false);

            showAlert(getResponseError(e));
            event.preventDefault();
            return false;
        }
    }

    const getList = () => {
        return listErrors;
    }
    
    const filterProduct = (value, toCompare) => {
        if (toCompare.OrdemQtd === toCompare.Mengsum)    
            return null;
        if (value.Vbeln === toCompare.OrdemVenda && value.Posnr === toCompare.OrdemItem) {
            return value;
        }else if( value.Vbeln === toCompare.Vbeln && value.Posnr === toCompare.Posnr ){
            return value;
        }    
    }

    const saveFat = (item) => {
        var newList = [...list];
        newList.push(item);
        setLastItem(item);
        setlist(newList);
    }

    const editFat = (item, index) => {
        var newList = [...list];
        newList.splice(index, 1);
        newList.splice(index, 0, item);
        setlist(newList);
    }

    return (
        <>

            <Container >
                <p style={{ marginTop: 10, color: 'red', fontSize: 15 }}>
                    **Peso da carga será validado pela Boa Safra Sementes, podendo ser solicitado ajuste da carga.**
                </p>
                <Table>
                    <thead >
                        <RowHeader>
                            <FisthItem>SEQ. CARGA</FisthItem>
                            <Item>PEDIDO</Item>
                            <Item>ITEM</Item>
                            <Item>DESCRIÇÃO</Item>
                            <Item>EMBALAGEM</Item>
                            <LastItem>QTD.</LastItem>
                        </RowHeader>
                    </thead>
                    <tbody>
                        {
                            list.map((item, index) => (
                                <ItemList key={index + 1 + '° A EMBARCAR'}
                                    charge={index + 1 + '° A EMBARCAR'}
                                    item={item}
                                    isEdit={history.location.state.idAgendamento || false}
                                    removeItem={() => remove(index)}
                                    editItem={() => {
                                        AlertAddFatActions.setVisibleAlert(true);
                                        setItemIndex(index);
                                    }}
                                    upItem={() => upItem(index, item)}
                                    position={index}
                                    onChangeAmmount={value => {
                                        list[index].Mengsum = value.split(".")[0]
                                   }}
                                    onChangeEmbalagem={value => {
                                        list[index].Vrkme = value.toUpperCase()
                                    }}
                                />
                            ))
                        }
                    </tbody>
                </Table>
                <BoxAddItem>

                    <AddItem text={'ADICIONAR ITEM DE PEDIDO'} onClick={(event) => {
                        event.preventDefault();
                        ModalActions.setVisible(true);
                        setItemIndex(null)
                    }} />

                </BoxAddItem>

                <Button text={'SALVAR E IR PARA AGENDA'} onClick={onSend} disabled={list.length <= 0} shadow={true} />
            </Container>
            <Alert
                show={alertConfig.show}
                message={alertConfig.message}
                negativeButtonText="Fechar"
                negativeButtonClick={
                    alertConfig.onClick ? alertConfig.onClick :
                        () => setAlertConfig(INTIAL_ALERT_CONFIG)}
            />

            <AlertAddFat
                show={alertAddFatVisible}
                itemIndex={itemIndex}
                list={list}
                lastItem={lastItem}
                saveFat={saveFat}
                editFat={editFat}
            />

            <ModalItens initialVisible={modalVisible} initialList={itens}></ModalItens>
            <ModalItensError initialVisible={modalVisibleError} initialList={getList()}></ModalItensError>
            <ModalLoading show={loading} />
        </>
    )
}

const mapStateToProps = state => ({
    item: state.ModalItems.item,
    modalVisible: state.ModalItems.visible,
    modalVisibleError: state.ModalItemsError.visibleError,
    alertAddFatVisible: state.AlertAddFat.visible
})

const mapDispatchToProps = dispatch => ({
    ModalActions: bindActionCreators(modalActions, dispatch),
    ModalActionsError: bindActionCreators(modalActionsError, dispatch),
    StatusbarActions: bindActionCreators(TopStatusbarActions, dispatch),
    AlertAddFatActions: bindActionCreators(alertAddFat, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MountCharge);